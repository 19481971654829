// Custom Styling
.glyphicons{
  float:left;
  font-weight:bold;
  white-space:nowrap;
  background: #fff;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, 'Arial CE', Arial, sans-serif;
  font-size: 14px;
  color: #000;
  padding: 20px 20px 0 20px;
  width:100%;

  line-height:11px;
  overflow:visible;

  strong{
    display:block;
    width:100%;
    line-height:12px;
    overflow:hidden;
  }
  span{
    display:inline-block;
    width:100%;
    *display:inline;
    *zoom:1;
    margin:3px 0 0 0;
    font-size: 10px;
    font-weight:normal;
    overflow:hidden;
  }
}

.btn>.glyphicons{
  padding: 20px 0px 0px 20px;
}
