@import "../_variables.less";

.wageCal {

  .word-break {
    word-break: break-word;
  }

  &.employer {
    .wageCal .container {

      padding-top: 50px;
    }

    .showData {
      td {
        padding-right: 30px;
      }

      th, td {
        border-right: 5px solid white;
        padding-right: 10px;

        &:first-child, :last-child {
          border-right: none;
        }
      }

      th:nth-child(n+3) {
        text-align: right;
      }
    }
  }

  h2 {
    font-weight: @slim;
    color: @darkest-blue;
    font-size: 26px;
  }

  h4 {
    font-weight: @medium;
    margin-top: 0;
    color: #333;
  }

  .btn-yellow {
    padding-left: 50px;
    padding-right: 50px;

    &.disabled {
      background: #FAFAFA;
      border-color: #fafafa;
      color: #747474;
      font-style: italic;
    }
  }

  .inputData {
    padding-bottom: 25px;

    .form {
      margin-bottom: 25px;
      padding: 5px 25px;
      color: #fff;
      font-size: 17px;
      font-weight: @regular;
      background: #a3b0b9;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjYTNiMGI5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjYTNiMGI5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzg3OGY5NiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9yYWRpYWxHcmFkaWVudD4KICA8cmVjdCB4PSItNTAiIHk9Ii01MCIgd2lkdGg9IjEwMSIgaGVpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
      background: -moz-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
      background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(39%, #a3b0b9), color-stop(39%, #a3b0b9), color-stop(100%, #878f96));
      background: -webkit-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
      background: -o-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
      background: -ms-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
      background: radial-gradient(ellipse at center, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a3b0b9', endColorstr='#878f96', GradientType=1);
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;

      .row {
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .input .form-control {
        height: 25px;
        border-width: 2px 2px 2px 2px;
        background: #85939C;
        color: @darkest-blue;
        text-align: right;
        padding: 0px 12px 0px;
        width: 100%;
        font-weight: @regular;

        &.ng-valid {
          border-color: #9CB3C0;
          border-bottom-color: #B0BDC6;

          &:focus {
            outline: 0 !important;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

          }
        }
      }

      .input input::-webkit-outer-spin-button,
      .input input::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      .skala {
        color: @footer-color;
      }
    }

    .hints {
      color: @footer-color;
      margin-top: 30px;

      ol {
        list-style-type: lower-alpha;
        padding-left: 22px;
      }
    }

    .has-error .form-control,
    .has-error .form-control:focus {
      border-color: @ism-yellow;
    }

  }

  .triangle-down {
    width: 0;
    height: 0;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    border-top: 45px solid @light-blue;
    margin: 0 auto 20px;
  }

  .showData {
    width: 100%;

    .result {
      text-align: center;
      font-weight: @semibold;
      color: @darkest-blue;
      font-size: 26px;
    }

    table {
      background: @light-blue;
      width: 100%;
      font-size: 16px;
      margin: 50px 0 0;
    }

    .scrollWrap {
      width: 100%;
      margin: 0 -15px 0 -15px;
      overflow: scroll;
    }

    .scrollWrap::-webkit-scrollbar {
      /* This is the magic bit */
      display: none;
    }

    th {
      background: @darkest-blue;
      color: @light-blue;
      text-align: center;
    }

    td, th {
      padding: 3px 0 3px 20px;

      text-align: right;

      font-weight: @regular;
    }

    td:first-child,
    th:first-child {
      padding: 3px 10px 3px 10px;
    }

    td:last-child,
    th:last-child {
      padding: 3px 10px 3px 0
    }

    .borderRight {
      padding-right: 10px;
    }

    td {
      text-align: right;

    }

    tr.yellow td {
      background: @ism-yellow;
      font-weight: @semibold;
      color: @darkest-blue !important;
    }

    td:nth-child(2) {
      color: @darker-blue;
      padding-right: 10px;
    }

    td:nth-child(1),
    th:nth-child(1) {
      text-align: left;
    }

    th:nth-child(n+3) {
      text-align: right;
    }

    .borderRight {
      border-right: 5px solid white;
    }
  }

  .popover {
    min-width: 240px !important;
    max-width: 300px !important;
  }


}

.bgWhite {
  padding-bottom: 80px;
  background: #fff;

}

.sky-form .tooltip {
  background: none;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}

.tooltip-inner {
  background-color: #fff;
  color: #000;
  padding: 12px 8px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .scrollWrap {
    margin: 0 15px 0 15px;
  }

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}


