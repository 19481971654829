.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
#functionBlock {
  margin: -90px 0 20px 0;
  padding-top: 14px;
  padding-bottom: 14px;
}
#functionBlock #saveSearch .row-same-height > div:last-child {
  padding-right: 4px !important;
}
#functionBlock #savedSearchProfiles label {
  color: #FFF;
  font-weight: normal;
}
#functionBlock #savedSearchProfiles select.form-control {
  width: 30%;
  margin-left: 10px;
  display: inline-block;
  background-color: #e7edf1;
  color: #195184;
  border: none;
}
#functionBlock #savedSearchProfiles .form-group {
  margin-bottom: 0;
}
.detailFilter {
  margin-top: 10px;
}
.detailFilter .tab-v1 .nav-tabs {
  border-bottom: #fff 2px solid !important;
}
.detailFilter .tab-v1 .nav-tabs a {
  font-weight: 300;
  background: none !important;
  padding: 10px 15px 3px;
  font-size: 15px;
  text-align: left;
}
.detailFilter .tab-v1 .nav-tabs a.active {
  background: white !important;
}
.detailFilter .tab-v1 .nav-tabs a.active:hover,
.detailFilter .tab-v1 .nav-tabs a.active:focus {
  text-decoration: underline;
}
.detailFilter .tab-v1 .nav-tabs li.active a {
  background: #fff !important;
}
.detailFilter .tab-v1 .nav-tabs li.active a:hover {
  color: #555;
}
.detailFilter .nav-tabs > li > a {
  margin-right: 0;
}
.detailFilter .tab-v1 .nav-tabs > .active > a,
.detailFilter .tab-v1 .nav-tabs > .active > a:hover,
.detailFilter .tab-v1 .nav-tabs > .active > a:focus,
.detailFilter .tab-v1 .nav-tabs > li > a:hover {
  color: #324c80;
  background: white;
}
.detailFilter .sky-form .checkbox i {
  background-color: inherit;
  border: 2px solid #404040;
}
.detailFilter .sky-form .checkbox input + i:after,
.detailFilter .sky-form .checkbox input ~ i:after {
  top: 1px;
  color: #404040;
}
.detailFilter .tab-content {
  padding: 10px 13px;
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .detailFilter .nav-tabs > li:not(:last-child) {
    border-right: 1px solid white;
  }
  .detailFilter .tab-v1 .nav-tabs a {
    padding: 10px 3px 3px;
    text-align: center;
    font-size: 15px;
  }
  .detailFilter .tab-content {
    padding: 10px 4px;
  }
  .tab-v1 .nav-tabs li.col-md-2 {
    width: 14.28571429%;
  }
}
/* Large Devices, Wide Screens */
.reset-filter {
  margin-top: 20px 0px;
}
.reset-search {
  text-align: right;
  cursor: pointer;
}
.reset-search img {
  width: 25px;
  height: 25px;
  float: left;
}
.reset-search p {
  color: #002446;
  font-size: 15px;
  margin: 3px 0px 0px 20px;
}
