.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Topbar
2. Header - Navbar
3. Header - Responsive Navbar Style
4. Header - Submenu
5. Header - Search
6. Header - Mega Menu
7. Header - Dark Option
8. Header - Fixed
-----------------------------------------------------------------------*/
/*--------------------------------------------------
    [1. Header - Topbar]
----------------------------------------------------*/
/*Top Bar (login, search etc.)
------------------------------------*/
.header .topbar {
  z-index: 12;
  padding: 8px 0;
  position: relative;
}
@media (max-width: 991px) {
  .header .topbar {
    margin-bottom: 20px;
  }
}
.header .topbar ul.loginbar {
  margin: 0;
}
.header .topbar ul.loginbar > li {
  display: inline;
  list-style: none;
  position: relative;
  padding-bottom: 15px;
}
.header .topbar ul.loginbar > li > a,
.header .topbar ul.loginbar > li > a:hover {
  color: #7c8082;
  font-size: 11px;
  text-transform: uppercase;
}
.header .topbar ul.loginbar li i.fa {
  color: #bbb;
}
.header .topbar ul.loginbar li.topbar-devider {
  top: -1px;
  padding: 0;
  font-size: 8px;
  position: relative;
  margin: 0 9px 0 5px;
  font-family: Tahoma;
  border-right: solid 1px #bbb;
}
/*languages*/
.header .topbar ul.languages {
  top: 25px;
  left: -5px;
  display: none;
  padding: 4px 0;
  padding-left: 0;
  list-style: none;
  min-width: 100px;
  position: absolute;
  background: #f0f0f0;
}
.header .topbar li:hover ul.languages {
  display: block;
}
.header .topbar ul.languages:after {
  top: -4px;
  width: 0;
  height: 0;
  left: 8px;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 6px solid #f0f0f0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}
.header .topbar ul.languages li a {
  color: #555;
  display: block;
  font-size: 10px;
  padding: 2px 12px;
  margin-bottom: 1px;
  text-transform: uppercase;
}
.header .topbar ul.languages li.active a i {
  color: #999;
  float: right;
  margin-top: 2px;
}
.header .topbar ul.languages li a:hover,
.header .topbar ul.languages li.active a {
  background: #fafafa;
}
.header .topbar ul.languages li a:hover {
  text-decoration: none;
}
/*--------------------------------------------------
    [2. Header - Navbar]
----------------------------------------------------*/
/*Navbar*/
.header {
  z-index: 99;
  background: #fff;
  border-bottom: solid 2px #eee;
}
/*Header Container*/
.header > .container {
  display: table;
  margin-bottom: -40px;
}
@media (max-width: 768px) {
  .header > .container {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .header > .container {
    margin-bottom: 0;
  }
}
/*Logo*/
.header .logo {
  height: 100%;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header .logo img {
  z-index: 1;
  margin: 20px 0;
  position: relative;
}
/*Big Logo Style*/
@media (max-width: 1200px) {
  .header img.big-logo {
    width: 170px;
    height: auto;
  }
}
@media (max-width: 500px) {
  .header img.big-logo {
    width: 130px;
    height: auto;
  }
}
.header img.big-logo {
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
/*Navbar Toggle*/
.header .navbar-toggle {
  border-color: #5fb611;
}
@media (max-width: 991px) {
  .header .navbar-toggle {
    margin-right: 0;
  }
}
.header .navbar-toggle .fa {
  color: #fff;
  font-size: 19px;
}
.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: #72c02c;
  padding: 6px 10px 2px;
}
.header .navbar-toggle:hover {
  background: #5fb611 !important;
}
/*Navbar Collapse*/
.header .navbar-collapse {
  position: relative;
}
/*Navbar Menu*/
.header .navbar-nav > li > a {
  color: #002446;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
}
.header .navbar-nav > .active > a {
  color: #72c02c;
}
/* Defaults: Dropdown Menu */
.header .dropdown-menu {
  font-size: 18px;
}
@media (min-width: 992px) {
  .header .navbar-nav {
    float: right;
  }
}
/*No Topbar*/
.header.no-topbar .navbar-toggle {
  margin-top: 25px;
}
@media (min-width: 992px) {
  .header.no-topbar .navbar-nav {
    padding-top: 36px;
  }
}
/*Navbar Collapse*/
@media (max-width: 991px) {
  .header .navbar-collapse,
  .header .navbar-collapse .container {
    padding-left: 0;
    padding-right: 0;
  }
}
/*--------------------------------------------------
    [3. Header - Responsive Navbar Style]
----------------------------------------------------*/
/*Responsive Navbar*/
@media (max-width: 991px) {
  /*Responsive code for max-width: 991px*/
  .header .navbar-header {
    float: none;
  }
  .header .navbar-toggle {
    display: block;
  }
  .header .navbar-collapse.collapse {
    display: none !important;
  }
  .header .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important;
  }
  .header .navbar-nav {
    margin: 0 0 5px;
    float: none !important;
  }
  .header .navbar-nav > li {
    float: none;
  }
  .header .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  /*Pull Right*/
  .header .dropdown-menu.pull-right {
    float: none !important;
  }
  /*Dropdown Menu Slide Down Effect*/
  .header .navbar-nav .open .dropdown-menu {
    border: 0;
    float: none;
    width: auto;
    margin-top: 0;
    position: static;
    box-shadow: none;
    background-color: transparent;
  }
  .header .navbar-nav .open > a,
  .header .navbar-nav .open > a:hover,
  .header .navbar-nav .open > a:focus {
    border-bottom-color: #eee;
  }
  .header .navbar-nav .open .dropdown-menu > li > a,
  .header .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 9px 15px 8px 25px;
  }
  .header .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .header .navbar-nav .open .dropdown-menu > li > a:hover,
  .header .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
  .header .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .header .navbar-nav .open .dropdown-menu > li > a:hover,
  .header .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: transparent;
  }
  .header .navbar-nav .open .dropdown-menu > .active > a,
  .header .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .header .navbar-nav .open .dropdown-menu > .disabled > a,
  .header .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .header .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
  .header .dropdown-menu.no-bottom-space {
    padding-bottom: 0;
  }
  /*Design for max-width: 991px*/
  .header .navbar-nav > li > a {
    font-size: larger;
  }
  .header .navbar-nav > li a {
    border-bottom: solid 1px #eee;
  }
  .header .navbar-nav > li > a:focus {
    background: none;
  }
  .header .navbar-nav > li > a:hover {
    color: #72c02c;
  }
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: #72c02c;
    color: #fff !important;
  }
  .header .dropdown .dropdown-submenu > a {
    font-size: 13px;
    color: #555 !important;
    text-transform: uppercase;
  }
}
@media (min-width: 992px) {
  /*Navbar Collapse*/
  .header .navbar-collapse {
    padding: 0;
  }
  /*Navbar*/
  .header .container > .navbar-header,
  .header .container-fluid > .navbar-header,
  .header .container > .navbar-collapse,
  .header .container-fluid > .navbar-collapse {
    margin-bottom: -10px;
  }
  .header .navbar-nav {
    position: relative;
  }
  .header .navbar-nav > li > a {
    bottom: -2px;
    position: relative;
    padding: 9px 30px 9px 20px;
  }
  .header .navbar-nav > li > a,
  .header .navbar-nav > li > a:focus {
    border-bottom: solid 2px transparent;
  }
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    bottom: -2px;
    position: relative;
    border-bottom: solid 2px #72c02c;
  }
  .header .navbar-nav > li > a,
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > li > a:focus,
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: none;
  }
  .header .navbar-nav > .open > a,
  .header .navbar-nav > .open > a:hover,
  .header .navbar-nav > .open > a:focus {
    color: #72c02c;
  }
  .header .navbar-nav > li:hover > a {
    color: #72c02c;
  }
  /*Dropdown Menu*/
  .header .dropdown-menu {
    padding: 0;
    border: none;
    min-width: 200px;
    border-radius: 0;
    z-index: 9999 !important;
    border-top: solid 2px #72c02c;
    border-bottom: solid 2px #687074;
  }
  .header .dropdown-menu li a {
    color: #687074;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 15px;
    border-bottom: solid 1px #eee;
  }
  .header .dropdown-menu .active > a,
  .header .dropdown-menu li > a:hover {
    color: #687074;
    filter: none !important;
    background: #eee !important;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .header .dropdown-menu li > a:focus {
    background: none;
    filter: none !important;
  }
  .header .navbar-nav > li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  .header .open > .dropdown-menu {
    display: none;
  }
  /*Search*/
  .header .navbar-nav .search-open {
    width: 330px;
  }
}
/*--------------------------------------------------
    [4. Header - Submenu]
----------------------------------------------------*/
/*Dropdown Submenu for BS3
------------------------------------*/
.header .dropdown-submenu {
  position: relative;
}
.header .dropdown > a:after,
.header .dropdown-submenu > a:after {
  top: 8px;
  right: 9px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}
@media (max-width: 991px) {
  .header .dropdown-submenu > a:after {
    content: " ";
  }
}
.header .dropdown > a:after {
  top: 9px;
  right: 15px;
  content: "\f107";
}
.header .dropdown-submenu > .dropdown-menu {
  top: 3px;
  left: 100%;
  margin-top: -5px;
  margin-left: 0px;
}
/*Submenu comes from LEFT side*/
.header .dropdown-submenu > .dropdown-menu.submenu-left {
  left: -100%;
}
.header .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
@media (max-width: 991px) {
  .header .dropdown-submenu > .dropdown-menu {
    display: block;
    margin-left: 15px;
  }
}
.header .dropdown-submenu.pull-left {
  float: none;
}
.header .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.header .dropdown-menu li [class^="fa-"],
.header .dropdown-menu li [class*=" fa-"] {
  left: -3px;
  width: 1.25em;
  margin-right: 1px;
  position: relative;
  text-align: center;
  display: inline-block;
}
.header .dropdown-menu li [class^="fa-"].fa-lg,
.header .dropdown-menu li [class*=" fa-"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em;
}
/*--------------------------------------------------
    [5. Header - Search]
----------------------------------------------------*/
/*Search Box
------------------------------------*/
.header .nav > li > .search {
  color: #aaa;
  cursor: pointer;
  min-width: 35px;
  font-size: 15px;
  text-align: center;
  background: #f7f7f7;
  padding: 11px 0 12px;
  display: inline-block;
  border-bottom: solid 2px #aaa;
}
@media (min-width: 992px) {
  .header .nav > li > .search {
    bottom: -2px;
    position: relative;
  }
}
.header .nav > li > .search:hover {
  color: #72c02c;
  background: #f7f7f7;
  border-bottom-color: #72c02c;
}
.header .nav .search-open {
  right: 0;
  top: 40px;
  display: none;
  padding: 14px;
  position: absolute;
  background: #fcfcfc;
  border-top: solid 2px #eee;
  box-shadow: 0 1px 3px #ddd;
}
.header .nav .search-open form {
  margin: 0;
}
@media (min-width: 767px) and (max-width: 991px) {
  .header .navbar-toggle {
    margin-right: 0;
  }
  .header .nav .search-open {
    width: 93%;
  }
}
@media (max-width: 991px) {
  .header .nav > li > .search {
    color: #999;
    width: 35px;
    height: 34px;
    text-align: left;
    background: #eee;
    line-height: 12px;
    text-align: center;
    margin: 5px 10px 0;
    border-bottom: none;
  }
  .header .nav > li > .search:hover {
    background: #5fb611;
    color: #fff !important;
  }
  .header .nav .search-open {
    top: 5px;
    padding: 0;
    left: 48px;
    border-top: none;
    box-shadow: none;
    background: none;
    margin-right: 10px;
  }
}
/*--------------------------------------------------
    [6. Header - Mega Menu]
----------------------------------------------------*/
/*Mega Menu
------------------------------------*/
.header .mega-menu .nav,
.header .mega-menu .dropup,
.header .mega-menu .dropdown,
.header .mega-menu .collapse {
  position: static;
}
.header .mega-menu .navbar-inner,
.header .mega-menu .container {
  position: relative;
}
.header .mega-menu .dropdown-menu {
  left: auto;
}
.header .mega-menu .dropdown-menu > li {
  display: block;
}
.header .mega-menu .dropdown-submenu .dropdown-menu {
  left: 100%;
}
.header .mega-menu .nav.pull-right .dropdown-menu {
  right: 0;
}
.header .mega-menu .mega-menu-content {
  *zoom: 1;
  padding: 4px 15px;
}
.header .mega-menu .mega-menu-content:before,
.header .mega-menu .mega-menu-content:after {
  content: "";
  display: table;
  line-height: 0;
}
.header .mega-menu .mega-menu-content:after {
  clear: both;
}
.header .mega-menu .nav > li > .dropdown-menu:after,
.header .mega-menu .nav > li > .dropdown-menu:before {
  display: none;
}
.header .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  left: 0;
  right: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  /*Mega Menu for Two Columns*/
  .header .mega-menu .mega-menu-content.content-two-col {
    min-width: 596px;
  }
  /*Mega Menu for Three Columns*/
  .header .mega-menu .mega-menu-content.content-three-col {
    min-width: 864px;
  }
}
/*Mega Menu Dropdown OPENS from LEFT Side*/
.header .mega-menu .dropdown.mega-menu-left .dropdown-menu {
  left: auto;
  overflow: hidden;
}
/*Mega Menu Dropdown OPENS from RIGHT Side*/
.header .mega-menu .dropdown.mega-menu-right .dropdown-menu,
.header .mega-menu .dropdown.mega-menu-two-col-right .dropdown-menu {
  right: 0;
  overflow: hidden;
}
/*Equal Height Lists*/
@media (min-width: 992px) {
  .header .mega-menu .equal-height {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .header .mega-menu .equal-height-in {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
/*Mega Menu Style Effect*/
.header .mega-menu .equal-height-list h3 {
  font-size: 15px;
  font-weight: 400;
  padding: 0 10px 0 13px;
  text-transform: uppercase;
}
/*Space for only Big Resolution*/
@media (min-width: 992px) {
  .header .mega-menu .big-screen-space {
    margin-bottom: 20px;
  }
}
/*Mega Menu Content*/
@media (min-width: 992px) {
  .header .mega-menu .equal-height-in {
    padding: 20px 0;
    border-left: 1px solid #eee;
  }
  .header .mega-menu .equal-height-in:first-child {
    border-left: none;
    margin-left: -1px;
  }
  .header .mega-menu .equal-height-list {
    width: 100%;
  }
  .header .mega-menu .equal-height-list li a {
    display: block;
    margin-bottom: 1px;
    position: relative;
    border-bottom: none;
    padding: 5px 10px 5px 15px;
  }
  .header .mega-menu .equal-height-list a:hover {
    text-decoration: none;
  }
  /*Dropdown Arrow Icons*/
  .header .mega-menu .mega-menu-content li a {
    padding: 5px 10px 5px 30px;
  }
  .header .mega-menu .mega-menu-content li a:after {
    top: 7px;
    left: 15px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
  }
  /*Dropdown Arrow Icons Disable class*/
  .header .mega-menu .disable-icons li a {
    padding: 5px 10px 5px 15px;
  }
  .header .mega-menu .disable-icons li a:after {
    display: none;
  }
}
/*Equal Hight List Style*/
@media (max-width: 991px) {
  /*Equal Height List Style*/
  .header .mega-menu .equal-height-list h3 {
    color: #555;
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 400;
    padding: 6px 25px 5px;
    border-bottom: solid 1px #eee;
  }
  .header .mega-menu .equal-height-list li a {
    color: #687074;
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-left: 15px;
    padding: 6px 25px;
    border-bottom: solid 1px #eee;
  }
  .header .mega-menu .equal-height-list > h3.active,
  .header .mega-menu .equal-height-list > .active > a,
  .header .mega-menu .equal-height-list > .active > a:hover,
  .header .mega-menu .equal-height-list > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .header .mega-menu .equal-height-list li a:hover {
    color: #333;
    text-decoration: none;
  }
}
/*--------------------------------------------------
    [7. Header - Dark Option]
----------------------------------------------------*/
/*Header*/
.header.header-dark {
  background: #2d2d2d;
  border-bottom-color: #555;
}
/*Topbar*/
.header.header-dark .topbar ul.languages {
  background: #444;
}
.header.header-dark .topbar ul.loginbar li.topbar-devider {
  border-right-color: #666;
}
.header.header-dark .topbar ul.languages li a {
  color: #bbb;
}
.header.header-dark .topbar ul.languages:after {
  border-bottom-color: #444;
}
.header.header-dark .topbar ul.languages li a:hover,
.header.header-dark .topbar ul.languages li.active a {
  color: #bbb;
  background: #555;
}
/*Navbar Nav*/
.header.header-dark .navbar-nav > li > a {
  color: #ddd;
}
.header.header-dark .navbar-nav > .open > a,
.header.header-dark .navbar-nav > .open > a:hover,
.header.header-dark .navbar-nav > .open > a:focus {
  background: inherit;
}
@media (max-width: 991px) {
  .header.header-dark .navbar-nav > li a {
    border-bottom-color: #666;
  }
  .header.header-dark .navbar-nav .dropdown > a:hover {
    color: #72c02c;
    background: inherit;
  }
  .header.header-dark .navbar-nav .open > a,
  .header.header-dark .navbar-nav .open > a:hover,
  .header.header-dark .navbar-nav .open > a:focus {
    border-bottom-color: #666;
  }
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a {
    color: #bbb;
  }
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a:hover,
  .header.header-dark .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff !important;
  }
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a,
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #bbb;
    background: #444;
  }
  .header.header-dark .dropdown .dropdown-submenu > a {
    color: #bbb !important;
  }
}
@media (min-width: 992px) {
  .header.header-dark .navbar-nav > li > a:hover,
  .header.header-dark .navbar-nav > .active > a {
    color: #72c02c !important;
  }
  .header.header-dark .dropdown-menu {
    background: #2d2d2d;
  }
  .header.header-dark .dropdown-menu li > a {
    color: #bbb;
    border-bottom-color: #666;
  }
  .header.header-dark .dropdown-menu .active > a,
  .header.header-dark .dropdown-menu li > a:hover {
    background: #202020 !important;
  }
}
/*Search*/
.header.header-dark .nav > li > .search {
  background: #444;
}
.header.header-dark .search-open {
  background: #2d2d2d;
  border-top-color: #555;
  box-shadow: 0 1px 3px #888;
}
.header.header-dark .input-group .form-control {
  color: #bbb;
  background: #555;
  border-color: #999;
  box-shadow: 0 0 2px #555;
}
/*Mega Menu*/
.header.header-dark .mega-menu .equal-height-list h3 {
  color: #eee;
}
@media (max-width: 991px) {
  .header.header-dark .mega-menu .equal-height-list li a {
    color: #bbb;
    border-bottom-color: #666;
  }
  .header.header-dark .mega-menu .equal-height-list li a:focus,
  .header.header-dark .mega-menu .equal-height-list li a:hover {
    color: #fff;
  }
  .header.header-dark .mega-menu .equal-height-list h3 {
    border-bottom-color: #666;
  }
}
@media (min-width: 992px) {
  .header.header-dark .mega-menu .equal-height-in {
    border-left-color: #666;
  }
}
/*--------------------------------------------------
    [8. Header - Fixed]
----------------------------------------------------*/
/*Header*/
.header-fixed .header {
  height: auto;
}
.header-fixed .header.header-fixed-shrink {
  top: 0;
  left: 0;
  width: 100%;
  height: 95px;
  z-index: 9999;
  position: fixed;
  margin-top: -35px;
  border-bottom: none;
  box-shadow: 0 0 3px #bbb;
  background: rgba(255, 255, 255, 0.96);
}
@media (max-width: 991px) {
  /*Header*/
  .header-fixed .header,
  .header-fixed .header.header-fixed-shrink {
    height: inherit;
    position: relative;
  }
}
@media (min-width: 992px) {
  /*Container*/
  .header-fixed .header.header-fixed-shrink > .container {
    margin-bottom: -46px;
  }
  /*Logo*/
  .header-fixed .header.header-fixed-shrink .logo img {
    max-width: 105px;
    margin: 45px 0 -4px;
  }
  /*Topbar*/
  .header-fixed .header.header-fixed-shrink .topbar {
    margin-top: -10px;
  }
  /*Navbar Nav*/
  .header-fixed .header.header-fixed-shrink .navbar-nav > li > a {
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .header-fixed .header.header-fixed-shrink .navbar-nav > li > a:hover,
  .header-fixed .header.header-fixed-shrink .navbar-nav > .active > a {
    border-bottom: none;
  }
  /*Dropdown*/
  .header-fixed .header.header-fixed-shrink .dropdown > a:after {
    top: 20px;
  }
  /*Search*/
  .header-fixed .header.header-fixed-shrink .nav > li > .search {
    color: #555;
    padding: 20px 0 22px;
    background: inherit;
    border-bottom: none;
  }
  .header-fixed .header.header-fixed-shrink .nav .search-open {
    top: 60px;
  }
}
/*Footer-v1
------------------------------------*/
.footer-v1 .footer {
  padding: 40px 0;
  background: #272727;
}
.footer-v1 .footer p {
  color: #eee;
}
.footer-v1 .footer h2 {
  color: #eee;
  font-size: 18px;
  font-weight: 200;
}
/*Link List*/
.footer-v1 .link-list li {
  border-top: solid 1px #353535;
}
.footer-v1 address,
.footer-v1 .link-list a,
.footer-v1 .link-list i {
  color: #eee;
}
.footer-v1 address {
  line-height: 23px;
}
/*Dotted Map*/
.footer-v1 .footer .map-img {
  background: url(../../img/map-img.png) 5px 60px no-repeat;
}
/*Copyright*/
.footer-v1 .copyright {
  background: #222;
  border-top: none;
  padding: 10px 0 5px;
}
.footer-v1 .copyright p {
  color: #eee;
  margin-top: 14px;
}
.footer-v1 .copyright a {
  margin: 0 2px;
}
.footer-v1 .footer-socials {
  margin-top: 12px;
  text-align: right;
}
.footer-v1 .footer-socials li a {
  color: #777;
  font-size: 16px;
}
@media (max-width: 991px) {
  .footer-v1 .copyright,
  .footer-v1 .footer-socials {
    text-align: left;
  }
}
/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Header Fixed
------------------------------------*/
.ie8 .header-fixed .header.header-fixed-shrink {
  background: #fff;
}
/*Blog Latest Tweets
------------------------------------*/
.ie8 .blog-twitter .blog-twitter-inner:after,
.ie8 .blog-twitter .blog-twitter-inner:before {
  border: none;
}
.ie8 .our-clients img {
  width: 100%;
  height: 100px;
  vertical-align: inherit;
}
.ie8 .our-clients .img-hover {
  display: none;
}
/*Pricing Stickers*/
.ie8 .sticker-left {
  width: 90px;
  text-align: right;
  background: #72c02c;
}
.ie8 .sticker-right {
  width: 100px;
  text-align: left;
  background: #e74c3c;
}
/*Easy Block*/
.ie8 .easy-bg-v2 {
  width: 75px;
  text-align: left;
}
.ie8 .rgba-red {
  background: #e74c3c;
}
.ie8 .rgba-blue {
  background: #3498db;
}
.ie8 .rgba-default {
  background: #72c02c;
}
.ie8 .rgba-purple {
  background: #9b6bcc;
}
.ie8 .rgba-aqua {
  background: #27d7e7;
}
.ie8 .rgba-yellow {
  background: #f1c40f;
}
/*Sky-Forms*/
.ie8 .sky-form .icon-append {
  right: 5px !important;
  padding-left: 0;
}
/*Background Opacity*/
.ie8 .service-block-v2 .service-block-in {
  background: url(../img/transparent/white.png) repeat;
}
.ie8 .job-img .job-banner,
.ie8 .job-img .job-img-inputs,
.ie8 .parallax-team:before,
.ie8 .image-block .company-description,
.ie8 .search-block:before,
.ie8 .parallax-counter .counters,
.ie8 .carousel-v1 .carousel-caption {
  background: url(../img/transparent/black.png) repeat;
}
/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Breadcrumbs
------------------------------------*/
/*Default Breadcrumbs*/
.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #eee;
  background: url(../img/patterns/breadcrumbs.png) repeat;
}
.breadcrumbs h1 {
  color: #666;
  font-size: 22px;
  margin-top: 8px;
}
.breadcrumb {
  top: 10px;
  padding-right: 0;
  background: none;
  position: relative;
}
.breadcrumb a {
  color: #777;
}
.breadcrumb li.active,
.breadcrumb li a:hover {
  color: #72c02c;
  text-decoration: none;
}
@media (max-width: 550px) {
  .breadcrumbs h1.pull-left,
  .breadcrumbs ul.pull-right {
    width: 100%;
    text-align: center;
  }
  .breadcrumbs h1.pull-left {
    margin-bottom: 0;
  }
  .breadcrumbs .breadcrumb {
    top: 0;
    margin-bottom: 10px;
  }
}
/*Breadcrumbs Dark and Light*/
.breadcrumbs.breadcrumbs-dark,
.breadcrumbs.breadcrumbs-light {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777;
}
.breadcrumbs.breadcrumbs-light {
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
}
.breadcrumbs.breadcrumbs-dark h1,
.breadcrumbs.breadcrumbs-dark a,
.breadcrumbs.breadcrumbs-light h1,
.breadcrumbs.breadcrumbs-light a {
  color: #fff;
  font-weight: 200;
}
.breadcrumbs.breadcrumbs-light h1,
.breadcrumbs.breadcrumbs-light a {
  color: #555;
}
.breadcrumbs.breadcrumbs-dark h1 {
  font-size: 24px;
}
.breadcrumbs.breadcrumbs-light h1 {
  font-size: 26px;
}
/*Breadcrumbs Sizes*/
.breadcrumbs-sm {
  padding: 50px 0;
}
.breadcrumbs-md {
  padding: 100px 0;
}
.breadcrumbs-lg {
  padding: 200px 0;
}
/*Breadcrumbs v1 and v3
------------------------------------*/
.breadcrumbs-v1,
.breadcrumbs-v3 {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
/*Breadcrumbs v1
------------------------------------*/
.breadcrumbs-v1 {
  padding: 100px 0;
  position: relative;
  background: url(../img/breadcrumbs/img1.jpg) center no-repeat;
  background-size: cover;
  /*Scales the background image to be as large as possible*/
}
.breadcrumbs-v1:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
}
.breadcrumbs-v1 span,
.breadcrumbs-v1 h1 {
  color: #fff;
  z-index: 1;
  position: relative;
}
.breadcrumbs-v1 span {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 5px;
  display: inline-block;
}
.breadcrumbs-v1 h1 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 55px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .breadcrumbs-v1 {
    padding: 50px 0;
  }
  .breadcrumbs-v1 h1 {
    font-size: 40px;
    line-height: 45px;
  }
}
/*Breadcrumbs v2
------------------------------------*/
.breadcrumbs-v2 {
  text-align: center;
  position: relative;
  background: url(../img/bg/11.jpg) no-repeat center;
}
.breadcrumbs-v2-in {
  padding: 80px 0;
  position: relative;
}
.breadcrumbs-v2 h1 {
  color: #fff;
  text-transform: uppercase;
}
.breadcrumb-v2 li a,
.breadcrumb-v2 li.active {
  color: #fff;
}
.breadcrumb-v2 li i {
  color: #fff;
  min-width: 19px;
  padding: 3px 7px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #fff;
}
/*Breadcrumbs v3
------------------------------------*/
.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative;
}
.breadcrumbs-v3.breadcrumbs-lg {
  padding: 200px 0;
}
.breadcrumbs-v3.img-v1 {
  background: url(../img/breadcrumbs/img1.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.breadcrumbs-v3.img-v2 {
  background: url(../img/breadcrumbs/img2.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.breadcrumbs-v3.img-v3 {
  background: url(../img/breadcrumbs/img3.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.breadcrumbs-v3:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
}
.breadcrumbs-v3 h1 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}
.breadcrumbs-v3 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0;
  }
}
@media (max-width: 768px) {
  .breadcrumbs-v3,
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 50px 0;
  }
  .breadcrumbs-v3 h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .breadcrumbs-v3 p {
    font-size: 18px;
  }
}
/*Image Hover Effects
------------------------------------*/
/*Image Hover v1*/
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative;
}
.img-hover-v1 span:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.img-hover-v1:hover span:after {
  background: rgba(0, 0, 0, 0.3);
}
.img-hover-v1 img,
.img-hover-v2 img,
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.img-hover-v1:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
/*Image Hover v2*/
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}
/*Block Grid v1
------------------------------------*/
.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.block-grid-v1:hover {
  border-color: #72c02c;
}
.block-grid-v1 img {
  margin-bottom: 10px;
}
.block-grid-v1 img.block-grid-v1-img {
  width: 35px;
  height: 35px;
  margin-top: 8px;
}
.block-grid-v1.content-boxes-v1 img.pull-left {
  float: inherit !important;
}
.block-grid-v1 h3 a {
  font-size: 22px;
  font-weight: 200;
  margin-right: 10px;
}
.block-grid-v1.content-boxes-v1 h3 {
  margin-bottom: 0;
}
.block-grid-v1.content-boxes-v1 h3 a {
  color: #555;
}
/*Star Vote*/
.block-grid-v1 .star-vote {
  padding: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}
.block-grid-v1 .star-vote li {
  padding: 0;
  font-size: 11px;
  display: inline-block;
}
/*Additional Info*/
.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee;
}
.block-grid-v1-add-info li {
  padding: 0;
  margin-right: 3px;
  margin-bottom: 10px;
}
.block-grid-v1-add-info li a {
  font-size: 11px;
  padding: 4px 7px;
  text-decoration: none;
  border: solid 2px #eee;
  border-radius: 3px !important;
}
/*Block Grid v2
------------------------------------*/
.block-grid-v2 {
  padding: 0;
}
.block-grid-v2 li {
  list-style: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #72c02c;
}
.block-grid-v2-info h3 {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 10px;
}
.block-grid-v2-info .star-vote li {
  padding: 2px;
  font-size: 16px;
}
/*Services Box v1
------------------------------------*/
.service-box-v1 .service-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
}
.service-box-v1 p {
  position: relative;
  margin-bottom: 50px;
}
.service-box-v1 p:after {
  content: " ";
  left: 50%;
  height: 1px;
  width: 50px;
  bottom: -15px;
  position: absolute;
  margin-left: -25px;
  background: #72c02c;
}
.service-box-v1 ul {
  padding: 0 40px;
}
.service-box-v1 li {
  margin: 8px 0;
  font-size: 15px;
  padding-top: 8px;
  border-top: dotted 1px #72c02c;
}
.service-box-v1 li:hover {
  color: #72c02c;
}
.service-box-v1 li:first-child {
  padding-top: 0;
  border-top: none;
}
/*Service Block v1
------------------------------------*/
.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2;
}
.service-block-v1 i {
  left: 50%;
  top: -30px;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 16px;
  font-size: 30px;
  margin-left: -30px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  display: inline-block;
}
.service-block-v1 h3,
.service-block-v1 p {
  margin-bottom: 20px;
}
/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2);
}
.service-block-v2 .service-block-in i {
  font-size: 40px;
}
.service-block-v2 .service-block-in h4 {
  line-height: 25px;
}
.service-block-v2 .service-block-in p {
  margin-bottom: 20px;
}
.service-block-v2 .service-block-in i,
.service-block-v2 .service-block-in h4,
.service-block-v2 .service-block-in p {
  color: #fff;
}
/*Service Block v3 (used in profile pages)
------------------------------------*/
.service-block-v3 {
  padding: 20px;
}
.service-block-v3 i {
  color: #fff;
  float: left;
  font-size: 50px;
  margin: 0 20px 20px 0;
}
.service-block-v3 .service-heading,
.service-block-v3 .service-in small {
  color: #fff;
  opacity: 0.8;
  line-height: 1;
}
.service-block-v3 .service-in h4,
.service-block-v3 .counter {
  color: #fff;
}
.service-block-v3 .service-heading {
  font-size: 16px;
  text-transform: uppercase;
}
.service-block-v3 .counter {
  display: block;
  line-height: 1;
  font-size: 30px;
}
.service-block-v3 .progress {
  margin-bottom: 7px;
}
/*Service Innner*/
.service-block-v3 .service-in small {
  font-size: 16px;
  text-transform: uppercase;
}
.service-block-v3 .service-in h4 {
  font-size: 16px;
  line-height: 0.8;
  margin-bottom: 0;
}
/*Statistics*/
.service-block-v3 .statistics .heading-xs {
  color: #fff;
  opacity: 0.8;
}
.service-block-v3 .statistics small {
  color: #fff;
}
.service-block-v3 .statistics .progress {
  background: #bbb;
}
.service-block-v3 .statistics .progress-bar-light {
  background: #fff;
}
/*Service Block v4
------------------------------------*/
.service-block-v4 {
  position: relative;
  background: #f7f7f7;
}
.service-block-v4:after {
  top: 0;
  width: 0;
  height: 0;
  left: 50%;
  content: '';
  margin-left: -20px;
  position: absolute;
  border-style: solid;
  border-width: 25px 20px 0 20px;
  border-color: #fff transparent transparent transparent;
}
.service-block-v4 .service-desc {
  text-align: center;
}
.service-block-v4 .service-desc i {
  color: #72c02c;
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
}
.service-block-v4 .service-desc h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
/*Service Block v5
------------------------------------*/
.service-block-v5 {
  text-align: center;
  background: #f7f7f7;
}
.service-block-v5 .service-inner {
  padding: 80px 40px;
}
.service-block-v5 .service-inner i {
  margin-bottom: 30px;
}
.service-block-v5 .service-inner span {
  display: block;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.service-block-v5 .service-border {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
@media (max-width: 992px) {
  .service-block-v5 .service-border {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}
/*Service Block v6
------------------------------------*/
.service-block-v6 i {
  float: left;
  margin: 5px 15px 0 0;
}
.service-block-v6 .service-desc {
  overflow: hidden;
}
.service-block-v6 .service-desc h2 {
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.service-block-v6 .service-desc a {
  text-transform: uppercase;
}
/*Service Block v7
------------------------------------*/
.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px;
}
.service-block-v7 i {
  left: 50%;
  top: -25px;
  color: #fff;
  width: 45px;
  height: 45px;
  padding: 10px;
  font-size: 25px;
  text-align: center;
  position: absolute;
  background: #72c02c;
  margin-left: -22.5px;
  display: inline-block;
}
.service-block-v7 h3,
.service-block-v7 p {
  margin-bottom: 20px;
}
/*Service Block v8
------------------------------------*/
.service-block-v8 {
  padding: 25px;
  background: #fff;
}
.service-block-v8 i {
  color: #555;
  float: left;
  font-size: 22px;
  margin: 5px 25px 0 0;
}
.service-block-v8 .service-block-desc {
  overflow: hidden;
}
.service-block-v8 .service-block-desc h3 {
  font-size: 18px;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase;
}
.service-block-v8 .service-block-desc h3:after {
  left: 0;
  top: 40px;
  height: 1px;
  width: 30px;
  content: " ";
  position: absolute;
  background: #72c02c;
}
/*Process v1
------------------------------------*/
.process-v1 .process-in {
  text-align: center;
}
.process-v1 .process-in > li i {
  z-index: 1;
  width: 30px;
  height: 30px;
  position: relative;
  text-align: center;
  background: #72c02c;
  margin-bottom: 15px;
  display: inline-block;
  border: solid 9px #fff;
}
.process-v1 .process-in > li i:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #bbb;
}
/*Process Description*/
.process-v1 .process-in .process-desc {
  padding: 0 20px;
}
.process-v1 .process-in > li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
  text-transform: uppercase;
}
/*Process Border*/
.process-v1 .process-border {
  position: relative;
}
.process-v1 .process-border:before {
  top: 15px;
  width: 75%;
  left: 12%;
  content: " ";
  display: block;
  position: absolute;
  border-top: solid 2px #ddd;
}
@media (max-width: 768px) {
  .process-v1 .process-border:before {
    border: none;
  }
}
/*Team v1
------------------------------------*/
.team-v1 ul {
  position: relative;
}
/*Team Image*/
.team-v1 li > .team-img {
  position: relative;
}
.team-v1 li > .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: transparent;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.team-v1 li:hover > .team-img:after {
  background: rgba(0, 0, 0, 0.5);
}
/*Team Socials*/
.team-v1 li > .team-img ul {
  left: 0;
  top: 45%;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 36px;
  list-style: none;
  margin-top: -18px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.team-v1 li:hover > .team-img ul {
  top: 50%;
  visibility: visible;
}
.team-v1 li > .team-img ul li {
  margin: 0 5px;
  text-align: center;
  display: inline-block;
}
.team-v1 li > .team-img li i {
  color: #eee;
  border-width: 2px;
  border-color: #eee;
  transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}
.team-v1 li > .team-img li i.fa-twitter:hover {
  color: #fff;
  background: #3498db;
  border-color: #3498db;
}
.team-v1 li > .team-img li i.fa-facebook:hover {
  color: #fff;
  background: #4765a0;
  border-color: #4765a0;
}
/*Team Discription*/
.team-v1 li > h3 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 15px 0 7px;
  text-transform: uppercase;
}
.team-v1 li > h4 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}
.team-v1 li > p {
  margin: 20px 0;
  position: relative;
}
.team-v1 li > p:before {
  left: 0;
  top: -10px;
  height: 1px;
  width: 40px;
  content: " ";
  position: absolute;
  background: #bbb;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.team-v1 li:hover > p:before {
  width: 100%;
  background: #72c02c;
}
/*Team v2
------------------------------------*/
.team-v2 {
  position: relative;
  margin-bottom: 20px;
}
.team-v2 img {
  width: 100%;
}
.team-v2 .inner-team {
  padding: 20px;
  background: #fff;
  text-align: center;
}
.team-v2 .inner-team h3 {
  margin: 0;
}
.team-v2 .inner-team small {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
}
.team-v2 .inner-team p {
  font-size: 13px;
}
.team-v2 .inner-team hr {
  margin: 10px 0 15px;
}
/*Team-Social (Temporary)*/
.team-social {
  margin-bottom: 0;
}
.team-social li {
  padding: 0 2px;
}
.team-social li a {
  font-size: 14px;
  padding: 6px 4px;
  text-align: center;
  border-radius: 50% !important;
}
.team-social li i {
  min-width: 20px;
}
.team-social li a.fb {
  color: #4862a3;
  border: 1px solid #4862a3;
}
.team-social li a.tw {
  color: #159ceb;
  border: 1px solid #159ceb;
}
.team-social li a.gp {
  color: #dc4a38;
  border: 1px solid #dc4a38;
}
.team-social li a:hover {
  text-decoration: none;
}
.team-social li a.fb:hover {
  color: #fff;
  background: #4862a3;
}
.team-social li a.tw:hover {
  color: #fff;
  background: #159ceb;
}
.team-social li a.gp:hover {
  color: #fff;
  background: #dc4a38;
}
/*Team v3
------------------------------------*/
.team-v3 .team-img {
  position: relative;
}
.team-v3 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.team-v3 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.team-v3 .team-img > img {
  width: 100%;
}
/*Team Hover Gradient*/
.team-v3 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  padding: 20px;
  list-style: none;
  margin-top: -90px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.team-v3 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}
.team-v3 .team-hover span,
.team-v3 .team-hover small {
  color: #fff;
  display: block;
}
.team-v3 .team-hover span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}
.team-v3 .team-hover small {
  font-size: 13px;
  font-weight: 200;
  margin-bottom: 10px;
}
.team-v3 .team-hover p {
  color: #fff;
  font-weight: 200;
  margin-bottom: 20px;
}
/*Team Social*/
.team-v3 .team-hover .team-social-v3 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}
.team-v3 .team-hover .team-social-v3 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
/*Team v4
------------------------------------*/
.team-v4 {
  text-align: center;
}
.team-v4 img {
  width: 100%;
  margin-bottom: 20px;
}
.team-v4 span {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.team-v4 small {
  color: #999;
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}
.team-v4 .team-social-v4 a {
  color: #555;
  font-size: 16px;
}
.team-v4 .team-social-v4 a:hover {
  color: #72c02c;
  text-decoration: none;
}
/*Team v5
------------------------------------*/
.team-v5 {
  text-align: center;
}
.team-v5 span {
  color: #777;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.team-v5 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  font-style: italic;
  position: relative;
  margin-bottom: 25px;
}
.team-v5 small:after {
  left: 50%;
  top: 30px;
  height: 1px;
  width: 30px;
  content: " ";
  background: #777;
  margin-left: -15px;
  position: absolute;
}
.team-v5 .team-img {
  position: relative;
}
.team-v5 .team-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.team-v5 .team-img:hover:after {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.team-v5 .team-img > img {
  width: 100%;
  margin-bottom: 20px;
}
/*Team Hover Gradient*/
.team-v5 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  list-style: none;
  margin-top: -16px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.team-v5 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible;
}
/*Social Network-*/
.team-v5 .team-hover .team-social-v5 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff;
}
.team-v5 .team-hover .team-social-v5 i:hover {
  color: #666;
  background: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
/*Team v6
------------------------------------*/
.team-v6 {
  text-align: center;
}
.team-v6 img {
  width: 100%;
  margin-bottom: 20px;
}
.team-v6 span {
  color: #777;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.team-v6 small {
  display: block;
  color: #72c02c;
  font-size: 13px;
  margin-bottom: 15px;
}
/*Team v7
------------------------------------*/
.team-v7 .team-v7-in {
  padding: 0 50px;
}
.team-v7 .team-v7-name {
  color: #555;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.team-v7 .team-v7-position {
  display: block;
  color: #72c02c;
  font-style: normal;
  margin-bottom: 15px;
}
.team-v7 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}
/*Team Space*/
.team-v7 .team-v7-name {
  margin-top: 60px;
}
.team-v7 .social-icons-v1 {
  margin-bottom: 60px;
}
/*Team Image*/
.team-v7 .team-v7-img {
  position: relative;
}
.team-v7 .team-v7-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
}
/*Team Info*/
.team-v7 .team-arrow-right,
.team-v7 .team-arrow-left {
  position: relative;
}
.team-v7 .team-arrow-left:before,
.team-v7 .team-arrow-right:before {
  width: 0;
  height: 0;
  top: 20%;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid;
}
.team-v7 .team-arrow-right:before {
  right: -25px;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff;
}
.team-v7 .team-arrow-left:before {
  left: -25px;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent;
}
/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    top: auto;
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
  }
  .team-v7 .team-arrow-left:before,
  .team-v7 .team-arrow-right:before {
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
  }
  /*Equal Height Columns*/
  .team-v7 .equal-height-column {
    height: auto !important;
  }
}
/*Call To Action v1
------------------------------------*/
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0;
}
.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle;
}
.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle;
}
.call-action-v1 .call-action-v1-in.inner-btn {
  min-width: 250px;
  text-align: right;
}
@media (max-width: 992px) {
  .call-action-v1 .call-action-v1-in,
  .call-action-v1 .call-action-v1-in.inner-btn {
    display: block;
    text-align: center;
  }
  .call-action-v1 .call-action-v1-in p {
    margin-bottom: 20px;
  }
}
/*Call To Axtion v2
------------------------------------*/
.call-action-v2 {
  padding: 50px 0;
  text-align: center;
  background: url(../img/patterns/16.png);
}
.call-action-v2 h2 {
  font-size: 22px;
  text-transform: uppercase;
}
.call-action-v2 p {
  padding: 0 100px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .call-action-v2 p {
    padding: inherit;
  }
}
/*Portfolio Box
------------------------------------*/
/*Portfolio Box*/
.portfolio-box {
  margin-bottom: 100px;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-box {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}
.portfolio-box a.fancybox {
  background: #fff;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
.portfolio-box a.fancybox:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.portfolio-box a.fancybox:hover:after {
  background: rgba(0, 0, 0, 0.5);
}
.portfolio-box a.fancybox img {
  width: 100%;
  overflow: hidden;
  transition: all 500ms ease 0s;
}
.portfolio-box a.fancybox:hover img {
  transform: scale(1.1) rotate(0.1deg);
}
.portfolio-box .portfolio-box-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  margin-top: -25px;
  visibility: hidden;
  text-align: center;
  position: absolute;
}
.portfolio-box a.fancybox:hover .portfolio-box-in {
  visibility: visible;
}
.portfolio-box a.fancybox:after,
.portfolio-box a.fancybox:hover:after,
.portfolio-box a.fancybox:hover .portfolio-box-in i {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.portfolio-box .portfolio-box-in i {
  color: #fff;
  width: 50px;
  height: 50px;
  padding: 16px;
  font-size: 20px;
  text-align: center;
  background: #72c02c;
  display: inline-block;
}
/*Project Tag*/
.portfolio-box small.project-tag {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}
.portfolio-box small.project-tag,
.portfolio-box small.project-tag a {
  color: #999;
}
.portfolio-box small.project-tag i {
  margin-right: 5px;
}
.portfolio-box small.project-tag a:hover {
  color: #72c02c;
}
/*Portfolio Box v1
------------------------------------*/
.portfolio-box-v1 {
  margin: 0;
}
.portfolio-box-v1 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}
.portfolio-box-v1 li {
  background: #333;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
.portfolio-box-v1 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.portfolio-box-v1 li:hover:after {
  background: rgba(0, 0, 0, 0.7);
}
.portfolio-box-v1 li:after,
.portfolio-box-v1 li:hover:after {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.portfolio-box-v1 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.portfolio-box-v1 li:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.portfolio-box-v1 .portfolio-box-v1-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 150px;
  margin-top: -75px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}
.portfolio-box-v1 li:hover .portfolio-box-v1-in {
  visibility: visible;
}
.portfolio-box-v1 .portfolio-box-v1-in h3 {
  font-size: 22px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.portfolio-box-v1 .portfolio-box-v1-in h3:after {
  left: 50%;
  height: 1px;
  width: 40px;
  content: " ";
  bottom: -10px;
  background: #fff;
  margin-left: -20px;
  position: absolute;
}
.portfolio-box-v1 .portfolio-box-v1-in p,
.portfolio-box-v1 .portfolio-box-v1-in h3 {
  color: #fff;
}
.portfolio-box-v1 .portfolio-box-v1-in p {
  font-size: 16px;
  margin-bottom: 20px;
}
.portfolio-box-v1 .portfolio-box-v1-in a.btn-u {
  font-weight: normal;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
/*Portfolio Feature v2
------------------------------------*/
.portfolio-box-v2 {
  margin: 0 0 60px;
}
.portfolio-box-v2 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}
.portfolio-box-v2 li {
  background: #333;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
.portfolio-box-v2 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.portfolio-box-v2 li:hover:after {
  background: rgba(255, 255, 255, 0.6);
}
.portfolio-box-v2 li:after,
.portfolio-box-v2 li:hover:after {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.portfolio-box-v2 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.portfolio-box-v2 li:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.portfolio-box-v2 .portfolio-box-v2-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 60px;
  margin-top: -30px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}
.portfolio-box-v2 li:hover .portfolio-box-v2-in {
  visibility: visible;
}
.portfolio-box-v2 .portfolio-box-v2-in i {
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 20px;
  font-size: 22px;
  cursor: pointer;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  background: rgba(114, 192, 44, 0.8);
}
.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: #72c02c;
}
/*Thumbnail v1
------------------------------------*/
.thumbnails-v1 .thumbnail-img {
  margin-bottom: 12px;
}
.thumbnails-v1 h3 {
  margin-bottom: 5px;
}
.thumbnails-v1 h3 a {
  color: #555;
  font-size: 15px;
  text-transform: uppercase;
}
.thumbnails-v1 .read-more {
  color: #72c02c;
  font-size: 12px;
  text-transform: uppercase;
}
/*Image Backgrounds v1
------------------------------------*/
.bg-image-v1 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/11.jpg) repeat fixed;
}
.bg-image-v1:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
/*Image Backgrounds v2
------------------------------------*/
.bg-image-v2 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/7.jpg) repeat fixed;
}
.bg-image-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
}
/*Background Dark Cover*/
.bg-image-v2-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
.bg-image-v2-dark h2,
.bg-image-v2-dark p {
  color: #eee !important;
}
/*Parallax Quote
------------------------------------*/
.parallax-quote {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
}
.parallax-quote:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
}
.parallax-quote:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: url(../img/patterns/gridtile.png) repeat;
}
/*Parallax Quote Inner*/
.parallax-quote-in {
  z-index: 1;
  padding: 0 80px;
  position: relative;
}
.parallax-quote-in p {
  color: #fff;
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}
.parallax-quote-in p:after,
.parallax-quote-in p:before {
  content: ' " ';
  position: absolute;
  font-family: Tahoma;
}
.parallax-quote-in p:after {
  margin-left: 3px;
}
.parallax-quote-in p:before {
  margin-left: -15px;
}
.parallax-quote-in small {
  color: #bbb;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .parallax-quote-in {
    padding: 0 40px;
  }
  .parallax-quote-in p {
    font-size: 20px;
  }
}
/*Parallax Quote Light*/
.parallax-quote-light:after {
  background: rgba(255, 255, 255, 0.9);
}
.parallax-quote-light .parallax-quote-in {
  background: #fff;
  padding: 30px 80px;
  border-bottom: solid 2px #ddd;
}
.parallax-quote-light .parallax-quote-in p {
  color: #555;
  font-size: 28px;
  font-weight: 200;
}
.parallax-quote-light .parallax-quote-in small {
  color: #555;
}
@media (max-width: 768px) {
  .parallax-quote-light .parallax-quote-in {
    padding: 30px 40px;
  }
  .parallax-quote-light .parallax-quote-in p {
    font-size: 20px;
  }
  .parallax-quote-light .parallax-quote-in small {
    font-weight: 200;
  }
}
/*Parallax Team
------------------------------------*/
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
}
.parallax-team,
.parallax-team .title-box-v2 {
  position: relative;
}
.parallax-team:before,
.parallax-team-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
}
/*Parallax Team*/
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
}
.parallax-team,
.parallax-team .title-box-v2 {
  position: relative;
}
.parallax-team-dark:before {
  background: rgba(0, 0, 0, 0.8);
}
/*Parallax Counter
------------------------------------*/
.parallax-bg {
  background: url(../img/patterns/5.png) repeat fixed;
}
/*Parallax Counter
------------------------------------*/
.parallax-counter {
  padding: 30px 0;
  background: url(../img/patterns/5.png) repeat fixed;
}
.parallax-counter .counters {
  color: #fff;
  padding: 15px 0;
}
.parallax-counter h4 {
  color: #999;
  font-size: 16px;
  text-transform: uppercase;
}
.parallax-counter span.counter {
  font-size: 48px;
}
/*Parallax Counter v1
------------------------------------*/
.parallax-counter-v1 {
  padding: 60px 0;
  background: url(../img/patterns/5.png) repeat fixed;
}
.parallax-counter-v1 .counters {
  color: #fff;
  padding: 15px 0 10px;
  border: solid 1px #555;
}
.parallax-counter-v1 h4 {
  color: #eee;
  font-size: 16px;
  text-transform: uppercase;
}
.parallax-counter-v1 span.counter {
  font-size: 42px;
  line-height: 48px;
}
/*Parallax Counter v2
------------------------------------*/
.parallax-counter-v2 {
  position: relative;
  padding: 80px 0 60px;
  background: url(../img/patterns/16.png) repeat fixed;
}
.parallax-counter-v2:after {
  top: 0;
  left: 50%;
  content: " ";
  margin-left: -25px;
  position: absolute;
  border-top: 25px solid #fff;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}
.parallax-counter-v2:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.02);
}
.parallax-counter-v2 .counters {
  padding: 20px 0;
  border-bottom: solid 2px #ddd;
  background: rgba(255, 255, 255, 0.9);
}
.parallax-counter-v2 .counters h4,
.parallax-counter-v2 .counters span {
  color: #555;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}
.parallax-counter-v2 .counters span {
  font-size: 36px;
}
.parallax-counter-v2 .counters h4 {
  font-size: 22px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .parallax-counter-v2 .counters {
    margin-bottom: 0 !important;
  }
}
/*Parallax Counter v3
------------------------------------*/
.parallax-counter-v3 {
  z-index: 1;
  height: auto;
  padding: 80px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/25.jpg) 50% 0 fixed;
}
.parallax-counter-v3:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
.parallax-counter-v3 .features {
  width: 130px;
  height: 130px;
  padding: 25px;
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 50% !important;
  background: rgba(255, 255, 255, 0.2);
}
.parallax-counter-v3 .features:after {
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  content: " ";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #fff;
}
.parallax-counter-v3 .features span {
  color: #fff;
  display: block;
  font-weight: 200;
}
.parallax-counter-v3 .features span.counter {
  font-size: 30px;
}
.parallax-counter-v3 .features span.features-info {
  text-transform: uppercase;
}
@media (max-width: 550px) {
  .parallax-counter-v3 .features {
    width: 110px;
    height: 110px;
    padding: 22px;
  }
  .parallax-counter-v3 .features:after {
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
  }
  .parallax-counter-v3 .features span.counter {
    font-size: 20px;
  }
  .parallax-counter-v3 .features span.features-info {
    font-size: 11px;
  }
}
/*Parallax Counter v4
------------------------------------*/
.parallax-counter-v4 {
  z-index: 1;
  height: auto;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/19.jpg) 50% 0 fixed;
}
.parallax-counter-v4:after {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  content: " ";
  height: 100%;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}
.parallax-counter-v4 i {
  color: #fff;
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}
.parallax-counter-v4 span.counter {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}
.parallax-counter-v4 h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
  text-transform: uppercase;
}
/*Flat Background Block
------------------------------------*/
.flat-bg-block-v1 {
  padding: 40px 0;
  background: url(../img/patterns/16.png) repeat;
}
.flat-bg-block-v1 .checked-list.first-child {
  float: left;
  margin-right: 100px;
}
.flat-bg-block-v1 .checked-list li {
  color: #888;
  margin-bottom: 5px;
}
.flat-bg-block-v1 .checked-list i {
  color: #72c02c;
  margin-right: 5px;
}
/*Image Over*/
.flat-bg-block-v1 img.img-over {
  top: -70px;
  right: 10px;
  position: absolute;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .flat-bg-block-v1 img.img-over {
    top: -18px;
  }
}
@media (max-width: 991px) {
  .flat-bg-block-v1 img.img-over {
    position: static;
  }
}
/*Flat Testimonials
------------------------------------*/
.flat-testimonials {
  padding: 50px 0;
  background-color: #333;
}
.flat-testimonials .flat-testimonials-in {
  padding: 30px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
}
.flat-testimonials .flat-testimonials-in img {
  width: 135px;
  height: 135px;
  margin: 0 auto 25px;
}
.flat-testimonials .flat-testimonials-in h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
}
.flat-testimonials .flat-testimonials-in span {
  display: block;
  margin-bottom: 5px;
}
.flat-testimonials .flat-testimonials-in p {
  color: #fff;
  font-weight: 200;
  position: relative;
}
.flat-testimonials .flat-testimonials-in p:before,
.flat-testimonials .flat-testimonials-in p:after {
  top: 5px;
  font-size: 22px;
  line-height: 10px;
  position: relative;
}
.flat-testimonials .flat-testimonials-in p:before {
  content: "“";
  padding-right: 5px;
}
.flat-testimonials .flat-testimonials-in p:after {
  content: "”";
  padding-left: 5px;
}
/*Testimonials v4
------------------------------------*/
.testimonials-v4 {
  overflow: hidden;
}
.testimonials-v4 .testimonials-v4-in {
  background: #fff;
  position: relative;
  margin-bottom: 40px;
  padding: 20px 20px 20px 55px;
  -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.testimonials-v4 .testimonials-v4-in:before {
  color: #bbb;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -25px 0 0 -40px;
  font-family: Georgia, serif;
}
.testimonials-v4 .testimonials-v4-in:after {
  width: 0;
  height: 0;
  left: 80px;
  content: " ";
  bottom: -30px;
  position: absolute;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}
.testimonials-v4 .testimonials-v4-in p {
  color: #777;
  font-size: 14px;
  font-style: italic;
}
.testimonials-v4 img {
  float: left;
  width: 60px;
  height: auto;
  margin: 0 20px;
}
.testimonials-v4 .testimonials-author {
  color: #555;
  display: block;
  font-size: 14px;
  overflow: hidden;
  padding-top: 8px;
  text-transform: uppercase;
}
.testimonials-v4 .testimonials-author em {
  font-size: 13px;
  text-transform: capitalize;
}
/*Testimonials v5
------------------------------------*/
.testimonials-v5 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials-v5 .testimonials-desc span {
  font-size: 13px;
  font-weight: 200;
}
.testimonials-v5 .testimonials-desc p {
  padding: 15px;
  position: relative;
  background: #f9f9f9;
  margin-bottom: 25px;
}
.testimonials-v5 .testimonials-desc p:after {
  width: 0;
  height: 0;
  left: 30px;
  color: #fff;
  bottom: -12px;
  content: " ";
  text-align: center;
  position: absolute;
  border-style: solid;
  border-width: 13px 10px 0 10px;
  border-color: #f9f9f9 transparent transparent transparent;
}
/*Testimonials By*/
.testimonials-v5 .testimonials-by {
  margin-left: 20px;
}
.testimonials-v5 .testimonials-by img {
  float: left;
  width: 40px;
  height: auto;
  margin-right: 15px;
}
.testimonials-v5 .testimonials-by small {
  color: #72c02c;
  font-size: 12px;
}
.testimonials-v5 .testimonials-by span {
  text-transform: uppercase;
}
/*Testimonials v6
------------------------------------*/
.testimonials-v6.testimonials-wrap {
  padding-left: 70px;
  padding-right: 70px;
}
.testimonials-v6 .testimonials-info {
  padding: 20px;
  background: #fff;
  border-bottom: solid 3px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.testimonials-v6 .testimonials-info:hover {
  border-color: #72c02c;
}
.testimonials-v6 .testimonials-info img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}
.testimonials-v6 .testimonials-desc {
  overflow: hidden;
}
.testimonials-v6 .testimonials-desc p {
  margin-bottom: 15px;
}
.testimonials-v6 .testimonials-desc strong,
.testimonials-v6 .testimonials-desc span {
  color: #555;
  display: block;
}
@media (max-width: 768px) {
  .testimonials-v6 .testimonials-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
/*Featured Blog
------------------------------------*/
.featured-blog h2 {
  font-size: 18px;
  margin: 0 0 25px;
  line-height: 25px;
  position: relative;
}
.featured-blog h2:after {
  left: 0;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -10px;
  position: absolute;
  background: #72c02c;
}
.featured-blog .featured-img {
  position: relative;
}
.featured-blog .featured-img:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.featured-blog .featured-img:hover:after {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.featured-blog .featured-img > img {
  width: 100%;
}
/*Team Hover Gradient*/
.featured-blog .featured-img i {
  top: 60%;
  left: 50%;
  z-index: 1;
  width: 100%;
  list-style: none;
  text-align: center;
  visibility: hidden;
  position: absolute;
  margin: -17.5px 0 0 -17.5px;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.featured-blog .featured-img:hover i {
  top: 50%;
  visibility: visible;
}
.featured-blog .featured-img i {
  color: #fff;
  width: 35px;
  height: 35px;
  padding: 11px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  background: rgba(255, 255, 255, 0.4);
}
.featured-blog .featured-img i:hover {
  color: #fff;
  background: #72c02c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
/*Blog Comments
------------------------------------*/
.blog-comments img {
  width: 80px;
  height: auto;
}
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}
.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}
.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}
.blog-comments .comments-itself span {
  color: #999;
  float: right;
  font-size: 13px;
}
.blog-comments .comments-itself p {
  color: #999;
}
/*Comments Reply*/
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}
/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself:before {
    display: none;
  }
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent;
  }
}
/*Quote
------------------------------------*/
.quote-v1 {
  padding: 60px 0;
  text-align: center;
  background: url(../img/patterns/5.png) repeat;
}
.quote-v1 p {
  color: #fff;
  font-size: 22px;
  font-weight: 200;
  padding: 0 100px;
  position: relative;
  margin-bottom: 20px;
}
.quote-v1 p:before {
  color: #72c02c;
  font-size: 60px;
  content: "\201C";
  position: absolute;
  margin: -30px 0 0 -40px;
  font-family: Georgia, serif;
}
.quote-v1 span {
  color: #fff;
  font-size: 18px;
}
@media (max-width: 768px) {
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px;
  }
}
/*Quote v2
------------------------------------*/
.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px;
}
.quote-v2 p {
  color: #555;
  font-size: 16px;
  font-style: italic;
  position: relative;
  margin-bottom: 20px;
}
.quote-v2 p:before {
  left: 50%;
  top: -75px;
  font-size: 80px;
  content: "\201C";
  position: absolute;
  margin-left: -25px;
  font-family: Georgia, serif;
}
.quote-v2 span {
  color: #555;
  font-weight: 400;
}
/*Team Social
------------------------------------*/
.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}
.social-icons-v1 i:hover {
  color: #fff;
  background: #72c02c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
/*Box Shadows
------------------------------------*/
.shadow-wrapper {
  z-index: 1;
  position: relative;
}
/*Common Style*/
.box-shadow {
  background: #fff;
  position: relative;
}
.box-shadow:after,
.box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}
/*Effect 1*/
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
  -moz-box-shadow: 0 10px 6px -6px #bbb;
  -webkit-box-shadow: 0 10px 6px -6px #bbb;
}
/*Effect 2,3,4*/
.shadow-effect-2 {
  position: relative;
}
.shadow-effect-2:after,
.shadow-effect-2:before,
.shadow-effect-3:before,
.shadow-effect-4:after {
  transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
  -moz-box-shadow: 0 15px 10px #999;
  -webkit-box-shadow: 0 15px 10px #999;
}
.shadow-effect-2:after,
.shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
}
/*Interactive Slider v1
------------------------------------*/
.interactive-slider-v1 {
  z-index: 1;
  padding: 130px 0;
  position: relative;
}
.interactive-slider-v1.img-v1 {
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v1.img-v2 {
  background: url(../img/bg/19.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v1.img-v3 {
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v1:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}
.interactive-slider-v1 h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
}
.interactive-slider-v1 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .interactive-slider-v1 h2 {
    font-size: 30px;
  }
  .interactive-slider-v1 p {
    font-size: 18px;
  }
}
/*Interactive Slider v2
------------------------------------*/
.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v2.img-v1 {
  background: url(../img/bg/25.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v2.img-v2 {
  background: url(../img/bg/14.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}
.interactive-slider-v2:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
}
.interactive-slider-v2 h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  text-transform: uppercase;
}
.interactive-slider-v2 p {
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  font-family: "Open Sans", Arial, sans-serif;
}
@media (max-width: 768px) {
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}
/*Blog Trending
------------------------------------*/
.blog-trending li {
  margin-bottom: 20px;
}
.blog-trending h3 {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 17px;
}
.blog-trending small,
.blog-trending small a {
  color: #777;
}
.blog-trending small a:hover {
  color: #72c02c;
}
/*Blog Latest Posts
------------------------------------*/
.blog-latest-posts li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}
.blog-latest-posts li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.blog-latest-posts h3 {
  font-size: 14px;
  margin: 0 0 3px;
  line-height: 20px;
}
.blog-latest-posts small,
.blog-latest-posts small a {
  color: #777;
}
.blog-latest-posts small {
  margin-bottom: 8px;
  display: inline-block;
}
.blog-latest-posts p {
  font-size: 12px;
}
/*Blog Photostream
------------------------------------*/
.blog-photostream {
  margin-right: -5px;
}
.blog-photostream li {
  padding: 0;
  margin: 3px 5px 5px;
  position: relative;
}
.blog-photostream li img {
  width: 78px;
  height: auto;
}
/*Newsletter
------------------------------------*/
.blog-newsletter p {
  color: #555;
  margin-bottom: 15px;
}
.blog-newsletter .form-control {
  border: none;
}
.blog-newsletter .form-control:focus {
  box-shadow: none;
}
/*Blog Post Quote
------------------------------------*/
.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center;
}
.blog-post-quote p {
  color: #555;
  font-size: 32px;
  font-weight: 200;
  line-height: 45px;
  position: relative;
  margin-bottom: 20px;
  font-family: "Open Sans", Arial, sans-serif;
}
@media (max-width: 768px) {
  .blog-post-quote {
    padding: 35px 25px;
  }
  .blog-post-quote p {
    font-size: 26px;
    line-height: 35px;
  }
}
.blog-post-quote p:before,
.blog-post-quote p:after {
  top: -3px;
  font-size: 30px;
  position: relative;
}
.blog-post-quote p:before {
  left: -10px;
  content: "\0022";
}
.blog-post-quote p:after {
  right: -10px;
  content: "\0022";
}
.blog-post-quote span {
  color: #555;
  font-size: 20px;
  font-weight: 200;
}
/*Blog Post Author
------------------------------------*/
.blog-author {
  padding: 20px;
  background: #fff;
}
.blog-author img {
  float: left;
  width: 80px;
  height: auto;
  margin-right: 20px;
}
.blog-author .blog-author-desc {
  overflow: hidden;
}
.blog-author .blog-author-desc h4 {
  float: left;
  font-size: 18px;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.blog-author .blog-author-desc ul {
  float: right;
  margin-top: 6px;
}
.blog-author .blog-author-desc p {
  color: #555;
}
/*Blog Comments
------------------------------------*/
.blog-comments img {
  width: 80px;
  height: auto;
}
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative;
}
.blog-comments .comments-itself:before {
  width: 0;
  height: 0;
  top: 20px;
  left: -17px;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 15px 17px 15px 0;
  border-color: transparent #fff transparent transparent;
}
.blog-comments .comments-itself h3 {
  margin: 0 0 10px;
}
.blog-comments .comments-itself span {
  color: #555;
  float: right;
  font-size: 13px;
}
.blog-comments .comments-itself p {
  color: #555;
}
/*Comments Reply*/
.blog-comments.blog-comments-reply {
  margin-left: 70px;
}
/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself:before {
    display: none;
  }
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent;
  }
}
/*News v1
------------------------------------*/
.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
.news-v1 .news-v1-in {
  background: #fafafa;
}
.news-v1 h3 {
  margin-bottom: 0;
  font-weight: 200;
  line-height: 28px;
  padding: 10px 15px 15px;
}
.news-v1 h3 a {
  color: #555;
}
.news-v1 h3 a:hover {
  color: #72c02c;
}
.news-v1 p {
  color: #555;
  padding: 0 15px;
  margin-bottom: 20px;
}
.news-v1 .news-v1-info {
  margin-left: 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 8px 10px;
  border-top: solid 1px #eee;
}
.news-v1 .news-v1-info li {
  font-size: 12px;
}
.news-v1 .news-v1-info li a:hover {
  color: #72c02c;
  text-decoration: none;
}
/*News v2
------------------------------------*/
/*News Badge*/
.news-v2 .news-v2-badge {
  overflow: hidden;
  position: relative;
}
.news-v2 .news-v2-badge p {
  left: 0;
  top: 20px;
  background: #fff;
  padding: 5px 15px;
  text-align: center;
  position: absolute;
}
.news-v2 .news-v2-badge span {
  color: #555;
  display: block;
  font-size: 16px;
  line-height: 16px;
}
.news-v2 .news-v2-badge small {
  color: #555;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}
/*News Description*/
.news-v2 .news-v2-desc {
  padding: 20px;
  background: #fff;
}
.news-v2 .news-v2-desc h3 {
  margin: 0 0 3px;
  font-size: 16px;
}
.news-v2 .news-v2-desc h3 a {
  color: #555;
}
.news-v2 .news-v2-desc h3 a:hover {
  color: #72c02c;
}
.news-v2 .news-v2-desc small {
  color: #555;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.news-v2 .news-v2-desc p {
  color: #555;
}
/*News v3
------------------------------------*/
/*News Posts*/
.news-v3 {
  background: #fff;
}
.news-v3 .news-v3-in {
  padding: 35px 30px;
}
.news-v3 .news-v3-in-sm {
  padding: 20px;
}
.news-v3 h2 {
  font-size: 32px;
  font-weight: 200;
  margin: 0 0 20px;
  line-height: 45px;
  text-transform: uppercase;
}
.news-v3 .news-v3-in-sm h2 {
  font-size: 22px;
  margin: 0 0 15px;
  line-height: 30px;
  text-transform: inherit;
}
@media (max-width: 768px) {
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}
.news-v3 h2 a {
  color: #555;
}
.news-v3 h2 a:hover {
  color: #72c02c;
  text-decoration: none;
}
.news-v3 p {
  color: #555;
  margin-bottom: 20px;
}
/*News Info*/
.news-v3 .posted-info li {
  color: #555;
  font-style: italic;
}
.news-v3 .news-v3-in-sm .posted-info li {
  padding: 0;
  font-size: 12px;
  padding-left: 5px;
}
.news-v3 .posted-info li:before,
.news-v3 .news-v3-in-sm .posted-info li:before {
  content: '/';
}
.news-v3 .posted-info li:before {
  font-size: 12px;
  line-height: 12px;
  margin-right: 13px;
}
.news-v3 .news-v3-in-sm .posted-info li:before {
  font-size: 11px;
  line-height: 11px;
  margin-right: 9px;
}
.news-v3 .posted-info li:first-child:before,
.news-v3 .news-v3-in-sm .posted-info li:first-child:before {
  content: " ";
  margin-right: 0;
}
.news-v3 .posted-info li a {
  color: #555;
}
.news-v3 .posted-info li a:hover {
  color: #72c02c;
}
/*News Shares*/
.news-v3 .post-shares {
  padding-left: 0;
  list-style: none;
  margin: 40px 0 0 -5px;
}
.news-v3 .news-v3-in-sm .post-shares {
  margin: 20px 0 0 -5px;
}
.news-v3 .post-shares li {
  position: relative;
  padding: 0 10px 0 5px;
  display: inline-block;
}
.news-v3 .post-shares li i {
  color: #555;
  width: 40px;
  height: 40px;
  padding: 13px;
  font-size: 16px;
  background: #eee;
  text-align: center;
  display: inline-block;
}
.news-v3 .post-shares li i:hover {
  background: #ddd;
}
.news-v3 .post-shares li span {
  top: -5px;
  right: 6px;
  color: #fff;
  width: 22px;
  height: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  text-align: center;
  background: #72c02c;
  border: 2px solid #fff;
  border-radius: 50% !important;
}
.news-v3 .post-shares-lg li span {
  top: -10px;
  right: 0px;
  width: 29px;
  height: 29px;
  padding: 4px;
  font-size: 10px;
  line-height: 16px;
}
/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Reset Styles
------------------------------------*/
* {
  border-radius: 0;
}
a,
a:focus,
a:hover,
a:active,
button,
button:hover {
  outline: 0 !important;
}
a:focus {
  text-decoration: none;
}
hr {
  margin: 30px 0;
}
hr.hr-xs {
  margin: 10px 0;
}
hr.hr-md {
  margin: 20px 0;
}
hr.hr-lg {
  margin: 40px 0;
}
/*Headings*/
h1 {
  font-size: 28px;
  line-height: 35px;
}
h2 {
  font-size: 24px;
  line-height: 33px;
}
h3 {
  font-size: 20px;
  line-height: 27px;
}
h4 {
  line-height: 25px;
}
h5 {
  line-height: 20px;
}
h6 {
  line-height: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555;
  margin-top: 5px;
  text-shadow: none;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif;
}
h1 i,
h2 i,
h3 i,
h4 i,
h5 i,
h6 i {
  margin-right: 5px;
}
/*Block Headline*/
.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0;
}
.headline h2 {
  font-size: 22px;
}
.headline h2,
.headline h3,
.headline h4 {
  margin: 0 0 -2px 0;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #72c02c;
}
.headline-md {
  margin-bottom: 15px;
}
.headline-md h2 {
  font-size: 21px;
}
/*Heading Options*/
.heading {
  text-align: center;
}
.heading h2 {
  padding: 0 12px;
  position: relative;
  display: inline-block;
  line-height: 34px !important;
  /*For Tagline Boxes*/
}
.heading h2:before,
.heading h2:after {
  content: ' ';
  width: 70%;
  position: absolute;
  border-width: 1px;
  border-color: #bbb;
}
.heading h2:before {
  right: 100%;
}
.heading h2:after {
  left: 100%;
}
@media (max-width: 768px) {
  .heading h2:before,
  .heading h2:after {
    width: 20%;
  }
}
/*Headline v1*/
.heading-v1 h2:before,
.heading-v1 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: solid;
  border-bottom-style: solid;
}
/*Headline v2*/
.heading-v2 h2:before,
.heading-v2 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dashed;
  border-bottom-style: dashed;
}
/*Headline v3*/
.heading-v3 h2:before,
.heading-v3 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dotted;
  border-bottom-style: dotted;
}
/*Headline v4*/
.heading-v4 h2:before,
.heading-v4 h2:after {
  top: 17px;
  border-bottom-style: solid;
}
/*Headline v5*/
.heading-v5 h2:before,
.heading-v5 h2:after {
  top: 17px;
  border-bottom-style: dashed;
}
/*Headline v6*/
.heading-v6 h2:before,
.heading-v6 h2:after {
  top: 17px;
  border-bottom-style: dotted;
}
/*Heading Titles v1*/
.title-v1 {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}
.title-v1 h1,
.title-v1 h2 {
  color: #444;
  font-size: 28px;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}
.title-v1 h1:after,
.title-v1 h2:after {
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 70px;
  content: " ";
  margin-left: -35px;
  position: absolute;
  background: #72c02c;
}
.title-v1 p {
  font-size: 17px;
  font-weight: 200;
}
/*Heading Titles v2*/
h2.title-v2 {
  color: #555;
  position: relative;
  margin-bottom: 30px;
}
h2.title-v2:after {
  left: 0;
  width: 70px;
  height: 2px;
  content: " ";
  bottom: -10px;
  background: #555;
  position: absolute;
}
h1.title-v2.title-center,
h2.title-v2.title-center,
h3.title-v2.title-center {
  text-align: center;
}
h1.title-v2.title-center:after,
h2.title-v2.title-center:after,
h3.title-v2.title-center:after {
  left: 50%;
  width: 70px;
  margin-left: -35px;
}
h1.title-light,
h2.title-light,
h3.title-light {
  color: #fff;
}
h2.title-light:after {
  background: #fff;
}
/*Heading Title v3*/
h1[class^="title-v3-"],
h2[class^="title-v3-"],
h3[class^="title-v3-"] {
  color: #555;
}
h2.title-v3-xlg {
  font-size: 28px;
  line-height: 32px;
}
h1.title-v3-lg,
h2.title-v3-lg {
  font-size: 24px;
  line-height: 28px;
}
h1.title-v3-md,
h2.title-v3-md {
  font-size: 20px;
  line-height: 24px;
}
h2.title-v3-sm,
h3.title-v3-md {
  font-size: 18px;
  line-height: 24px;
}
h3.title-v3-md {
  line-height: 22px;
}
h3.title-v3-sm {
  font-size: 16px;
  line-height: 20px;
}
h2.title-v3-xs {
  font-size: 16px;
  line-height: 22px;
}
h3.title-v3-xs {
  font-size: 14px;
  margin-bottom: 0;
}
/*Headline Center*/
.headline-center {
  text-align: center;
  position: relative;
}
.headline-center h2 {
  color: #555;
  font-size: 24px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.headline-center h2:after {
  left: 50%;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -5px;
  margin-left: -15px;
  text-align: center;
  position: absolute;
  background: #72c02c;
}
.headline-center p {
  /*color: #999;*/
  font-size: 14px;
  /*padding: 0 150px;*/
}
@media (max-width: 991px) {
  .headline-center p {
    padding: 0 50px;
  }
}
.headline-center.headline-light h2 {
  color: #fff;
}
.headline-center.headline-light p {
  color: #eee;
}
/*Headline Center v2*/
.headline-center-v2 {
  z-index: 0;
  text-align: center;
  position: relative;
}
.headline-center-v2 h2 {
  color: #555;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.headline-center-v2 span.bordered-icon {
  color: #fff;
  padding: 0 10px;
  font-size: 15px;
  line-height: 18px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
}
.headline-center-v2 span.bordered-icon:before,
.headline-center-v2 span.bordered-icon:after {
  top: 8px;
  height: 1px;
  content: " ";
  width: 100px;
  background: #fff;
  position: absolute;
}
.headline-center-v2 span.bordered-icon:before {
  left: 100%;
}
.headline-center-v2 span.bordered-icon:after {
  right: 100%;
}
.headline-center-v2 p {
  color: #555;
  font-size: 14px;
  padding: 0 70px;
}
.headline-center-v2.headline-center-v2-dark p {
  color: #666;
}
.headline-center-v2.headline-center-v2-dark span.bordered-icon {
  color: #666;
}
.headline-center-v2.headline-center-v2-dark span.bordered-icon:before,
.headline-center-v2.headline-center-v2-dark span.bordered-icon:after {
  background: #666;
}
/*Headline Left*/
.headline-left {
  position: relative;
}
.headline-left .headline-brd {
  color: #555;
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
.headline-left .headline-brd:after {
  left: 1px;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -5px;
  position: absolute;
  background: #72c02c;
}
/*Headline v2
------------------------------------*/
.headline-v2 {
  display: block;
  background: #fff;
  padding: 1px 10px;
  margin: 0 0 20px 0;
  border-left: 2px solid #000;
}
.headline-v2 h2 {
  margin: 3px 0;
  font-size: 20px;
  font-weight: 200;
}
/*Heading Sizes
------------------------------------*/
h2.heading-md {
  font-size: 20px;
  line-height: 24px;
}
h2.heading-sm,
h3.heading-md {
  font-size: 18px;
  line-height: 24px;
}
h3.heading-md {
  line-height: 22px;
}
h3.heading-sm {
  font-size: 16px;
  line-height: 20px;
}
h2.heading-xs {
  font-size: 16px;
  line-height: 22px;
}
h3.heading-xs {
  font-size: 14px;
  margin-bottom: 0;
}
/*Devider
------------------------------------*/
.devider.devider-dotted {
  border-top: 2px dotted #eee;
}
.devider.devider-dashed {
  border-top: 2px dashed #eee;
}
.devider.devider-db {
  height: 5px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.devider.devider-db-dashed {
  height: 5px;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
}
.devider.devider-db-dotted {
  height: 5px;
  border-top: 1px dotted #ddd;
  border-bottom: 1px dotted #ddd;
}
/*Tables
------------------------------------*/
/*Basic Tables*/
.table thead > tr > th {
  border-bottom: none;
}
@media (max-width: 768px) {
  .table th.hidden-sm,
  .table td.hidden-sm {
    display: none !important;
  }
}
/*Forms
------------------------------------*/
.form-control {
  box-shadow: none;
}
.form-control:focus {
  border-color: #bbb;
  box-shadow: 0 0 2px #c9c9c9;
}
/*Form Spacing*/
.form-spacing .form-control {
  margin-bottom: 15px;
}
/*Form Icons*/
.input-group-addon {
  color: #b3b3b3;
  font-size: 14px;
  background: #fff;
}
/*Carousel v1
------------------------------------*/
.carousel-v1 .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.7);
}
.carousel-v1 .carousel-caption p {
  color: #fff;
  margin-bottom: 0;
}
.carousel-v1 .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit;
}
.carousel-v1 .carousel-arrow a i {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute;
}
.carousel-v1 .carousel-arrow a i:hover {
  opacity: 0.8;
}
.carousel-v1 .carousel-arrow a.left i {
  left: 0;
}
.carousel-v1 .carousel-arrow a.right i {
  right: 0;
}
/*Carousel v2
------------------------------------*/
.carousel-v2 .carousel-control,
.carousel-v2 .carousel-control:hover {
  opacity: 1;
  text-shadow: none;
}
.carousel-v2 .carousel-control.left,
.carousel-v2 .carousel-control.right {
  top: 50%;
  z-index: 5;
  color: #eee;
  width: 45px;
  height: 45px;
  font-size: 30px;
  margin-top: -22px;
  position: absolute;
  text-align: center;
  display: inline-block;
  border: 2px solid #eee;
  background: rgba(0, 0, 0, 0.1);
}
.carousel-v2 .carousel-control:hover {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.carousel-v2 .carousel-control.left {
  left: 20px;
}
.carousel-v2 .carousel-control.right {
  right: 20px;
}
.carousel-v2 .carousel-control .arrow-prev,
.carousel-v2 .carousel-control .arrow-next {
  top: -5px;
  position: relative;
}
.carousel-v2 .carousel-control .arrow-next {
  right: -2px;
}
@media (min-width: 768px) {
  .carousel-indicators {
    bottom: 10px;
  }
}
/*Tabs
------------------------------------*/
/*Tabs v1*/
.tab-v1 .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #72c02c;
}
.tab-v1 .nav-tabs a {
  font-size: 14px;
  padding: 5px 15px;
}
.tab-v1 .nav-tabs > .active > a,
.tab-v1 .nav-tabs > .active > a:hover,
.tab-v1 .nav-tabs > .active > a:focus {
  color: #fff;
  border: none;
  background: #72c02c;
}
.tab-v1 .nav-tabs > li > a {
  border: none;
}
.tab-v1 .nav-tabs > li > a:hover {
  color: #fff;
  background: #72c02c;
}
.tab-v1 .tab-content {
  padding: 10px 0;
}
.tab-v1 .tab-content img {
  margin-top: 4px;
  margin-bottom: 15px;
}
.tab-v1 .tab-content img.img-tab-space {
  margin-top: 7px;
}
/*Tabs v2*/
.tab-v2 .nav-tabs {
  border-bottom: none;
}
.tab-v2 .nav-tabs li a {
  padding: 9px 16px;
  background: none;
  border: none;
}
.tab-v2 .nav-tabs li.active a {
  background: #fff;
  padding: 7px 15px 9px;
  border: solid 1px #eee;
  border-top: solid 2px #72c02c;
  border-bottom: none !important;
}
.tab-v2 .tab-content {
  padding: 10px 16px;
  border: solid 1px #eee;
}
/*Tabs v3*/
.tab-v3 .nav-pills li a {
  color: #777;
  font-size: 17px;
  padding: 4px 8px;
  margin-bottom: 3px;
  background: #fafafa;
  border: solid 1px #eee;
}
.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
  color: #fff;
  background: #72c02c;
  border: solid 1px #68af28;
}
.tab-v3 .nav-pills li i {
  width: 1.25em;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
}
.tab-v3 .tab-content {
  padding: 15px;
  background: #fafafa;
  border: solid 1px #eee;
}
/*Accordions
------------------------------------*/
/*Accordion v1*/
.acc-v1 .panel-heading {
  padding: 0;
  box-shadow: none;
}
.acc-v1 .panel-heading a {
  display: block;
  font-size: 14px;
  padding: 5px 15px;
  background: #fefefe;
}
.acc-icon a.accordion-toggle i {
  color: #555;
  margin-right: 8px;
}
.acc-icon a.accordion-toggle:hover i {
  color: #39414c;
}
/*Navigation
------------------------------------*/
/*Pegination*/
.pagination li a {
  color: #777;
  padding: 5px 15px;
}
.pagination li a:hover {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-color: #72c02c;
  background-color: #72c02c;
}
/*Pagination Without Space*/
.pagination-no-space .pagination {
  margin: 0;
}
/*Pager*/
.pager li > a:hover,
.pager li > a:focus {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611;
}
/*Pager v2 and v3
------------------------------------*/
.pager.pager-v2 li > a {
  border: none;
}
.pager.pager-v2 li > a,
.pager.pager-v3 li > a {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.pager.pager-v2 li > a:hover,
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover,
.pager.pager-v3 li > a:focus {
  color: #fff;
  background: #72c02c;
}
/*Pager Amount*/
.pager.pager-v2 li.page-amount,
.pager.pager-v3 li.page-amount {
  font-size: 16px;
  font-style: italic;
}
.pager.pager-v2 li.page-amount,
.pager.pager-v2 li.page-amount:hover,
.pager.pager-v2 li.page-amount:focus,
.pager.pager-v3 li.page-amount,
.pager.pager-v3 li.page-amount:hover,
.pager.pager-v3 li.page-amount:focus {
  top: 7px;
  color: #777;
  position: relative;
}
/*Pager Size*/
.pager.pager-v2.pager-md li a,
.pager.pager-v3.pager-md li a {
  font-size: 16px;
  padding: 8px 18px;
}
/*Sidebar Menu
------------------------------------*/
/*Sidebar Menu v1*/
.sidebar-nav-v1 li {
  padding: 0;
}
.sidebar-nav-v1 li a {
  display: block;
  padding: 8px 30px 8px 10px;
}
.sidebar-nav-v1 li a:hover {
  text-decoration: none;
}
.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover {
  background: #717984;
}
.sidebar-nav-v1 > li.active,
.sidebar-nav-v1 > li.active:hover,
.sidebar-nav-v1 > li.active:focus {
  border-color: #ddd;
}
.sidebar-nav-v1 > li.active > a {
  color: #fff;
}
/*Sidebar Sub Navigation*/
.sidebar-nav-v1 li ul {
  padding: 0;
  list-style: none;
}
.sidebar-nav-v1 li ul,
.sidebar-nav-v1 li.active ul a {
  background: #f8f8f8;
}
.sidebar-nav-v1 li ul a {
  color: #555;
  font-size: 12px;
  border-top: solid 1px #ddd;
  padding: 6px 30px 6px 17px;
}
.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
  color: #72c02c;
}
/*Sidebar Badges*/
.list-group-item li > .badge {
  float: right;
}
.sidebar-nav-v1 span.badge {
  margin-top: 8px;
  margin-right: 10px;
}
.sidebar-nav-v1 .list-toggle > span.badge {
  margin-right: 25px;
}
.sidebar-nav-v1 ul li span.badge {
  margin-top: 8px;
  font-size: 11px;
  padding: 3px 5px;
  margin-right: 10px;
}
/*Sidebar List Toggle*/
.list-toggle:after {
  top: 7px;
  right: 10px;
  color: #777;
  font-size: 14px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}
.list-toggle.active:after {
  color: #fff;
  content: "\f107";
}
/*Button Styles
------------------------------------*/
.btn {
  box-shadow: none;
}
.btn-u {
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  padding: 6px 13px;
  position: relative;
  background: #72c02c;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}
.btn-u:hover {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-u.btn-block {
  text-align: center;
}
a.btn-u {
  /*padding: 4px 13px;*/
  /*vertical-align: middle;*/
}
.btn-u-sm,
a.btn-u-sm {
  padding: 3px 12px;
}
.btn-u-lg,
a.btn-u-lg {
  font-size: 18px;
  padding: 10px 25px;
}
.btn-u-xs,
a.btn-u-xs {
  font-size: 12px;
  padding: 2px 12px;
  line-height: 18px;
}
/*Button Groups*/
.btn-group .dropdown-menu > li > a {
  padding: 3px 13px;
}
.btn-group > .btn-u,
.btn-group-vertical > .btn-u {
  float: left;
  position: relative;
}
.btn-group > .btn-u:first-child {
  margin-left: 0;
}
/*For FF Only*/
@-moz-document url-prefix() {
  .footer-subsribe .btn-u {
    padding-bottom: 4px;
  }
}
@media (max-width: 768px) {
  @-moz-document url-prefix() {
    .btn-u {
      padding-bottom: 6px;
    }
  }
}
/*Buttons Color*/
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
  background: #5fb611;
}
.btn-u-split.dropdown-toggle {
  border-left: solid 1px #5fb611;
}
.btn-u.btn-u-blue {
  background: #3498db;
}
.btn-u.btn-u-blue:hover,
.btn-u.btn-u-blue:focus,
.btn-u.btn-u-blue:active,
.btn-u.btn-u-blue.active,
.open .dropdown-toggle.btn-u.btn-u-blue {
  background: #2980b9;
}
.btn-u.btn-u-split-blue.dropdown-toggle {
  border-left: solid 1px #2980b9;
}
.btn-u.btn-u-red {
  background: #e74c3c;
}
.btn-u.btn-u-red:hover,
.btn-u.btn-u-red:focus,
.btn-u.btn-u-red:active,
.btn-u.btn-u-red.active,
.open .dropdown-toggle.btn-u.btn-u-red {
  background: #c0392b;
}
.btn-u.btn-u-split-red.dropdown-toggle {
  border-left: solid 1px #c0392b;
}
.btn-u.btn-u-orange {
  background: #e67e22;
}
.btn-u.btn-u-orange:hover,
.btn-u.btn-u-orange:focus,
.btn-u.btn-u-orange:active,
.btn-u.btn-u-orange.active,
.open .dropdown-toggle.btn-u.btn-u-orange {
  background: #d35400;
}
.btn-u.btn-u-split-orange.dropdown-toggle {
  border-left: solid 1px #d35400;
}
.btn-u.btn-u-sea {
  background: #1abc9c;
}
.btn-u.btn-u-sea:hover,
.btn-u.btn-u-sea:focus,
.btn-u.btn-u-sea:active,
.btn-u.btn-u-sea.active,
.open .dropdown-toggle.btn-u.btn-u-sea {
  background: #16a085;
}
.btn-u.btn-u-split-sea.dropdown-toggle {
  border-left: solid 1px #16a085;
}
.btn-u.btn-u-green {
  background: #2ecc71;
}
.btn-u.btn-u-green:hover,
.btn-u.btn-u-green:focus,
.btn-u.btn-u-green:active,
.btn-u.btn-u-green.active,
.open .dropdown-toggle.btn-u.btn-u-green {
  background: #27ae60;
}
.btn-u.btn-u-split-green.dropdown-toggle {
  border-left: solid 1px #27ae60;
}
.btn-u.btn-u-yellow {
  background: #f1c40f;
}
.btn-u.btn-u-yellow:hover,
.btn-u.btn-u-yellow:focus,
.btn-u.btn-u-yellow:active,
.btn-u.btn-u-yellow.active,
.open .dropdown-toggle.btn-u.btn-u-yellow {
  background: #f39c12;
}
.btn-u.btn-u-split-yellow.dropdown-toggle {
  border-left: solid 1px #f39c12;
}
.btn-u.btn-u-default {
  background: #95a5a6;
}
.btn-u.btn-u-default:hover,
.btn-u.btn-u-default:focus,
.btn-u.btn-u-default:active,
.btn-u.btn-u-default.active,
.open .dropdown-toggle.btn-u.btn-u-default {
  background: #7f8c8d;
}
.btn-u.btn-u-split-default.dropdown-toggle {
  border-left: solid 1px #7f8c8d;
}
.btn-u.btn-u-purple {
  background: #9b6bcc;
}
.btn-u.btn-u-purple:hover,
.btn-u.btn-u-purple:focus,
.btn-u.btn-u-purple:active,
.btn-u.btn-u-purple.active,
.open .dropdown-toggle.btn-u.btn-u-purple {
  background: #814fb5;
}
.btn-u.btn-u-split-purple.dropdown-toggle {
  border-left: solid 1px #814fb5;
}
.btn-u.btn-u-aqua {
  background: #27d7e7;
}
.btn-u.btn-u-aqua:hover,
.btn-u.btn-u-aqua:focus,
.btn-u.btn-u-aqua:active,
.btn-u.btn-u-aqua.active,
.open .dropdown-toggle.btn-u.btn-u-aqua {
  background: #26bac8;
}
.btn-u.btn-u-split-aqua.dropdown-toggle {
  border-left: solid 1px #26bac8;
}
.btn-u.btn-u-brown {
  background: #9c8061;
}
.btn-u.btn-u-brown:hover,
.btn-u.btn-u-brown:focus,
.btn-u.btn-u-brown:active,
.btn-u.btn-u-brown.active,
.open .dropdown-toggle.btn-u.btn-u-brown {
  background: #81674b;
}
.btn-u.btn-u-split-brown.dropdown-toggle {
  border-left: solid 1px #81674b;
}
.btn-u.btn-u-dark-blue {
  background: #4765a0;
}
.btn-u.btn-u-dark-blue:hover,
.btn-u.btn-u-dark-blue:focus,
.btn-u.btn-u-dark-blue:active,
.btn-u.btn-u-dark-blue.active,
.open .dropdown-toggle.btn-u.btn-u-dark-blue {
  background: #324c80;
}
.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #324c80;
}
.btn-u.btn-u-light-green {
  background: #79d5b3;
}
.btn-u.btn-u-light-green:hover,
.btn-u.btn-u-light-green:focus,
.btn-u.btn-u-light-green:active,
.btn-u.btn-u-light-green.active,
.open .dropdown-toggle.btn-u.btn-u-light-green {
  background: #59b795;
}
.btn-u.btn-u-split-light-green.dropdown-toggle {
  border-left: solid 1px #59b795;
}
.btn-u.btn-u-dark {
  background: #555;
}
.btn-u.btn-u-dark:hover,
.btn-u.btn-u-dark:focus,
.btn-u.btn-u-dark:active,
.btn-u.btn-u-dark.active,
.open .dropdown-toggle.btn-u.btn-u-dark {
  background: #333;
}
.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #333;
}
.btn-u.btn-u-light-grey {
  background: #585f69;
}
.btn-u.btn-u-light-grey:hover,
.btn-u.btn-u-light-grey:focus,
.btn-u.btn-u-light-grey:active,
.btn-u.btn-u-light-grey.active,
.open .dropdown-toggle.btn-u.btn-u-light-grey {
  background: #484f58;
}
.btn-u.btn-u-split-light-grey.dropdown-toggle {
  border-left: solid 1px #484f58;
}
/*Bordered Buttons*/
.btn-u.btn-brd {
  color: #555;
  /*font-weight: 200;*/
  background: none;
  padding: 5px 13px;
  border: solid 1px transparent;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.btn-u.btn-brd:hover {
  background: none;
  border: solid 1px #eee;
}
.btn-u.btn-brd:focus {
  background: none;
}
.btn-u.btn-brd.btn-brd-hover:hover {
  color: #fff !important;
}
.btn-u.btn-brd {
  border-color: #72c02c;
}
.btn-u.btn-brd:hover {
  color: #5fb611;
  border-color: #5fb611;
}
.btn-u.btn-brd.btn-brd-hover:hover {
  background: #5fb611;
}
.btn-u.btn-brd.btn-u-blue {
  border-color: #3498db;
}
.btn-u.btn-brd.btn-u-blue:hover {
  color: #2980b9;
  border-color: #2980b9;
}
.btn-u.btn-brd.btn-u-blue.btn-brd-hover:hover {
  background: #2980b9;
}
.btn-u.btn-brd.btn-u-red {
  border-color: #e74c3c;
}
.btn-u.btn-brd.btn-u-red:hover {
  color: #c0392b;
  border-color: #c0392b;
}
.btn-u.btn-brd.btn-u-red.btn-brd-hover:hover {
  background: #c0392b;
}
.btn-u.btn-brd.btn-u-orange {
  border-color: #e67e22;
}
.btn-u.btn-brd.btn-u-orange:hover {
  color: #d35400;
  border-color: #d35400;
}
.btn-u.btn-brd.btn-u-orange.btn-brd-hover:hover {
  background: #d35400;
}
.btn-u.btn-brd.btn-u-sea {
  border-color: #1abc9c;
}
.btn-u.btn-brd.btn-u-sea:hover {
  color: #16a085;
  border-color: #16a085;
}
.btn-u.btn-brd.btn-u-sea.btn-brd-hover:hover {
  background: #16a085;
}
.btn-u.btn-brd.btn-u-green {
  border-color: #2ecc71;
}
.btn-u.btn-brd.btn-u-green:hover {
  color: #27ae60;
  border-color: #27ae60;
}
.btn-u.btn-brd.btn-u-green.btn-brd-hover:hover {
  background: #27ae60;
}
.btn-u.btn-brd.btn-u-yellow {
  border-color: #f1c40f;
}
.btn-u.btn-brd.btn-u-yellow:hover {
  color: #f39c12;
  border-color: #f39c12;
}
.btn-u.btn-brd.btn-u-yellow.btn-brd-hover:hover {
  background: #f39c12;
}
.btn-u.btn-brd.btn-u-default {
  border-color: #95a5a6;
}
.btn-u.btn-brd.btn-u-default:hover {
  color: #7f8c8d;
  border-color: #7f8c8d;
}
.btn-u.btn-brd.btn-u-default.btn-brd-hover:hover {
  background: #7f8c8d;
}
.btn-u.btn-brd.btn-u-dark {
  border-color: #555;
}
.btn-u.btn-brd.btn-u-dark:hover {
  color: #333;
  border-color: #333;
}
.btn-u.btn-brd.btn-u-dark.btn-brd-hover:hover {
  background: #333;
}
.btn-u.btn-brd.btn-u-light-grey {
  border-color: #585f69;
}
.btn-u.btn-brd.btn-u-light-grey:hover {
  color: #484f58;
  border-color: #484f58;
}
.btn-u.btn-brd.btn-u-light-grey.btn-brd-hover:hover {
  background: #484f58;
}
.btn-u.btn-brd.btn-u-purple {
  border-color: #9b6bcc;
}
.btn-u.btn-brd.btn-u-purple:hover {
  color: #814fb5;
  border-color: #814fb5;
}
.btn-u.btn-brd.btn-u-purple.btn-brd-hover:hover {
  background: #814fb5;
}
.btn-u.btn-brd.btn-u-aqua {
  border-color: #27d7e7;
}
.btn-u.btn-brd.btn-u-aqua:hover {
  color: #26bac8;
  border-color: #26bac8;
}
.btn-u.btn-brd.btn-u-aqua.btn-brd-hover:hover {
  background: #26bac8;
}
.btn-u.btn-brd.btn-u-brown {
  border-color: #9c8061;
}
.btn-u.btn-brd.btn-u-brown:hover {
  color: #81674b;
  border-color: #81674b;
}
.btn-u.btn-brd.btn-u-brown.btn-brd-hover:hover {
  background: #81674b;
}
.btn-u.btn-brd.btn-u-dark-blue {
  border-color: #4765a0;
}
.btn-u.btn-brd.btn-u-dark-blue:hover {
  color: #324c80;
  border-color: #324c80;
}
.btn-u.btn-brd.btn-u-dark-blue.btn-brd-hover:hover {
  background: #324c80;
}
.btn-u.btn-brd.btn-u-light-green {
  border-color: #79d5b3;
}
.btn-u.btn-brd.btn-u-light-green:hover {
  color: #59b795;
  border-color: #59b795;
}
.btn-u.btn-brd.btn-u-light-green.btn-brd-hover:hover {
  background: #59b795;
}
.btn-u.btn-brd.btn-u-light {
  color: #fff;
  border-color: #fff;
}
.btn-u.btn-brd.btn-u-light:hover {
  border-color: #fff;
}
.btn-u.btn-brd.btn-u-light.btn-brd-hover:hover {
  background: #fff;
  color: #555 !important;
}
/*Dropdown Buttons
------------------------------------*/
.dropdown-show {
  box-shadow: 0 0 4px #eee;
  display: inline-block;
  position: relative;
}
/*Badges and Labels
------------------------------------*/
/*Labels*/
span.label {
  font-size: 11px;
  font-weight: 400;
  padding: 4px 7px;
}
/*Badges*/
span.badge,
.list-group-item.active > .badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px;
}
span.label-u,
span.badge-u,
.list-group-item.active > .badge.badge-u {
  background: #72c02c;
}
span.label-blue,
span.badge-blue,
.list-group-item.active > .badge {
  background: #3498db;
}
span.label-red,
span.badge-red,
.list-group-item.active > .badge {
  background: #e74c3c;
}
span.label-green,
span.badge-green,
.list-group-item.active > .badge.badge-green {
  background: #2ecc71;
}
span.label-sea,
span.badge-sea,
.list-group-item.active > .badge.badge-sea {
  background: #1abc9c;
}
span.label-orange,
span.badge-orange,
.list-group-item.active > .badge.badge-orange {
  background: #e67e22;
}
span.label-yellow,
span.badge-yellow,
.list-group-item.active > .badge.badge-yellow {
  background: #f1c40f;
}
span.label-purple,
span.badge-purple,
.list-group-item.active > .badge.badge-purple {
  background: #9b6bcc;
}
span.label-aqua,
span.badge-aqua,
.list-group-item.active > .badge.badge-aqua {
  background: #27d7e7;
}
span.label-brown,
span.badge-brown,
.list-group-item.active > .badge.badge-brown {
  background: #9c8061;
}
span.label-dark-blue,
span.badge-dark-blue,
.list-group-item.active > .badge.badge-dark-blue {
  background: #4765a0;
}
span.label-light-green,
span.badge-light-green,
.list-group-item.active > .badge.badge-light-green {
  background: #79d5b3;
}
span.label-light,
span.badge-light,
.list-group-item.active > .badge.badge-light {
  color: #777;
  background: #ecf0f1;
}
span.label-dark,
span.badge-dark,
.list-group-item.active > .badge.badge-dark {
  background: #555;
}
/*Badge Lists*/
.badge-lists li {
  position: relative;
}
.badge-lists span.badge {
  top: -10px;
  right: -6px;
  position: absolute;
}
/*Badge Icons*/
.badge-lists.badge-icons span.badge {
  min-width: 12px;
  padding: 3px 6px;
}
.badge-lists.badge-icons i {
  font-size: 18px;
  min-width: 25px;
}
/*Badge Box v1*/
.badge-box-v1 a {
  color: #777;
  min-width: 40px;
  font-size: 18px;
  padding: 8px 9px;
  display: inline-block;
  border: solid 1px #eee;
}
/*Badge Box v2*/
.badge-box-v2 a {
  color: #777;
  font-size: 12px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eee;
}
.badge-box-v2 a i {
  font-size: 20px;
}
/*General Badge Box*/
.badge-box-v1 a i,
.badge-box-v2 a i {
  display: block;
  margin: 1px auto 2px;
}
.badge-box-v1 a:hover,
.badge-box-v2 a:hover {
  color: #555;
  border-color: #555;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*Icons
------------------------------------*/
/*Social Icons*/
.social-icons {
  margin: 0;
  padding: 0;
}
.social-icons li {
  list-style: none;
  margin-right: 3px;
  margin-bottom: 5px;
  text-indent: -9999px;
  display: inline-block;
}
.social-icons li a,
a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.social-icons li:hover a {
  background-position: 0 -38px;
}
.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
}
.social-icons-color li a:hover {
  opacity: 1;
}
.social_amazon {
  background: url(../img/icons/social/amazon.png) no-repeat;
}
.social_behance {
  background: url(../img/icons/social/behance.png) no-repeat;
}
.social_blogger {
  background: url(../img/icons/social/blogger.png) no-repeat;
}
.social_deviantart {
  background: url(../img/icons/social/deviantart.png) no-repeat;
}
.social_dribbble {
  background: url(../img/icons/social/dribbble.png) no-repeat;
}
.social_dropbox {
  background: url(../img/icons/social/dropbox.png) no-repeat;
}
.social_evernote {
  background: url(../img/icons/social/evernote.png) no-repeat;
}
.social_facebook {
  background: url(../img/icons/social/facebook.png) no-repeat;
}
.social_forrst {
  background: url(../img/icons/social/forrst.png) no-repeat;
}
.social_github {
  background: url(../img/icons/social/github.png) no-repeat;
}
.social_googleplus {
  background: url(../img/icons/social/googleplus.png) no-repeat;
}
.social_jolicloud {
  background: url(../img/icons/social/jolicloud.png) no-repeat;
}
.social_last-fm {
  background: url(../img/icons/social/last-fm.png) no-repeat;
}
.social_linkedin {
  background: url(../img/icons/social/linkedin.png) no-repeat;
}
.social_picasa {
  background: url(../img/icons/social/picasa.png) no-repeat;
}
.social_pintrest {
  background: url(../img/icons/social/pintrest.png) no-repeat;
}
.social_rss {
  background: url(../img/icons/social/rss.png) no-repeat;
}
.social_skype {
  background: url(../img/icons/social/skype.png) no-repeat;
}
.social_spotify {
  background: url(../img/icons/social/spotify.png) no-repeat;
}
.social_stumbleupon {
  background: url(../img/icons/social/stumbleupon.png) no-repeat;
}
.social_tumblr {
  background: url(../img/icons/social/tumblr.png) no-repeat;
}
.social_twitter {
  background: url(../img/icons/social/twitter.png) no-repeat;
}
.social_vimeo {
  background: url(../img/icons/social/vimeo.png) no-repeat;
}
.social_wordpress {
  background: url(../img/icons/social/wordpress.png) no-repeat;
}
.social_xing {
  background: url(../img/icons/social/xing.png) no-repeat;
}
.social_yahoo {
  background: url(../img/icons/social/yahoo.png) no-repeat;
}
.social_youtube {
  background: url(../img/icons/social/youtube.png) no-repeat;
}
.social_vk {
  background: url(../img/icons/social/vk.png) no-repeat;
}
.social_instagram {
  background: url(../img/icons/social/instagram.png) no-repeat;
}
/*Font Awesome Icon Styles*/
i.icon-custom {
  color: #555;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  border: solid 1px #555;
}
i.icon-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}
i.icon-md {
  width: 55px;
  height: 55px;
  font-size: 22px;
  line-height: 55px;
}
i.icon-lg {
  width: 60px;
  height: 60px;
  font-size: 31px;
  line-height: 60px;
  margin-bottom: 10px;
}
i.icon-2x {
  font-size: 30px;
}
i.icon-3x {
  font-size: 40px;
}
i.icon-4x {
  font-size: 50px;
}
/*Line Icons*/
i.icon-line {
  font-size: 17px;
}
i.icon-sm.icon-line {
  font-size: 14px;
}
i.icon-md.icon-line {
  font-size: 22px;
}
i.icon-lg.icon-line {
  font-size: 28px;
}
i.icon-2x.icon-line {
  font-size: 27px;
}
i.icon-3x.icon-line {
  font-size: 36px;
}
i.icon-4x.icon-line {
  font-size: 47px;
}
/*Icon Styles For Links*/
.link-icon,
.link-bg-icon {
  color: #555;
}
.link-icon:hover,
.link-bg-icon:hover {
  border: none;
  text-decoration: none;
}
.link-icon:hover i {
  color: #72c02c;
  background: none;
  border: solid 1px #72c02c;
}
.link-bg-icon:hover i {
  color: #72c02c;
  background: #72c02c;
  border-color: #72c02c;
  color: #fff !important;
}
/*Icons Color*/
i.icon-color-u,
i.icon-color-red,
i.icon-color-sea,
i.icon-color-dark,
i.icon-color-grey,
i.icon-color-blue,
i.icon-color-green,
i.icon-color-yellow,
i.icon-color-orange,
i.icon-color-purple,
i.icon-color-aqua,
i.icon-color-brown,
i.icon-color-dark-blue,
i.icon-color-light-grey,
i.icon-color-light-green {
  background: none;
}
i.icon-color-u {
  color: #72c02c;
  border: solid 1px #72c02c;
}
i.icon-color-blue {
  color: #3498db;
  border: solid 1px #3498db;
}
i.icon-color-red {
  color: #e74c3c;
  border: solid 1px #e74c3c;
}
i.icon-color-sea {
  color: #1abc9c;
  border: solid 1px #1abc9c;
}
i.icon-color-green {
  color: #2ecc71;
  border: solid 1px #2ecc71;
}
i.icon-color-yellow {
  color: #f1c40f;
  border: solid 1px #f1c40f;
}
i.icon-color-orange {
  color: #e67e22;
  border: solid 1px #e67e22;
}
i.icon-color-grey {
  color: #95a5a6;
  border: solid 1px #95a5a6;
}
i.icon-color-purple {
  color: #9b6bcc;
  border: solid 1px #9b6bcc;
}
i.icon-color-aqua {
  color: #27d7e7;
  border: solid 1px #27d7e7;
}
i.icon-color-brown {
  color: #9c8061;
  border: solid 1px #9c8061;
}
i.icon-color-dark-blue {
  color: #4765a0;
  border: solid 1px #4765a0;
}
i.icon-color-light-green {
  color: #79d5b3;
  border: solid 1px #79d5b3;
}
i.icon-color-light {
  color: #fff;
  border: solid 1px #fff;
}
i.icon-color-light-grey {
  color: #585f69;
  border: solid 1px #585f69;
}
/*Icons Backgroun Color*/
i.icon-bg-u,
i.icon-bg-red,
i.icon-bg-sea,
i.icon-bg-dark,
i.icon-bg-darker,
i.icon-bg-grey,
i.icon-bg-blue,
i.icon-bg-green,
i.icon-bg-yellow,
i.icon-bg-orange,
i.icon-bg-purple,
i.icon-bg-aqua,
i.icon-bg-brown,
i.icon-bg-dark-blue,
i.icon-bg-light-grey,
i.icon-bg-light-green {
  color: #fff;
  border-color: transparent;
}
i.icon-bg-u {
  background: #72c02c;
}
i.icon-bg-blue {
  background: #3498db;
}
i.icon-bg-red {
  background: #e74c3c;
}
i.icon-bg-sea {
  background: #1abc9c;
}
i.icon-bg-green {
  background: #2ecc71;
}
i.icon-bg-yellow {
  background: #f1c40f;
}
i.icon-bg-orange {
  background: #e67e22;
}
i.icon-bg-grey {
  background: #95a5a6;
}
i.icon-bg-dark {
  background: #555;
}
i.icon-bg-darker {
  background: #333;
}
i.icon-bg-purple {
  background: #9b6bcc;
}
i.icon-bg-aqua {
  background: #27d7e7;
}
i.icon-bg-brown {
  background: #9c8061;
}
i.icon-bg-dark-blue {
  background: #4765a0;
}
i.icon-bg-light-green {
  background: #79d5b3;
}
i.icon-bg-light {
  background: #fff;
  border-color: transparent;
}
i.icon-bg-light-grey {
  background: #585f69;
  border-color: transparent;
}
/* Make Font Awesome icons fixed width */
.fa-fixed [class^="fa"],
.fa-fixed [class*=" fa"] {
  width: 1.25em;
  text-align: center;
  display: inline-block;
}
.fa-fixed [class^="fa"].fa-lg,
.fa-fixed [class*=" fa"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em;
}
/*Content Boxes
------------------------------------*/
/*Content Boxes v1*/
.content-boxes-v1 {
  text-align: center;
}
.content-boxes-v1 span {
  display: block;
  margin-top: 5px;
}
/*Content Boxes v2*/
@media (max-width: 992px) {
  .content-boxes-v2,
  .content-boxes-v2 .text-justify {
    text-align: center;
  }
  .content-boxes-v2 span {
    display: block;
    margin-top: 5px;
  }
}
/*Content Boxes v3*/
.content-boxes-v3 i.icon-custom {
  top: 8px;
  float: left;
  position: relative;
}
.content-boxes-v3 .content-boxes-in-v3 {
  padding: 0 10px;
  overflow: hidden;
}
.content-boxes-v3 .content-boxes-in-v3 h3 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.content-boxes-v3 .content-boxes-in-v3 h3 a {
  color: #555;
}
/*Content Boxes Right v3*/
.content-boxes-v3.content-boxes-v3-right {
  text-align: right;
}
.content-boxes-v3.content-boxes-v3-right i.icon-custom {
  float: right;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .content-boxes-v3.content-boxes-v3-right {
    text-align: inherit;
  }
  .content-boxes-v3.content-boxes-v3-right i.icon-custom {
    float: left;
    margin-left: 0;
  }
}
/*Content Boxes v4*/
.content-boxes-v4 h2 {
  color: #555;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.content-boxes-v4 a {
  color: #777;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.content-boxes-v4 i {
  width: 25px;
  color: #72c02c;
  font-size: 35px;
  margin-top: 10px;
}
.content-boxes-in-v4 {
  padding: 0 10px;
  overflow: hidden;
}
.content-boxes-v4-sm i {
  font-size: 26px;
  margin-top: 10px;
  margin-right: 5px;
}
/*Content Boxes v5*/
.content-boxes-v5 i {
  float: left;
  color: #999;
  width: 50px;
  height: 50px;
  padding: 11px;
  font-size: 22px;
  background: #eee;
  line-height: 28px;
  text-align: center;
  margin-right: 15px;
  display: inline-block;
}
.content-boxes-v5:hover i {
  color: #fff;
  background: #72c02c;
}
/*Content Boxes v6*/
.content-boxes-v6 {
  padding-top: 25px;
  text-align: center;
}
.content-boxes-v6 i {
  color: #fff;
  width: 90px;
  height: 90px;
  padding: 30px;
  font-size: 30px;
  line-height: 30px;
  position: relative;
  text-align: center;
  background: #dedede;
  margin-bottom: 25px;
  display: inline-block;
}
.content-boxes-v6 i:after {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  content: " ";
  position: absolute;
  border: 1px solid #dedede;
  border-radius: 50% !important;
}
.content-boxes-v6:hover i,
.content-boxes-v6:hover i:after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.content-boxes-v6:hover i {
  background: #72c02c;
}
.content-boxes-v6:hover i:after {
  border-color: #72c02c;
}
/*Colored Content Boxes
------------------------------------*/
.service-block {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 20px;
}
.service-block p,
.service-block h2 {
  color: #fff;
}
.service-block h2 a:hover {
  text-decoration: none;
}
.service-block-light,
.service-block-default {
  background: #fafafa;
  border: solid 1px #eee;
}
.service-block-default:hover {
  box-shadow: 0 0 8px #eee;
}
.service-block-light p,
.service-block-light h2,
.service-block-default p,
.service-block-default h2 {
  color: #555;
}
.service-block-u {
  background: #72c02c;
}
.service-block-blue {
  background: #3498db;
}
.service-block-red {
  background: #e74c3c;
}
.service-block-sea {
  background: #1abc9c;
}
.service-block-grey {
  background: #95a5a6;
}
.service-block-yellow {
  background: #f1c40f;
}
.service-block-orange {
  background: #e67e22;
}
.service-block-green {
  background: #2ecc71;
}
.service-block-purple {
  background: #9b6bcc;
}
.service-block-aqua {
  background: #27d7e7;
}
.service-block-brown {
  background: #9c8061;
}
.service-block-dark-blue {
  background: #4765a0;
}
.service-block-light-green {
  background: #79d5b3;
}
.service-block-dark {
  background: #555;
}
.service-block-light {
  background: #ecf0f1;
}
/*Funny Boxes
------------------------------------*/
.funny-boxes {
  background: #f7f7f7;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.funny-boxes h2 {
  margin-top: 0;
  font-size: 18px;
  line-height: 20px;
}
.funny-boxes h2 a {
  color: #555;
}
.funny-boxes p a {
  color: #72c02c;
}
.funny-boxes .funny-boxes-img li {
  font-size: 12px;
  margin-bottom: 2px;
}
.funny-boxes .funny-boxes-img li i {
  color: #72c02c;
  font-size: 12px;
  margin-right: 5px;
}
@media (max-width: 992px) {
  .funny-boxes .funny-boxes-img li {
    display: inline-block;
  }
}
.funny-boxes .funny-boxes-img img {
  margin: 5px 10px 15px 0;
}
.funny-boxes ul.funny-boxes-rating li {
  display: inline-block;
}
.funny-boxes ul.funny-boxes-rating li i {
  color: #f8be2c;
  cursor: pointer;
  font-size: 14px;
}
.funny-boxes ul.funny-boxes-rating li i:hover {
  color: #f8be2c;
}
/*Funny Colored Boxes*/
.funny-boxes-colored p,
.funny-boxes-colored h2 a,
.funny-boxes-colored .funny-boxes-img li,
.funny-boxes-colored .funny-boxes-img li i {
  color: #fff;
}
/*Red Funny Box*/
.funny-boxes-red {
  background: #e74c3c;
}
/*Dark Red Funny Box*/
.funny-boxes-purple {
  background: #9b6bcc;
}
/*Blue Funny Box*/
.funny-boxes-blue {
  background: #3498db;
}
/*Grey Funny Box*/
.funny-boxes-grey {
  background: #95a5a6;
}
/*Turquoise Funny Box*/
.funny-boxes-sea {
  background: #1abc9c;
}
/*Turquoise Top Bordered Funny Box*/
.funny-boxes-top-sea {
  border-top: solid 2px #1abc9c;
}
.funny-boxes-top-sea:hover {
  border-top-color: #16a085;
}
/*Yellow Top Bordered Funny Box**/
.funny-boxes-top-yellow {
  border-top: solid 2px #f1c40f;
}
.funny-boxes-top-yellow:hover {
  border-top-color: #f39c12;
}
/*Red Top Bordered Funny Box**/
.funny-boxes-top-red {
  border-top: solid 2px #e74c3c;
}
.funny-boxes-top-red:hover {
  border-top-color: #c0392b;
}
/*Purple Top Bordered Funny Box**/
.funny-boxes-top-purple {
  border-top: solid 2px #9b6bcc;
}
.funny-boxes-top-purple:hover {
  border-top-color: #814fb5;
}
/*Orange Left Bordered Funny Box**/
.funny-boxes-left-orange {
  border-left: solid 2px #e67e22;
}
.funny-boxes-left-orange:hover {
  border-left-color: #d35400;
}
/*Green Left Bordered Funny Box**/
.funny-boxes-left-green {
  border-left: solid 2px #72c02c;
}
.funny-boxes-left-green:hover {
  border-left-color: #5fb611;
}
/*Blue Left Bordered Funny Box**/
.funny-boxes-left-blue {
  border-left: solid 2px #3498db;
}
.funny-boxes-left-blue:hover {
  border-left-color: #2980b9;
}
/*Dark Left Bordered Funny Box**/
.funny-boxes-left-dark {
  border-left: solid 2px #555;
}
.funny-boxes-left-dark:hover {
  border-left-color: #333;
}
/*Typography
------------------------------------*/
.text-justify p {
  text-align: justify;
}
.text-transform-uppercase {
  text-transform: uppercase;
}
.text-transform-normal {
  text-transform: inherit !important;
}
.font-bold {
  font-weight: 600;
}
.font-light {
  font-weight: 200;
}
.font-normal {
  font-weight: 400 !important;
}
/*Text Dropcap*/
.dropcap {
  float: left;
  color: #72c02c;
  padding: 5px 0;
  font-size: 45px;
  font-weight: 200;
  line-height: 30px;
  margin: 0px 5px 0 0;
}
.dropcap-bg {
  float: left;
  color: #fff;
  padding: 7px 0;
  min-width: 50px;
  font-size: 35px;
  font-weight: 200;
  line-height: 35px;
  text-align: center;
  background: #72c02c;
  margin: 4px 10px 0 0;
}
/*Text Highlights*/
.text-highlights {
  color: #fff;
  font-weight: 200;
  padding: 0px 5px;
  background: #555;
}
.text-highlights-green {
  background: #72c02c;
}
.text-highlights-blue {
  background: #3498db;
}
.text-highlights-red {
  background: #e74c3c;
}
.text-highlights-sea {
  background: #1abc9c;
}
.text-highlights-orange {
  background: #e67e22;
}
.text-highlights-yellow {
  background: #f1c40f;
}
.text-highlights-purple {
  background: #9b6bcc;
}
.text-highlights-aqua {
  background: #27d7e7;
}
.text-highlights-brown {
  background: #9c8061;
}
.text-highlights-dark-blue {
  background: #4765a0;
}
.text-highlights-light-green {
  background: #79d5b3;
}
/*Text Borders*/
.text-border {
  border-bottom: dashed 1px #555;
}
.text-border-default {
  border-color: #95a5a6;
}
.text-border-green {
  border-color: #72c02c;
}
.text-border-blue {
  border-color: #3498db;
}
.text-border-red {
  border-color: #e74c3c;
}
.text-border-yellow {
  border-color: #f1c40f;
}
.text-border-purple {
  border-color: #9b6bcc;
}
/*List Styles*/
.list-styles li {
  margin-bottom: 8px;
}
/*Contextual Backgrounds*/
.contex-bg p {
  opacity: 0.8;
  padding: 8px 10px;
}
.contex-bg p:hover {
  opacity: 1;
}
/*Blockquote*/
blockquote {
  padding: 5px 15px;
  border-left-width: 2px;
}
blockquote p {
  font-size: 14px;
  font-weight: 400;
}
blockquote h1,
blockquote h2,
blockquote span {
  font-size: 18px;
  margin: 0 0 8px;
  line-height: 24px;
}
/*Blockquote Styles*/
blockquote.bq-text-lg p,
blockquote.bq-text-lg small {
  text-transform: uppercase;
}
blockquote.bq-text-lg p {
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
}
blockquote.text-right,
blockquote.hero.text-right {
  border-left: none;
  border-right: 2px solid #eee;
}
blockquote.hero.text-right,
blockquote.hero.text-right:hover {
  border-color: #555;
}
blockquote:hover,
blockquote.text-right:hover {
  border-color: #72c02c;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
blockquote.bq-dark,
blockquote.bq-dark:hover {
  border-color: #585f69;
}
blockquote.bq-green {
  border-color: #72c02c;
}
/*Blockquote Hero Styles*/
blockquote.hero {
  border: none;
  padding: 18px;
  font-size: 16px;
  background: #f3f3f3;
  border-left: solid 2px #666;
}
blockquote.hero:hover {
  background: #eee;
  border-left-color: #666;
}
blockquote.hero.hero-dark,
blockquote.hero.hero-default {
  border: none;
}
blockquote.hero.hero-dark {
  background: #444;
}
blockquote.hero.hero-dark:hover {
  background: #555;
}
blockquote.hero.hero-default {
  background: #72c02c;
}
blockquote.hero.hero-default:hover {
  background: #5fb611;
}
blockquote.hero.hero-dark p,
blockquote.hero.hero-dark h2,
blockquote.hero.hero-dark small,
blockquote.hero.hero-default p,
blockquote.hero.hero-default h2,
blockquote.hero.hero-default small {
  color: #fff;
  font-weight: 200;
}
/*Tag Boxes
------------------------------------*/
.tag-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
}
.tag-box h2 {
  font-size: 20px;
  line-height: 25px;
}
.tag-box p {
  margin-bottom: 0;
}
.tag-box.tag-text-space p {
  margin-bottom: 10px;
}
/*Tag Boxes v1*/
.tag-box-v1 {
  border: solid 1px #eee;
  border-top: solid 2px #72c02c;
}
/*Tag Boxes v2*/
.tag-box-v2 {
  background: #fafafa;
  border: solid 1px #eee;
  border-left: solid 2px #72c02c;
}
/*Tag Boxes v3*/
.tag-box-v3 {
  border: solid 2px #eee;
}
/*Tag Boxes v4*/
.tag-box-v4 {
  border: dashed 1px #bbb;
}
/*Tag Boxes v5*/
.tag-box-v5 {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc;
}
.tag-box-v5 span {
  color: #555;
  font-size: 28px;
  margin-bottom: 0;
}
/*Tag Boxes v6*/
.tag-box-v6 {
  background: #fafafa;
  border: solid 1px #eee;
}
/*Tag Boxes v7*/
.tag-box-v7 {
  border: solid 1px #eee;
  border-bottom: solid 2px #72c02c;
}
/*Testimonials
------------------------------------*/
/*Testimonials*/
.testimonials {
  margin-bottom: 10px;
}
.testimonials .testimonial-info {
  color: #72c02c;
  font-size: 16px;
  padding: 0 15px;
  margin-top: 18px;
}
.testimonials .testimonial-info span {
  top: 3px;
  position: relative;
}
.testimonials .testimonial-info em {
  color: #777;
  display: block;
  font-size: 13px;
}
.testimonials .testimonial-info img {
  width: 60px;
  float: left;
  height: 60px;
  padding: 2px;
  margin-right: 15px;
  border: solid 1px #ccc;
}
.testimonials .testimonial-author {
  overflow: hidden;
}
.testimonials .carousel-arrow {
  top: -65px;
  position: relative;
}
.testimonials .carousel-arrow i {
  color: #777;
  padding: 2px;
  min-width: 25px;
  font-size: 20px;
  text-align: center;
  background: #f5f5f5;
}
.testimonials .carousel-arrow i:hover {
  color: #fff;
  background: #72c02c;
}
.testimonials .carousel-control {
  opacity: 1;
  width: 100%;
  text-align: right;
  text-shadow: none;
  position: absolute;
}
.testimonials .carousel-control.left {
  right: 27px;
  left: auto;
}
.testimonials .carousel-control.right {
  right: 0px;
}
/*Testimonials v1*/
.testimonials.testimonials-v1 .item p {
  position: relative;
}
.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v1 .item p:before {
  left: 80px;
  bottom: -20px;
}
.testimonials.testimonials-v1 .item p:after {
  border-top: 22px solid;
  border-left: 0 solid transparent;
  border-right: 22px solid transparent;
}
/*Testimonials v2*/
.testimonials.testimonials-v2 .testimonial-info {
  padding: 0 20px;
}
.testimonials.testimonials-v2 p {
  padding-bottom: 15px;
}
.testimonials.testimonials-v2 .carousel-arrow {
  top: -55px;
}
.testimonials.testimonials-v2 .item p:after,
.testimonials.testimonials-v2 .item p:before {
  left: 8%;
  bottom: 45px;
}
.testimonials.testimonials-v2 .item p:after {
  border-top: 20px solid;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
}
/*General Testimonials v1/v2*/
.testimonials.testimonials-v1 p,
.testimonials.testimonials-v2 p {
  padding: 15px;
  font-size: 14px;
  font-style: italic;
  background: #f5f5f5;
}
.testimonials.testimonials-v1 .item p:after,
.testimonials.testimonials-v2 .item p:after {
  width: 0;
  height: 0;
  content: " ";
  display: block;
  position: absolute;
  border-top-color: #f5f5f5;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}
/*Testimonials Backgrounds*/
.testimonials-bg-dark .item p,
.testimonials-bg-default .item p {
  color: #fff;
  font-weight: 200;
}
.testimonials-bg-dark .carousel-arrow i,
.testimonials-bg-default .carousel-arrow i {
  color: #fff;
}
/*Testimonials Default*/
.testimonials-bg-default .item p {
  background: #72c02c;
}
.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #72c02c;
}
.testimonials-bg-default .carousel-arrow i {
  background: #72c02c;
}
.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #5fb611;
}
/*Testimonials Dark*/
.testimonials-bg-dark .item p {
  background: #555;
}
.testimonials.testimonials-bg-dark .item p:after,
.testimonials.testimonials-bg-dark .item p:after {
  border-top-color: #555;
}
.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
  background: #555;
}
.testimonials.testimonials-bg-dark .carousel-arrow i:hover {
  background: #333;
}
.testimonials.testimonials-bg-dark .testimonial-info {
  color: #555;
}
/*Panels (Portlets)
------------------------------------*/
.panel-heading {
  color: #fff;
  padding: 5px 15px;
}
/*Panel Table*/
.panel .table {
  margin-bottom: 0;
}
/*Panel Unify*/
.panel-u {
  border-color: #72c02c;
}
.panel-u > .panel-heading {
  background: #72c02c;
}
/*Panel Blue*/
.panel-blue {
  border-color: #3498db;
}
.panel-blue > .panel-heading {
  background: #3498db;
}
/*Panel Red*/
.panel-red {
  border-color: #e74c3c;
}
.panel-red > .panel-heading {
  background: #e74c3c;
}
/*Panel Green*/
.panel-green {
  border-color: #2ecc71;
}
.panel-green > .panel-heading {
  background: #2ecc71;
}
/*Panel Sea*/
.panel-sea {
  border-color: #1abc9c;
}
.panel-sea > .panel-heading {
  background: #1abc9c;
}
/*Panel Orange*/
.panel-orange {
  border-color: #e67e22;
}
.panel-orange > .panel-heading {
  background: #e67e22;
}
/*Panel Yellow*/
.panel-yellow {
  border-color: #f1c40f;
}
.panel-yellow > .panel-heading {
  background: #f1c40f;
}
/*Panel Grey*/
.panel-grey {
  border-color: #95a5a6;
}
.panel-grey > .panel-heading {
  background: #95a5a6;
}
/*Panel Dark*/
.panel-dark {
  border-color: #555;
}
.panel-dark > .panel-heading {
  background: #555;
}
/*Panel Purple*/
.panel-purple {
  border-color: #9b6bcc;
}
.panel-purple > .panel-heading {
  background: #9b6bcc;
}
/*Panel Aqua*/
.panel-aqua {
  border-color: #27d7e7;
}
.panel-aqua > .panel-heading {
  background: #27d7e7;
}
/*Panel Brown*/
.panel-brown {
  border-color: #9c8061;
}
.panel-brown > .panel-heading {
  background: #9c8061;
}
/*Panel Dark Blue*/
.panel-dark-blue {
  border-color: #4765a0;
}
.panel-dark-blue > .panel-heading {
  background: #4765a0;
}
/*Panel Light Green*/
.panel-light-green {
  border-color: #79d5b3;
}
.panel-light-green > .panel-heading {
  background: #79d5b3;
}
/*Panel Default Dark*/
.panel-default-dark {
  border-color: #585f69;
}
.panel-default-dark > .panel-heading {
  background: #585f69;
}
/*Progress Bar
------------------------------------*/
.progress-u {
  box-shadow: none;
}
.progress-u .progress-bar {
  box-shadow: none;
}
/*progress-bar (sizes)*/
.progress-lg {
  height: 25px;
}
.progress-lg p {
  padding-top: 3px;
}
.progress-sm {
  height: 12px;
}
.progress-xs {
  height: 7px;
}
.progress-xxs {
  height: 3px;
}
/*progress-bar (colors)*/
.progress {
  background: #e5e5e5;
}
.progress-bar-u {
  background: #72c02c;
}
.progress-bar-blue {
  background: #3498db;
}
.progress-bar-orange {
  background: #e67e22;
}
.progress-bar-red {
  background: #e74c3c;
}
.progress-bar-purple {
  background: #9b6bcc;
}
.progress-bar-aqua {
  background: #27d7e7;
}
.progress-bar-brown {
  background: #9c8061;
}
.progress-bar-dark-blue {
  background: #4765a0;
}
.progress-bar-light-green {
  background: #79d5b3;
}
.progress-bar-dark {
  background: #555;
}
/*Progress Bar Animation
------------------------------------*/
.progress {
  position: relative;
}
.progress .progress-bar {
  overflow: hidden;
  line-height: 20px;
  position: absolute;
}
.progress-box .progress-bar {
  transition: all 3s ease-in;
  -o-transition: all 3s ease-in;
  -ms-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  -webkit-transition: all 3s ease-in;
}
/*Vertical Progress Bar*/
.progress.vertical {
  float: left;
  width: 100%;
  height: 200px;
  margin-right: 20px;
}
.progress.vertical.bottom {
  position: relative;
}
.progress.vertical .progress-bar {
  height: 0;
  width: 100%;
  transition: height 3s ease;
  -o-transition: height 3s ease;
  -ms-transition: height 3s ease;
  -moz-transition: height 3s ease;
  -webkit-transition: height 3s ease;
}
.progress.vertical.bottom .progress-bar {
  bottom: 0;
  position: absolute;
}
/*Count Stars
------------------------------------*/
.stars-existing {
  color: #72c02c;
  cursor: pointer;
}
.star-lg {
  font-size: 30px;
}
.star-sm {
  font-size: 25px;
}
.star-xs {
  font-size: 20px;
}
.star-default {
  font-size: 16px;
}
/*Media (Audio/Videos and Images)
------------------------------------*/
/*Images*/
img.img-bordered {
  padding: 3px;
  border: solid 1px #eee;
}
img.img-circle {
  border-radius: 50% !important;
}
img.image-sm {
  width: 50px;
  height: 50px;
}
img.image-md {
  width: 100px;
  height: 100px;
}
/*Responsive Video*/
.responsive-video {
  height: 0;
  padding-top: 1px;
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/
}
.responsive-video iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
/*Tags v1
------------------------------------*/
.tags-v1 li {
  margin: 0;
  padding: 0;
}
.tags-v1 li a {
  font-size: 13px;
  padding: 4px 8px;
  line-height: 32px;
  border: solid 2px #eee;
  border-radius: 20px !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.tags-v1 li a:hover {
  text-decoration: none;
  border-color: #e0e0e0;
}
/*Tags v2
------------------------------------*/
.tags-v2 li {
  padding: 7px 0 7px 4px;
}
.tags-v2 li a {
  color: #555;
  font-size: 13px;
  padding: 5px 10px;
  border: solid 1px #bbb;
}
.tags-v2 li a:hover {
  color: #fff;
  background: #555;
  border-color: #555;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*Lists
------------------------------------*/
.list-row {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}
/*Lists v1*/
.lists-v1 li {
  margin-bottom: 10px;
}
.lists-v1 i {
  color: #fff;
  width: 15px;
  height: 15px;
  padding: 1px;
  font-size: 13px;
  margin-right: 7px;
  text-align: center;
  background: #72c02c;
  display: inline-block;
  border-radius: 50% !important;
}
/*Lists v2*/
.lists-v2 li {
  margin-bottom: 10px;
}
.lists-v2 i {
  color: #72c02c;
  font-size: 13px;
  margin-right: 7px;
  display: inline-block;
}
/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.no-gutter.no-gutter-boxed {
  padding-right: 15px;
  padding-left: 15px;
}
/*Heights
------------------------------------*/
.height-100 {
  min-height: 100px;
}
.height-150 {
  min-height: 150px;
}
.height-200 {
  min-height: 200px;
}
.height-250 {
  min-height: 250px;
}
.height-300 {
  min-height: 300px;
}
.height-350 {
  min-height: 350px;
}
.height-400 {
  min-height: 400px;
}
.height-450 {
  min-height: 450px;
}
.height-500 {
  min-height: 500px !important;
}
/*Spaces
------------------------------------*/
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0;
}
.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.content-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}
.content {
  padding-top: 40px;
  padding-bottom: 40px;
}
.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}
.content-md {
  padding-top: 80px;
  padding-bottom: 80px;
}
.content-lg {
  padding-top: 100px;
  padding-bottom: 100px;
}
.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px;
}
.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px;
}
.margin-bottom-5,
.margin-bottom-10,
.margin-bottom-15,
.margin-bottom-20,
.margin-bottom-25,
.margin-bottom-30,
.margin-bottom-35,
.margin-bottom-40,
.margin-bottom-45,
.margin-bottom-50,
.margin-bottom-55,
.margin-bottom-60 {
  clear: both;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-55 {
  margin-bottom: 55px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .sm-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .sm-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .sm-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .sm-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .sm-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .sm-margin-bottom-60 {
    margin-bottom: 60px;
  }
}
@media (max-width: 992px) {
  .md-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .md-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .md-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .md-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .md-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .md-margin-bottom-60 {
    margin-bottom: 60px;
  }
}
/*Other Spaces*/
.margin-top-20 {
  margin-top: 20px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-left-5 {
  padding-left: 5px;
}
/*Text Colors
------------------------------------*/
.color-sea {
  color: #1abc9c;
}
.color-red {
  color: #e74c3c;
}
.color-aqua {
  color: #27d7e7;
}
.color-blue {
  color: #3498db;
}
.color-grey {
  color: #95a5a6;
}
.color-dark {
  color: #555555;
}
.color-green {
  color: #72c02c;
}
.color-brown {
  color: #9c8061;
}
.color-light {
  color: #ffffff;
}
.color-orange {
  color: #e67e22;
}
.color-yellow {
  color: #f1c40f;
}
.color-green1 {
  color: #2ecc71;
}
.color-purple {
  color: #9b6bcc;
}
.color-inherit {
  color: inherit;
}
.color-dark-blue {
  color: #4765a0;
}
.color-light-grey {
  color: #585f69;
}
.color-light-green {
  color: #79d5b3;
}
/*Background Colors
------------------------------------*/
.bg-color-dark,
.bg-color-sea,
.bg-color-red,
.bg-color-aqua,
.bg-color-blue,
.bg-color-grey,
.bg-color-light,
.bg-color-green,
.bg-color-brown,
.bg-color-orange,
.bg-color-green1,
.bg-color-purple,
.bg-color-dark-blue,
.bg-color-light-grey,
.bg-color-light-green {
  color: #fff;
}
.bg-color-white {
  color: #555;
}
.bg-color-dark {
  background-color: #555 !important;
}
.bg-color-white {
  background-color: #fff !important;
}
.bg-color-sea {
  background-color: #1abc9c !important;
}
.bg-color-red {
  background-color: #e74c3c !important;
}
.bg-color-aqua {
  background-color: #27d7e7 !important;
}
.bg-color-blue {
  background-color: #3498db !important;
}
.bg-color-grey {
  background-color: #95a5a6 !important;
}
.bg-color-light {
  background-color: #f7f7f7 !important;
}
.bg-color-green {
  background-color: #72c02c !important;
}
.bg-color-brown {
  background-color: #9c8061 !important;
}
.bg-color-orange {
  background-color: #e67e22 !important;
}
.bg-color-green1 {
  background-color: #2ecc71 !important;
}
.bg-color-purple {
  background-color: #9b6bcc !important;
}
.bg-color-dark-blue {
  background-color: #4765a0 !important;
}
.bg-color-light-grey {
  background-color: #585f69 !important;
}
.bg-color-light-green {
  background-color: #79d5b3 !important;
}
.rgba-red {
  background-color: rgba(231, 76, 60, 0.8);
}
.rgba-blue {
  background-color: rgba(52, 152, 219, 0.8);
}
.rgba-aqua {
  background-color: rgba(39, 215, 231, 0.8);
}
.rgba-yellow {
  background-color: rgba(241, 196, 15, 0.8);
}
.rgba-default {
  background-color: rgba(114, 192, 44, 0.8);
}
.rgba-purple {
  background-color: rgba(155, 107, 204, 0.8);
}
/*Grey Backroud*/
.bg-grey {
  background: #f7f7f7;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
/*Rounded and Circle Classes
------------------------------------*/
.no-rounded {
  border-radius: 0 !important;
}
.rounded {
  border-radius: 4px !important;
}
.rounded-x {
  border-radius: 50% !important;
}
.rounded-2x {
  border-radius: 10px !important;
}
.rounded-3x {
  border-radius: 15px !important;
}
.rounded-4x {
  border-radius: 20px !important;
}
.rounded-sm {
  border-radius: 2px !important;
}
.rounded-md {
  border-radius: 3px !important;
}
.rounded-top {
  border-radius: 4px 4px 0 0 !important;
}
.rounded-left {
  border-radius: 4px 0 0 4px !important;
}
.rounded-right {
  border-radius: 0 4px 4px 0 !important;
}
.rounded-bottom {
  border-radius: 0 0 4px 4px !important;
}
/*Others
------------------------------------*/
.overflow-h {
  overflow: hidden;
}
.overflow-a {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
/*Display*/
.dp-none {
  display: none;
}
.dp-block {
  display: block;
}
.dp-table {
  display: table;
}
.dp-inline-block {
  display: inline-block;
}
.dp-table-cell {
  display: table-cell;
  vertical-align: middle;
}
/*Full Width*/
.full-width {
  width: 100%;
}
/*Equal Height Columns*/
@media (max-width: 767px) {
  .equal-height-column {
    height: auto !important;
  }
}
/*Image Classes*/
.img-width-200 {
  width: 200px;
}
.lft-img-margin {
  margin: 0 20px 5px 0;
}
.rgt-img-margin {
  margin: 0 0 5px 10px;
}
img.img-center,
.img-center img {
  margin-left: auto;
  margin-right: auto;
}
/*Background Light*/
.bg-light {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: solid 1px #e5e5e5;
}
.bg-light:hover {
  border: solid 1px #bbb;
}
/*CSS3 Hover Effects*/
.hover-effect {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.hover-effect-kenburn {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.hover-effect-kenburn:hover {
  -webkit-transform: scale(2) rotate(5deg);
  -moz-transform: scale(2) rotate(5deg);
  -o-transform: scale(2) rotate(5deg);
  -ms-transform: scale(2) rotate(5deg);
  transform: scale(2) rotate(5deg);
}
/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Import Global Compulsory CSS Files*/
/* Imported through less */
/*@import url(ie8.css);
@import url(blocks.css);
@import url(plugins.css);
@import url(app.css); */
body {
  color: #333;
  font-size: 13px;
  line-height: 1.6;
}
body.header-fixed-space {
  padding-top: 94px;
}
@media (max-width: 991px) {
  body.header-fixed-space {
    padding-top: 0;
  }
}
p,
li,
li a,
label {
  color: #555;
}
a {
  color: #72c02c;
  text-decoration: none;
}
a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
}
a:focus {
  text-decoration: none;
}
a:hover {
  color: #72c02c;
  text-decoration: underline;
}
/*Boxed Layout
------------------------------------*/
.wrapper {
  background: #fff;
}
.boxed-layout {
  padding: 0;
  box-shadow: 0 0 5px #ccc;
  margin-left: auto !important;
  margin-right: auto !important;
  background: url(../img/patterns/15.png) repeat;
}
/*Main Styles
------------------------------------*/
/*Purchase Block*/
.purchase {
  padding: 25px 0 30px;
  border-bottom: solid 1px #eee;
  background: url(../img/patterns/breadcrumbs.png) repeat;
}
.purchase p {
  margin-bottom: 0;
}
.purchase span {
  color: #666;
  display: block;
  font-size: 24px;
  line-height: 35px;
  font-weight: normal;
  margin-bottom: 12px;
  font-family: "Open Sans", Arial, sans-serif;
}
.purchase .btn-buy {
  text-align: center;
}
.purchase .btn-buy a {
  margin-top: 27px;
}
.purchase .btn-buy a i {
  margin-right: 5px;
}
@media (max-width: 992px) {
  .purchase {
    text-align: center;
  }
  .purchase span {
    font-size: 22px;
    line-height: 29px;
  }
  .purchase .btn-buy a {
    margin-top: 25px;
    font-size: 16px;
  }
}
/*Service*/
.service {
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px 15px 10px;
}
.service:hover {
  background: #fcfcfc;
  box-shadow: 0 0 5px #ddd;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
}
.service:hover i {
  color: #656565;
}
.service .desc {
  padding: 0 15px;
  overflow: hidden;
}
.service .desc h4 {
  font-size: 22px;
  line-height: 25px;
}
.service .service-icon {
  float: left;
  padding: 10px;
  color: #72c02c;
  font-size: 35px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.service .service-icon.icon-cogs {
  font-size: 37px;
}
.service .service-icon.icon-plane {
  font-size: 43px;
}
/* Recent Work  */
.recent-work a {
  text-align: center;
  background: #fcfcfc;
  display: inline-block;
}
.recent-work a:hover {
  text-decoration: none;
}
.recent-work a:hover strong {
  color: #555;
}
.recent-work em.overflow-hidden {
  display: block;
}
.recent-work a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee;
}
.recent-work a strong {
  color: #555;
  display: block;
}
.recent-work a i {
  color: #777;
}
.recent-work a img {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.recent-work a:hover img {
  opacity: 0.8;
  -webkit-transform: scale(1.2) rotate(3deg);
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg);
}
.recent-work a:hover span {
  border-bottom: solid 2px #72c02c;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* Work */
.work {
  margin-top: 6px;
}
.work .details {
  padding: 10px;
}
.work .caption {
  display: block;
  padding-top: 5px;
  color: #585f69 !important;
  font-size: 18px !important;
}
.work .caption:hover {
  cursor: pointer;
  color: #444 !important;
  text-decoration: underline;
}
/*Other Footer Common Classes
------------------------------------*/
/*Thumb Headline*/
.thumb-headline h2 {
  color: #eee;
  font-size: 16px;
}
/*Footer Logo*/
.footer-logo {
  margin: 17px 0 20px;
}
/*Latest List*/
.latest-list li {
  padding: 8px 0;
  border-top: 1px solid #353535;
}
.latest-list li:first-child {
  padding-top: 0;
  border-top: none;
}
.latest-list li a {
  color: #eee;
}
.latest-list small {
  color: #999;
  display: block;
}
/*Link List*/
.link-list li {
  border-top: solid 1px #353535;
}
.link-list li:first-child {
  border-top: none !important;
}
.link-list a {
  color: #eee;
  font-size: 11px;
  padding: 6px 0px;
  display: inline-block;
  text-transform: uppercase;
}
.link-list li i {
  color: #bbb;
  float: right;
  margin-top: 10px;
}
/*Latest Tweets*/
.latest-tweets .latest-tweets-inner p {
  color: #555;
  font-size: 13px;
}
.latest-tweets .latest-tweets-inner a {
  /*color: #3498db;*/
  text-decoration: none;
}
.latest-tweets .latest-tweets-inner a:hover {
  text-decoration: underline;
}
.latest-tweets .latest-tweets-inner i.fa {
  top: 2px;
  float: left;
  color: #bbb;
  color: #55acee;
  font-size: 18px;
  margin-right: 5px;
  position: relative;
}
.latest-tweets .latest-tweets-inner small {
  color: #777;
  display: block;
}
/*Social Icons*/
.social-icons {
  margin-top: 5px;
}
.social-icons li {
  margin: 0;
}
.social-icons li a {
  margin: 0 3px;
}
/*Thumbnails
------------------------------------*/
a.thumbnail {
  padding: 0;
  border: none;
  margin-bottom: 15px;
}
.thumbnail-style {
  padding: 7px;
  margin-bottom: 20px;
}
.thumbnail-style:hover {
  box-shadow: 0 0 8px #ddd;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
}
.thumbnail h3,
.thumbnail-style h3 {
  margin: 6px 0 8px 0;
}
.thumbnail h3 a,
.thumbnail-style h3 a {
  color: #585f69;
  font-size: 18px;
}
.thumbnail h3 a:hover,
.thumbnail-style h3 a:hover {
  color: #72c02c;
  text-decoration: none;
}
.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px;
}
.thumbnail-style a.btn-more {
  right: -10px;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  position: absolute;
  background: #72c02c;
  display: inline-block;
}
.thumbnail-style a.btn-more:hover {
  text-decoration: none;
  box-shadow: 0 0 0 2px #5fb611;
}
.thumbnail-style:hover a.btn-more {
  right: 10px;
}
.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.thumbnail-kenburn:hover img {
  -webkit-transform: scale(1.2) rotate(2deg);
  -moz-transform: scale(1.2) rotate(2deg);
  -o-transform: scale(1.2) rotate(2deg);
  -ms-transform: scale(1.2) rotate(2deg);
  transform: scale(1.2) rotate(2deg);
}
/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
.service-alternative .service:hover {
  background: #76ca2c;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.service-alternative .service:hover i,
.service-alternative .service:hover p,
.service-alternative .service:hover h4 {
  color: #fff;
}
/*Servive Block Versions*/
.service-v1 h2 {
  margin: 15px 0 10px;
  font-size: 20px;
  line-height: 28px;
}
.service-v1 p {
  text-align: justify;
}
/*Blog Posts
------------------------------------*/
.posts .dl-horizontal a {
  color: #555;
}
.posts .dl-horizontal {
  margin-bottom: 15px;
  overflow: hidden;
}
.posts .dl-horizontal dt {
  width: 60px;
  float: left;
}
.posts .dl-horizontal dt img {
  width: 60px;
  height: 60px;
  padding: 2px;
  margin-top: 2px;
  border: solid 1px #ddd;
}
.posts .dl-horizontal dd {
  margin-left: 70px;
}
.posts .dl-horizontal dd p {
  margin: 0;
}
.posts .dl-horizontal dd a {
  font-size: 14px;
  line-height: 16px !important;
}
.posts .dl-horizontal dd a:hover {
  color: #72c02c;
  text-decoration: none;
}
.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
  color: #72c02c;
  border-color: #72c02c !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*About Page
------------------------------------*/
.team ul.team-socail {
  text-align: right;
}
.team ul.team-socail li {
  margin: 0 !important;
  padding: 0 !important;
}
.team ul.team-socail li a,
.team ul.team-socail li a:hover {
  text-decoration: none;
}
.team ul.team-socail li i {
  color: #aaa;
  padding: 6px 7px;
  min-width: 30px;
  font-size: 18px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}
.team ul.team-socail li i:hover {
  color: #fff;
  background: #72c02c;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.team .thumbnail-style {
  padding: 12px;
}
.team .thumbnail-style img {
  margin-bottom: 7px;
}
.team .thumbnail-style h3 {
  margin-bottom: 5px;
}
.team .thumbnail-style:hover h3 a {
  color: #72c02c !important;
}
.team .thumbnail-style small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}
/*About Me Page
------------------------------------*/
.about-me {
  overflow: hidden;
}
.about-me h2 {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
}
.about-me span {
  color: #999;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}
.about-me .social-icons {
  margin-top: 7px;
}
/*About My Goals*/
.about-my-goals h2 {
  text-transform: uppercase;
}
/*About Skills*/
.about-skills {
  padding: 40px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: url(../img/breadcrumbs.png) repeat;
}
/*Gallery Page
------------------------------------*/
.gallery-page .thumbnail,
.gallery-page .thumbnail:hover {
  padding: 0;
  border: none;
  box-shadow: none;
}
.gallery-page .thumbnails {
  margin-bottom: 6px;
}
/*Privacy Page
------------------------------------*/
.privacy a {
  text-decoration: underline;
}
.privacy a:hover {
  color: #72c02c;
  text-decoration: none;
}
/*Portfolio Item Page v1
------------------------------------*/
.portfolio-item h2 {
  color: #555;
  font-size: 24px;
  margin: 0 0 15px;
}
.portfolio-item li {
  padding: 3px 0;
}
.portfolio-item p a {
  text-decoration: underline;
}
.portfolio-item p a:hover {
  text-decoration: none;
}
/*Portfolio Item v1*/
.portfolio-item1 ul {
  margin: 10px 0 20px;
}
.portfolio-item1 li {
  font-size: 16px;
  margin-bottom: 3px;
}
.portfolio-item1 li i {
  font-size: 18px;
  margin-right: 4px;
}
/*Sidebar Features
------------------------------------*/
/*Blog Tags*/
ul.blog-tags li {
  display: inline-block;
}
ul.blog-tags a {
  font-size: 13px;
  padding: 2px 7px;
  background: #f7f7f7;
  margin: 0 3px 6px 0;
  display: inline-block;
}
ul.blog-tags a i {
  color: #666;
}
ul.blog-tags a:hover {
  background: #72c02c;
}
ul.blog-tags a:hover,
ul.blog-tags a:hover i {
  color: #fff !important;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*Blog Photo Stream*/
.blog-photos li {
  display: inline;
}
.blog-photos li img {
  width: 58px;
  height: 58px;
  margin: 0 2px 8px;
}
.blog-photos li img:hover {
  box-shadow: 0 0 0 2px #72c02c;
}
/*Blog Latest Tweets*/
.blog-twitter .blog-twitter-inner {
  padding: 10px;
  position: relative;
  background: #f7f7f7;
  margin-bottom: 10px;
}
.blog-twitter .blog-twitter-inner,
.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.blog-twitter .blog-twitter-inner:after,
.blog-twitter .blog-twitter-inner:before {
  width: 0;
  height: 0;
  right: 0px;
  bottom: 0px;
  content: " ";
  display: block;
  position: absolute;
}
.blog-twitter .blog-twitter-inner:after {
  border-top: 15px solid #eee;
  border-right: 15px solid transparent;
  border-left: 0px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}
.blog-twitter .blog-twitter-inner:before {
  border-bottom: 15px solid #fff;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-bottom-style: inset;
  /*FF fixes*/
}
.blog-twitter .blog-twitter-inner:hover {
  border-color: #72c02c;
  border-top-color: #72c02c;
}
.blog-twitter .blog-twitter-inner:hover:after {
  border-top-color: #72c02c;
}
.blog-twitter .blog-twitter-inner span.twitter-time {
  color: #777;
  display: block;
  font-size: 11px;
}
.blog-twitter .blog-twitter-inner a {
  color: #72c02c;
  text-decoration: none;
}
.blog-twitter .blog-twitter-inner a:hover {
  text-decoration: underline;
}
.blog-twitter .blog-twitter-inner i.fa {
  top: 2px;
  color: #bbb;
  font-size: 18px;
  position: relative;
}
/*Choose Block*/
.who li {
  margin-bottom: 6px;
}
.who li i {
  color: #72c02c;
  font-size: 13px;
  min-width: 15px;
  margin-right: 8px;
  text-align: center;
  position: relative;
}
.who li:hover i,
.who li:hover a {
  color: #777;
}
.who li:hover a:hover {
  text-decoration: none;
}
/*Promo Page
------------------------------------*/
/*Slider Part*/
.fullwidthbanner-container.slider-promo {
  max-height: 460px !important;
}
.slider-promo-info-bg {
  padding: 25px 20px 20px;
  background: url(../img/bg-black.png) repeat;
}
.slider-promo-info span.big-size {
  color: #fff;
  display: block;
  font-size: 30px;
  margin-bottom: 25px;
  text-shadow: none !important;
}
.slider-promo-info p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}
/*Promo Box*/
.promo-box {
  padding: 20px;
  margin: 25px 0 20px;
  text-align: center;
}
.promo-box i {
  display: block;
  font-size: 50px;
}
.promo-box p {
  font-size: 14px;
  margin-bottom: 0;
}
.promo-box strong,
.promo-box strong a {
  color: #555;
  display: block;
  font-size: 20px;
  font-weight: 200;
  margin: 20px 0 15px;
}
.promo-box:hover strong,
.promo-box:hover strong a {
  color: #72c02c;
}
/*Promo Service*/
.promo-service {
  margin-bottom: 20px;
}
.promo-service li {
  margin-bottom: 5px;
}
.promo-service a.btn-u {
  margin: 0 20px 10px 0;
}
/*Promo Elements*/
.title-box {
  font-size: 16px;
  text-align: center;
  padding: 10px 0 30px;
  border-bottom: solid 1px #eee;
}
.title-box .title-box-text {
  color: #555;
  display: block;
  font-size: 35px;
  font-weight: 200;
}
@media (max-width: 768px) {
  .title-box .title-box-text {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .title-box p {
    font-size: 15px;
  }
}
.block-blockquote {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc;
}
.block-blockquote-inner {
  padding: 15px;
}
.block-blockquote p {
  font-size: 26px;
  margin-bottom: 0;
}
/*Info Blocks*/
.info-blocks {
  margin-bottom: 15px;
}
.info-blocks:hover i.icon-info-blocks {
  color: #72c02c;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.info-blocks i.icon-info-blocks {
  float: left;
  color: #777;
  font-size: 30px;
  min-width: 50px;
  margin-top: 10px;
  text-align: center;
}
.info-blocks .info-blocks-in {
  padding: 0 10px;
  overflow: hidden;
}
.info-blocks .info-blocks-in h3 {
  color: #555;
  font-size: 20px;
  line-height: 28px;
}
.info-blocks .info-blocks-in p {
  font-size: 12px;
}
/*Brand Page
------------------------------------*/
.brand-page .btn {
  margin-bottom: 10px;
}
/*Form Pages
------------------------------------*/
.form-page input,
.form-page select {
  margin-bottom: 10px;
}
/*Labels and Badges Page
------------------------------------*/
ul.labels-demo {
  margin: 5px 0;
}
ul.labels-demo li {
  list-style: none;
  display: inline-block;
  margin: 0 5px 2px 0;
}
.badge-sidebar {
  border-left: solid 1px #eee;
}
@media (max-width: 991px) {
  .badge-sidebar {
    border-left: none;
  }
}
/*Line Icon Page
------------------------------------*/
.line-icon-page .item-box {
  width: 25%;
  float: left;
  height: 90px;
  text-align: center;
  padding: 20px 10px;
  margin: 0 -1px -1px 0;
  word-wrap: break-word;
  border: 1px solid #ddd;
}
@media (max-width: 768px) {
  .line-icon-page .item-box {
    height: 115px;
  }
}
@media (max-width: 450px) {
  .line-icon-page .item-box {
    height: 130px;
  }
}
.line-icon-page .item-box:hover {
  background: #fafafa;
}
.line-icon-page .item span {
  display: block;
  font-size: 28px;
  margin-bottom: 10px;
}
.line-icon-page .item:hover {
  color: #72c02c;
}
/*Line Icon Page
------------------------------------*/
.icon-page-fa {
  margin-bottom: 20px;
}
.icon-page-fa .item-box {
  float: left;
  height: 100px;
  width: 16% !important;
}
.icon-page-fa .item {
  font-size: 12px;
}
.icon-page-fa .item i {
  color: #555;
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .icon-page-fa .item-box {
    width: 20% !important;
  }
}
@media (max-width: 600px) {
  .icon-page-fa .item-box {
    width: 30% !important;
  }
}
@media (max-width: 400px) {
  .icon-page-fa .item-box {
    width: 50% !important;
  }
}
/*GLYPHICONS Icons Page
------------------------------------*/
/*Special styles for displaying the icons and their classes*/
.bs-glyphicons {
  padding-left: 0;
  list-style: none;
  overflow: hidden;
  padding-bottom: 1px;
  margin-bottom: 20px;
}
.bs-glyphicons li {
  width: 25%;
  float: left;
  height: 115px;
  padding: 10px;
  font-size: 11px;
  line-height: 1.4;
  text-align: center;
  margin: 0 -1px -1px 0;
  border: 1px solid #ddd;
}
.bs-glyphicons .glyphicon {
  display: block;
  font-size: 18px;
  margin: 5px auto 10px;
}
.bs-glyphicons li:hover {
  color: #72c02c;
  background-color: #fafafa;
}
@media (min-width: 768px) {
  .bs-glyphicons li {
    width: 12.5%;
  }
}
/*Glyphicons Pro*/
.demo-pro-glyphicons li {
  width: 170px;
  height: 100px;
  padding: 20px 10px 30px;
}
.demo-pro-glyphicons span {
  height: 40px;
  display: block;
}
.demo-pro-glyphicons span:before {
  color: #555;
  padding: 10px 62px;
}
.demo-pro-glyphicons span.glyphicons:before {
  font-size: 22px;
}
/*Glyphicons Pro Halflings*/
.demo-pro-glyphicons span.halflings {
  height: 30px;
}
.demo-pro-glyphicons span.halflings:before {
  padding: 10px 70px;
}
/*Contact Pages
------------------------------------*/
.map {
  width: 100%;
  height: 350px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}
.map-box {
  height: 250px;
}
.map-box-space {
  margin-top: 15px;
}
.map-box-space1 {
  margin-top: 7px;
}
/*Background Opacity
------------------------------------*/
.service-or {
  overflow: hidden;
  position: relative;
}
.service-bg {
  width: 250px;
  padding: 60px;
  position: absolute;
  top: -10px;
  right: -100px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: rotate(55deg);
  -o-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -webkit-transform: rotate(55deg);
}
/*Easy Block (used for image badges)
------------------------------------*/
.easy-block-v1 {
  position: relative;
}
.easy-block-v1 img {
  width: 100%;
}
.easy-block-v1 .overflow-h h3 {
  width: 60%;
  float: left;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
}
.easy-block-v1 .easy-block-v1-badge {
  left: 0px;
  top: 10px;
  z-index: 1;
  color: #fff;
  padding: 4px 10px;
  position: absolute;
}
/*Easy Block v2 (used for image badges)
------------------------------------*/
.easy-block-v2 {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}
.easy-block-v2 img {
  width: 100%;
}
.easy-block-v2 .easy-bg-v2 {
  top: 5px;
  color: #fff;
  width: 100px;
  padding: 5px;
  right: -32px;
  font-size: 13px;
  position: absolute;
  text-align: center;
  letter-spacing: 2px;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/*Easy Block v3 (used for image badges)
------------------------------------*/
.easy-block-v3 {
  text-align: left;
  background: #585f69;
  padding: 30px 20px 20px;
}
.easy-block-v3.first {
  background: #696f77;
}
.easy-block-v3.second {
  background: #707780;
}
.easy-block-v3.third {
  background: #787f88;
}
.easy-block-v3 i {
  color: #fff;
  float: left;
  font-size: 30px;
  min-width: 45px;
  padding-top: 10px;
}
.easy-block-v3 .inner-faq-b {
  overflow: hidden;
}
.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3 {
  font-size: 21px;
  margin-bottom: 8px;
}
.easy-block-v3 .inner-faq-b h4 {
  color: #fff;
  font-size: 17px;
  margin-bottom: 2px;
}
.easy-block-v3 .inner-faq-b h2,
.easy-block-v3 .inner-faq-b h3,
.easy-block-v3 .inner-faq-b p {
  color: #fff;
}
/*Full Width Block
------------------------------------*/
.full-w-block {
  padding: 30px 0;
  background: #282828;
}
.full-w-block .info-blocks i,
.full-w-block .info-blocks-in h3 {
  color: #fff;
}
.full-w-block .info-blocks-in p {
  color: #ccc;
}
.full-w-block .info-blocks:hover i.icon-info-blocks {
  color: #fff;
}
/*Simple Block
------------------------------------*/
.simple-block {
  margin-bottom: 20px;
}
.simple-block img {
  width: 100%;
}
.simple-block p {
  text-align: center;
}
.simple-block .carousel-indicators {
  top: 10px;
  left: 65%;
  text-align: right;
}
.simple-block .carousel-indicators .active {
  background: #555;
}
.simple-block .carousel-indicators li {
  border-color: #555;
}
.simple-block .carousel-inner > .item {
  margin: 0;
}
.simple-block .responsive-video {
  margin-bottom: 3px;
}
/*Two Blocks
------------------------------------*/
.two-blocks {
  text-align: center;
}
.two-blocks-in {
  margin-bottom: 30px;
}
.two-blocks-in i {
  color: #fff;
  padding: 11px;
  font-size: 30px;
  min-width: 50px;
  text-align: center;
  background: #585f69;
  margin-bottom: 10px;
  display: inline-block;
}
/*Three Blocks
------------------------------------*/
.three-blocks {
  text-align: center;
}
.three-blocks-in {
  margin-bottom: 30px;
}
.three-blocks-in i {
  color: #777;
  padding: 9px 6px;
  font-size: 20px;
  min-width: 40px;
  margin-bottom: 10px;
  border: 1px solid #777;
}
.three-blocks-in h3 {
  font-size: 20px;
  margin-bottom: 7px;
}
/*Temperory Classes
------------------------------------*/
/*Banner-info*/
.banner-info {
  margin-bottom: 10px;
}
.banner-info i {
  float: left;
  color: #fff;
  padding: 11px;
  /*min-width works with line-icon*/
  min-width: 40px;
  font-size: 22px;
  text-align: center;
  margin: 7px 20px 0 0;
}
/*min-width works with FontAwesome*/
.banner-info i.fa {
  min-width: 46px;
}
.banner-info.light i {
  border: 1px solid #fff;
}
.banner-info.dark i {
  background: #555;
}
.banner-info h3 {
  font-size: 21px;
  margin-bottom: 5px;
}
.banner-info.light h3 {
  color: #fff;
}
.banner-info.light p {
  color: #eee;
}
/*Title-Box-V2*/
.title-box-v2 {
  text-align: center;
  margin-bottom: 40px;
}
.title-box-v2 h2 {
  font-size: 25px;
  text-transform: uppercase;
}
.title-box-v2 p {
  font-size: 13px;
}
/*remove padding from "Col-"*/
.no-space {
  padding: 0;
}
.no-space-left {
  padding-left: 0;
  padding-right: 15px !important;
}
.no-space-right {
  padding-right: 0;
  padding-left: 15px !important;
}
/*List Style v1*/
.list-style-v1 li {
  margin-bottom: 7px;
}
.list-style-v1 i {
  margin-right: 5px;
}
/*Social Contacts
------------------------------------*/
.social-contacts li {
  padding: 5px 0;
  border-top: 1px solid #ddd;
}
.social-contacts li:first-child {
  border-top: none;
}
.social-contacts li i {
  color: #777;
  font-size: 13px;
  min-width: 13px;
  margin-right: 12px;
  text-align: center;
}
/*Status Bar
------------------------------------*/
.status-bar li {
  padding-left: 9px;
  border-left: 1px solid #eee;
}
.status-bar li:first-child {
  border-left: none;
}
.status-bar li i {
  color: #72c02c;
  margin-right: 5px;
}
.status-bar li a:hover {
  text-decoration: none;
}
.status-bar-in {
  padding: 10px;
  position: relative;
  background: #f0f0f0;
}
.status-bar-in:after {
  width: 0;
  height: 0;
  top: -10px;
  left: 50px;
  content: " ";
  display: block;
  position: absolute;
  border-bottom: 11px solid #f0f0f0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-left-style: inset;
  /*FF fixes*/
  border-right-style: inset;
  /*FF fixes*/
}
/*Share List*/
.share-list {
  margin-bottom: 0;
}
.share-list li {
  padding-left: 20px;
}
.share-list li:first-child {
  padding-left: 0;
}
.share-list li i {
  color: #72c02c;
  margin-right: 5px;
}
/*People Say
------------------------------------*/
.people-say img {
  float: left;
  width: 40px;
  height: auto;
  margin: 6px 10px 0 0;
}
.people-say .overflow-h span {
  font-weight: 700;
}
.people-say .overflow-h p {
  font-style: italic;
  line-height: 1.3;
}
.people-say .overflow-h small {
  text-align: right;
  font-style: italic;
}
/*Inspire Page
------------------------------------*/
img.img-mouse {
  width: 150px;
  height: auto;
  margin: 0 auto 60px;
}
/*Desire Page
------------------------------------*/
/*Service Info*/
.service-info {
  position: relative;
  padding: 40px 0 50px;
  background: url(../img/patterns/17.png) repeat;
}
.service-info .info-description {
  overflow: hidden;
}
.service-info i.service-info-icon {
  color: #777;
  float: left;
  width: 50px;
  height: 50px;
  padding: 15px;
  font-size: 20px;
  line-height: 17px;
  margin-right: 20px;
  text-align: center;
  display: inline-block;
  border: 1px solid #777;
}
.service-info .info-description h3 {
  margin: 0 0 10px;
}
.service-info .info-description p {
  color: #777;
}
/*Portfolio Single Item Page
------------------------------------*/
/*Project Details*/
.project-details li {
  color: #555;
  font-weight: 200;
  margin-bottom: 5px;
}
.project-details strong {
  min-width: 100px;
  display: inline-block;
}
.project-details a {
  text-decoration: underline;
}
/*Bootstrap Testimonials
------------------------------------*/
.testimonials-bs {
  padding: 50px 0;
  background: url(../img/bg/16.jpg) 50% 0 repeat fixed;
}
.testimonials-bs .headline-center-v2 span.author {
  color: #777;
  font-size: 14px;
}
/*Removes Bootstrap carousel background images*/
.testimonials-bs .item {
  margin-bottom: 30px;
}
.testimonials-bs .carousel-arrow {
  text-align: center;
}
.testimonials-bs .carousel-control-v2 i {
  color: #888;
  width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 22px;
  display: inline-block;
  border: 2px solid #888;
}
.testimonials-bs .carousel-control-v2 i:hover {
  color: #72c02c;
  border-color: #72c02c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.testimonials-bs .carousel-control-v2.left,
.testimonials-bs .carousel-control-v2.right {
  text-decoration: none;
  background-image: none;
}
/*Team v1*/
.team-v1 li > h3 {
  color: #555;
}
/* 
* Version: 1.7
* Dark Blue Color: @dark-blue;  
* Dark Blue Hover Color: @darker;  
* Additional color: #cad9f6;
* rgba(71, 101, 160, 1);
*/
a {
  color: #002446;
}
a:focus,
a:hover,
a:active {
  color: #002446;
}
.color-green {
  color: #002446;
}
a.read-more:hover {
  color: #002446;
}
.linked:hover {
  color: #002446;
}
/* Headers Default
------------------------------------*/
.header .navbar-nav > .active > a {
  color: #002446;
}
.header .navbar-nav > .active > a {
  border-color: #002446;
}
.header .dropdown-menu {
  border-top: solid 2px #002446;
}
.header .navbar-nav > li:hover > a {
  color: #324c80;
}
.header .nav > li > .search:hover {
  border-bottom-color: #324c80;
  color: #324c80;
}
.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom-color: #fac700;
}
.header .navbar-toggle {
  border-color: #324c80;
}
.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
  background: #002446;
}
.header .navbar-toggle:hover {
  background: #324c80 !important;
}
.header .navbar-nav > .open > a,
.header .navbar-nav > .open > a:hover,
.header .navbar-nav > .open > a:focus {
  color: #002446;
}
/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header .navbar-nav > .active > a,
  .header .navbar-nav > .active > a:hover,
  .header .navbar-nav > .active > a:focus {
    background: #002446;
    color: #fff !important;
  }
  .header .navbar-nav > .active > a {
    border-color: #eee;
  }
  .header .nav > li > .search:hover {
    background: #002446;
  }
}
/* Headers v1
------------------------------------*/
.header-v1 .navbar-default .navbar-nav > .active > a,
.header-v1 .navbar-default .navbar-nav > li > a:hover,
.header-v1 .navbar-default .navbar-nav > li > a:focus {
  color: #002446;
}
.header-v1 .dropdown-menu {
  border-color: #002446;
}
.header-v1 .navbar-default .navbar-nav > li:hover > a {
  color: #002446;
}
.header-v1 .navbar .nav > li > .search:hover {
  color: #002446;
}
.header-v1 .navbar .nav > li > .search:hover {
  color: #002446;
}
.header-v1 .navbar-default .navbar-toggle {
  border-color: #324c80;
}
.header-v1 .navbar-toggle,
.header-v1 .navbar-default .navbar-toggle:hover,
.header-v1 .navbar-default .navbar-toggle:focus {
  background: #002446;
}
.header-v1 .navbar-toggle:hover {
  background: #324c80 !important;
}
/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v1 .navbar-default .navbar-nav > li:hover > a {
    border-color: #eee;
  }
  .header-v1 .navbar-default .navbar-nav > .active > a,
  .header-v1 .navbar-default .navbar-nav > .active > a:hover,
  .header-v1 .navbar-default .navbar-nav > .active > a:focus {
    background-color: #002446;
  }
  .header-v1 .navbar-default .navbar-nav > .active > a {
    border-color: #eee;
  }
  .header-v1 .navbar .nav > li > .search:hover {
    background-color: #002446;
  }
}
/* Headers v2
------------------------------------*/
.header-v2 .dropdown-menu {
  border-color: #002446;
}
.header-v2 .navbar-default .navbar-toggle {
  border-color: #324c80;
}
.header-v2 .navbar-toggle,
.header-v2 .navbar-default .navbar-toggle:hover,
.header-v2 .navbar-default .navbar-toggle:focus {
  background: #002446;
}
.header-v2 .navbar-toggle:hover {
  background: #324c80 !important;
}
/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v2 .navbar-default .navbar-nav > .active > a,
  .header-v2 .navbar-default .navbar-nav > .active > a:hover,
  .header-v2 .navbar-default .navbar-nav > .active > a:focus {
    background: #002446;
    color: #fff !important;
  }
  .header-v2 .navbar-default .navbar-nav > li > a:hover {
    color: #002446;
  }
}
/* Headers v3
------------------------------------*/
.header-v3 .navbar-default .navbar-nav > .active > a {
  color: #002446;
}
.header-v3 .navbar-default .navbar-nav > li:hover > a {
  color: #324c80;
}
.header-v3 .dropdown-menu {
  border-color: #002446;
}
.header-v3 .navbar-default .navbar-toggle {
  border-color: #324c80;
}
.header-v3 .navbar-toggle,
.header-v3 .navbar-default .navbar-toggle:hover,
.header-v3 .navbar-default .navbar-toggle:focus {
  background: #002446;
}
.header-v3 .navbar-toggle:hover {
  background: #324c80 !important;
}
.header-v3 .navbar .nav > li > .search:hover {
  background: inherit;
  color: #002446;
}
/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v3 .navbar-default .navbar-nav > .active > a,
  .header-v3 .navbar-default .navbar-nav > .active > a:hover,
  .header-v3 .navbar-default .navbar-nav > .active > a:focus {
    background: #002446;
    color: #fff !important;
  }
  .header-v3 .navbar-default .navbar-nav > .active > a {
    border-color: #eee;
  }
  .header-v3 .navbar .nav > li > .search:hover {
    background: #002446;
  }
}
/* Headers v4
------------------------------------*/
.header-v4 .navbar-default .navbar-nav > li > a:hover,
.header-v4 .navbar-default .navbar-nav > .active > a {
  border-color: #002446;
}
.header-v4 .navbar-default .navbar-nav > .active > a {
  color: #002446;
}
.header-v4 .navbar-default .navbar-nav > li:hover > a {
  border-color: #002446;
  color: #324c80;
}
.header-v4 .navbar .nav > li > .search:hover {
  color: #002446;
}
.header-v4 .navbar-default .navbar-nav > .open > a,
.header-v4 .navbar-default .navbar-nav > .open > a:hover,
.header-v4 .navbar-default .navbar-nav > .open > a:focus {
  color: #002446;
}
/* Design for max-width: 991px */
@media (max-width: 991px) {
  .header-v4 .navbar-default .navbar-nav > li:hover > a {
    border-color: #eee;
  }
  .header-v4 .navbar-default .navbar-nav > .active > a,
  .header-v4 .navbar-default .navbar-nav > .active > a:hover,
  .header-v4 .navbar-default .navbar-nav > .active > a:focus {
    color: #002446 !important;
  }
  .header-v4 .navbar-default .navbar-nav > .active > a {
    border-color: #eee;
  }
  .header-v4 .navbar .nav > li > .search:hover {
    background: #002446;
  }
}
/* Headers v5
------------------------------------*/
.header-v5 .navbar-default .navbar-nav > li > a:hover,
.header-v5 .navbar-default .navbar-nav > .active > a {
  border-top: 2px solid #002446;
}
.header-v5 .navbar-default .navbar-nav > .active > a {
  color: #002446;
}
.header-v5 .navbar-default .navbar-nav > li:hover > a {
  color: #002446;
}
.header-v5 .navbar-default .navbar-nav > .open > a,
.header-v5 .navbar-default .navbar-nav > .open > a:hover,
.header-v5 .navbar-default .navbar-nav > .open > a:focus {
  color: #002446;
}
.header-v5 .dropdown-menu li > a:hover {
  background: #002446;
}
.header-v5 .dropdown-menu .active > a,
.header-v5 .dropdown-menu li > a:hover {
  background: #002446;
}
.header-v5 .dropdown-menu {
  border-color: #002446;
}
.header-v5 .dropdown-menu li.dropdown-submenu:hover > a {
  background: #002446;
}
.header-v5 .dropdown-menu .style-list li > a:hover {
  background: none;
}
.header-v5 .style-list li a:hover {
  color: #002446;
}
/* Shopping cart
------------------------------------*/
.header-v5 .shop-badge.badge-icons i {
  color: #002446;
}
.header-v5 .shop-badge span.badge-sea {
  background: #002446;
}
.header-v5 .badge-open {
  border-top: 2px solid #002446;
  box-shadow: 0 5px 5px 0 rgba(71, 101, 160, 0.075);
}
/* Header v6
------------------------------------*/
/* Search */
.header-v6 .shopping-cart .shopping-cart-open {
  border-top-color: #002446 !important;
}
.header-v6 li.menu-icons span.badge {
  background: #002446;
}
/* Dropdown Menu */
.header-v6 .dropdown-menu {
  border-top-color: #002446;
}
/* Media Queries */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-v6 .navbar-nav > .active > a,
  .header-v6 .navbar-nav > .active > a:hover,
  .header-v6 .navbar-nav > .active > a:focus {
    color: #002446 !important;
  }
  .header-v6 .nav .open > a,
  .header-v6 .nav .open > a:hover,
  .header-v6 .nav .open > a:focus {
    border-color: #eee;
  }
  .header-v6 .navbar-nav > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > li > a:focus,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:hover,
  .header-v6 .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #002446 !important;
  }
  .header-v6 .mega-menu .equal-height-list li a:hover {
    color: #002446 !important;
  }
  /* Classic Dark */
  .header-v6 .mega-menu .equal-height-list li a:hover {
    color: #002446;
  }
  /* Dark Responsive Navbar */
  .header-v6.header-dark-res-nav .navbar-nav > li a:hover,
  .header-v6.header-dark-res-nav .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #002446;
  }
  .header-v6.header-dark-res-nav .nav .open > a,
  .header-v6.header-dark-res-nav .nav .open > a:hover,
  .header-v6.header-dark-res-nav .nav .open > a:focus {
    border-color: #555;
  }
}
@media (min-width: 992px) {
  /* Default Style */
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
    color: #002446 !important;
  }
  .header-v6 .dropdown-menu .active > a,
  .header-v6 .dropdown-menu li > a:hover,
  .header-fixed .header-v6.header-fixed-shrink .dropdown-menu .active > a,
  .header-fixed .header-v6.header-fixed-shrink .dropdown-menu li > a:hover {
    color: #002446 !important;
  }
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-fixed-shrink .navbar-nav li > a:hover {
    color: #002446;
  }
  /* Classic White */
  .header-fixed .header-v6.header-classic-white .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-white .navbar-nav li > a:hover {
    color: #002446;
  }
  /* Classic Dark */
  .header-v6.header-classic-dark .navbar-nav .active > a,
  .header-v6.header-classic-dark .navbar-nav li > a:hover,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-classic-dark.header-fixed-shrink .navbar-nav li > a:hover {
    color: #002446;
  }
  .header-v6.header-classic-dark .dropdown-menu .active > a,
  .header-v6.header-classic-dark .dropdown-menu li > a:hover {
    color: #002446 !important;
  }
  /* Dark Dropdown */
  .header-v6.header-dark-dropdown .dropdown-menu .active > a,
  .header-v6.header-dark-dropdown .dropdown-menu li > a:hover {
    color: #002446;
  }
  /* Dark Scroll */
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav .active > a,
  .header-fixed .header-v6.header-dark-scroll.header-fixed-shrink .navbar-nav li > a:hover {
    color: #002446;
  }
}
/* Header v7
------------------------------------*/
.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li.active > a {
  color: #002446 !important;
}
.header-v7 .dropdown-menu .active > a,
.header-v7 .dropdown-menu li > a:focus,
.header-v7 .dropdown-menu li > a:hover {
  color: #002446 !important;
}
.header-v7 .navbar-default .navbar-nav > li > a:hover,
.header-v7 .navbar-default .navbar-nav > li > a:focus,
.header-v7 .navbar-default .navbar-nav > .active > a,
.header-v7 .navbar-default .navbar-nav > .active > a:hover,
.header-v7 .navbar-default .navbar-nav > .active > a:focus {
  color: #002446;
}
.header-socials li a:hover {
  color: #002446;
}
/* Sliders
------------------------------------*/
/* Main Parallax Sldier */
.da-slide h2 i {
  background-color: rgba(71, 101, 160, 0.8);
}
/* Sequence Parallax Sldier */
.sequence-inner {
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#cad9f6));
  background: -webkit-linear-gradient(#fff, #cad9f6);
  background: -moz-linear-gradient(#fff, #cad9f6);
  background: -ms-linear-gradient(#fff, #cad9f6);
  background: -o-linear-gradient(#fff, #cad9f6);
  background: linear-gradient(#fff, #cad9f6);
}
#sequence-theme h2 {
  background: rgba(71, 101, 160, 0.8);
}
#sequence-theme .info p {
  background: rgba(71, 101, 160, 0.8);
}
/* Buttons
------------------------------------*/
.btn-u {
  background: #002446;
}
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
  background: #324c80;
  color: #fff;
}
/* Buttons Color */
.btn-u-split.dropdown-toggle {
  border-left: solid 1px #324c80;
}
/* Bordered Buttons */
.btn-u.btn-brd {
  border-color: #002446;
}
.btn-u.btn-brd:hover {
  color: #324c80;
  border-color: #324c80;
}
.btn-u.btn-brd.btn-brd-hover:hover {
  background: #324c80;
}
/* Service
------------------------------------*/
.service .service-icon {
  color: #002446;
}
/* Service Blocks */
.service-alternative .service:hover {
  background: #002446;
}
/* Thumbnail (Recent Work)
------------------------------------*/
.thumbnail-style h3 a:hover {
  color: #002446;
}
.thumbnail-style a.btn-more {
  background: #002446;
}
.thumbnail-style a.btn-more:hover {
  box-shadow: 0 0 0 2px #324c80;
}
/* Typography
------------------------------------*/
/* Heading */
.headline h2,
.headline h3,
.headline h4 {
  border-bottom: 2px solid #002446;
}
/* Blockquote */
blockquote:hover {
  border-left-color: #002446;
}
.hero {
  border-left-color: #002446;
}
blockquote.hero.hero-default {
  background: #002446;
}
blockquote.hero.hero-default:hover {
  background: #324c80;
}
/* Carousel
------------------------------------*/
.carousel-arrow a.carousel-control:hover {
  color: #002446;
}
/* Footer
------------------------------------*/
.footer a,
.copyright a,
.footer a:hover,
.copyright a:hover {
  color: #002446;
}
/* Footer Blog */
.footer .dl-horizontal a:hover {
  color: #002446 !important;
}
/* Blog Posts
------------------------------------*/
.posts .dl-horizontal a:hover {
  color: #002446;
}
.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
  color: #002446;
  border-color: #002446 !important;
}
/* Post Comment */
.post-comment h3,
.blog-item .media h3,
.blog-item .media h4.media-heading span a {
  color: #002446;
}
/* Tabs
------------------------------------*/
/* Tabs v1 */
.tab-v1 .nav-tabs {
  border-bottom: solid 2px #002446;
}
.tab-v1 .nav-tabs > .active > a,
.tab-v1 .nav-tabs > .active > a:hover,
.tab-v1 .nav-tabs > .active > a:focus {
  background: #002446;
}
.tab-v1 .nav-tabs > li > a:hover {
  background: #002446;
}
/* Tabs v2 */
.tab-v2 .nav-tabs li.active a {
  border-top: solid 2px #002446;
}
/* Tabs v3 */
.tab-v3 .nav-pills li a:hover,
.tab-v3 .nav-pills li.active a {
  background: #002446;
  border: solid 1px #324c80;
}
/* Accardion
------------------------------------*/
.acc-home a.active,
.acc-home a.accordion-toggle:hover {
  color: #002446;
}
.acc-home .collapse.in {
  border-bottom: solid 1px #002446;
}
/* Testimonials
------------------------------------*/
.testimonials .testimonial-info {
  color: #002446;
}
.testimonials .carousel-arrow i:hover {
  background: #002446;
}
/* Info Blocks
------------------------------------*/
.info-blocks:hover i.icon-info-blocks {
  color: #002446;
}
/* Breadcrumb
------------------------------------*/
.breadcrumb li.active,
.breadcrumb li a:hover {
  color: #002446;
}
/* About Page
------------------------------------*/
.team .thumbnail-style:hover h3 a {
  color: #002446 !important;
}
/* Social Icons */
.team ul.team-socail li i:hover {
  background: #002446;
}
/* Right Sidebar
------------------------------------*/
/* Right Sidebar */
.who li i,
.who li:hover i,
.who li:hover a {
  color: #002446;
}
/* Privacy Page
------------------------------------*/
.privacy a:hover {
  color: #002446;
}
/* Portfolio Page
------------------------------------*/
/* Portfolio v1 */
.view a.info:hover {
  background: #002446;
}
/* Portfolio v2 */
.sorting-block .sorting-nav li.active {
  color: #002446;
  border-bottom: solid 1px #002446;
}
.sorting-block .sorting-grid li a:hover span.sorting-cover {
  background: #002446;
}
/* Blog Page
------------------------------------*/
.blog h3 {
  color: #002446;
}
.blog li a:hover {
  color: #002446;
}
/* Blog Tags */
ul.blog-tags a:hover {
  background: #002446;
}
.blog-post-tags ul.blog-tags a:hover {
  background: #002446;
}
/* Blog Photos */
.blog-photos li img:hover {
  box-shadow: 0 0 0 2px #002446;
}
/* Blog Latest Tweets */
.blog-twitter .blog-twitter-inner:hover {
  border-color: #002446;
  border-top-color: #002446;
}
.blog-twitter .blog-twitter-inner:hover:after {
  border-top-color: #002446;
}
.blog-twitter .blog-twitter-inner a {
  color: #002446;
}
/* Blog Item Page
------------------------------------*/
.blog-item h4.media-heading span a {
  color: #002446;
}
/* Coming Soon Page
------------------------------------*/
.coming-soon-border {
  border-top: solid 3px #002446;
}
/* Search Page
------------------------------------*/
.booking-blocks p a {
  color: #002446;
}
/* Icons Page
------------------------------------*/
.icon-page li:hover {
  color: #002446;
}
/* Glyphicons */
.glyphicons-demo a:hover {
  color: #002446;
  text-decoration: none;
}
/* Social Icons
------------------------------------*/
.social-icons-v1 i:hover {
  color: #fff;
  background: #002446;
}
/* Magazine Page
------------------------------------*/
/* Magazine News */
.magazine-news .by-author strong {
  color: #002446;
}
.magazine-news a.read-more {
  color: #002446;
}
/* Magazine Mini News */
.magazine-mini-news .post-author strong {
  color: #002446;
}
.news-read-more i {
  background: #002446;
}
/* Sidebar Features */
.magazine-page h3 a:hover {
  color: #002446;
}
/* Page Features
------------------------------------*/
/* Tag Boxes v1 */
.tag-box-v1 {
  border-top: solid 2px #002446;
}
/* Tag Boxes v2 */
.tag-box-v2 {
  border-left: solid 2px #002446;
}
/* Tag Boxes v7 */
.tag-box-v7 {
  border-bottom: solid 2px #002446;
}
/* Font Awesome Icon Page Style */
.fa-icons li:hover {
  color: #002446;
}
.fa-icons li:hover i {
  background: #002446;
}
/* GLYPHICONS Icons Page Style */
.bs-glyphicons li:hover {
  color: #002446;
}
/* Navigation
------------------------------------*/
/* Pagination */
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #002446;
  border-color: #002446;
}
.pagination li a:hover {
  background: #324c80;
  border-color: #324c80;
}
/* Pager */
.pager li > a:hover,
.pager li > a:focus {
  background: #324c80;
  border-color: #324c80;
}
.pager.pager-v2 li > a:hover,
.pager.pager-v2 li > a:focus,
.pager.pager-v3 li > a:hover,
.pager.pager-v3 li > a:focus {
  color: #fff;
  background: #002446;
}
/* Registration and Login Page v2
------------------------------------*/
.reg-block {
  border-top: solid 2px #002446;
}
/*Image Hover
------------------------------------*/
/* Image-hover */
#effect-2 figure .img-hover {
  background: #002446;
}
/* Blog Large Page
------------------------------------*/
.blog h2 a:hover {
  color: #002446;
}
/* Timeline v1 Page
------------------------------------*/
.timeline-v1 > li > .timeline-badge i:hover {
  color: #002446;
}
.timeline-v1 .timeline-footer .likes:hover i {
  color: #002446;
}
/* Timeline v2 Page
------------------------------------*/
/* The icons */
.timeline-v2 > li .cbp_tmicon {
  background: #002446;
}
/* Progress Bar
------------------------------------*/
.progress-bar-u {
  background: #002446;
}
/* Job Inner Page
------------------------------------*/
.job-description .save-job a:hover,
.block-description .save-job a:hover {
  color: #002446;
}
.job-description .p-chart .overflow-h li i,
.job-description .p-chart .overflow-h li a,
.block-description .p-chart .overflow-h li i,
.block-description .p-chart .overflow-h li a {
  color: #002446;
}
/* Colorful-ul */
.job-description .colorful-ul li a {
  color: #002446;
}
/* Search Inner Page
------------------------------------*/
.s-results .related-search a:hover {
  color: #002446;
}
.s-results .inner-results h3 a:hover {
  color: #002446;
}
.s-results .up-ul li a:hover {
  color: #002446;
}
.s-results .down-ul li a {
  color: #002446;
}
/* Funny Boxes
------------------------------------*/
.funny-boxes p a {
  color: #002446;
}
.funny-boxes .funny-boxes-img li i {
  color: #002446;
}
.funny-boxes-colored p,
.funny-boxes-colored h2 a,
.funny-boxes-colored .funny-boxes-img li,
.funny-boxes-colored .funny-boxes-img li i {
  color: #fff;
}
/* Sidebar Sub Navigation
------------------------------------*/
.sidebar-nav-v1 ul li:hover a,
.sidebar-nav-v1 ul li.active a {
  color: #002446;
}
/* Blockquote
------------------------------------*/
blockquote.bq-green {
  border-color: #002446;
}
blockquote:hover,
blockquote.text-right:hover {
  border-color: #002446;
}
.quote-v1 p::before {
  color: #002446;
}
/* Green Left Bordered Funny Box */
.funny-boxes-left-green {
  border-left: solid 2px #002446;
}
.funny-boxes-left-green:hover {
  border-left-color: #002446;
}
/* Testimonials Default
------------------------------------*/
/* Testimonials */
.testimonials .carousel-arrow i:hover {
  background: #002446;
}
/* Testimonials Default */
.testimonials-bg-default .item p {
  background: #002446;
}
.testimonials.testimonials-bg-default .item p:after,
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #002446;
}
.testimonials-bg-default .carousel-arrow i {
  background: #002446;
}
.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #324c80;
}
/* Promo Page
------------------------------------*/
/* Promo Box */
.promo-box:hover strong,
.promo-box:hover strong a {
  color: #002446;
}
/* Typography
------------------------------------*/
.dropcap {
  color: #002446;
}
.dropcap-bg {
  color: #fff;
  background: #002446;
}
/* Breadcrumbs
------------------------------------*/
span.label-u,
span.badge-u {
  background: #002446;
}
/* Icons
------------------------------------*/
/* Icon Link*/
.link-icon:hover i {
  color: #002446;
  border: solid 1px #002446;
}
.link-bg-icon:hover i {
  color: #002446;
  background: #002446 !important;
  border-color: #002446;
}
/* Icons Backgroun Color
------------------------------------*/
i.icon-color-u {
  color: #002446;
  border: solid 1px #002446;
}
i.icon-bg-u {
  background: #002446;
}
/* Line Icon Page
------------------------------------*/
.line-icon-page .item:hover {
  color: #002446;
}
/* Colored Content Boxes
------------------------------------*/
.service-block-u {
  background: #002446;
}
/* Panels (Portlets)
------------------------------------*/
.panel-u {
  border-color: #002446;
}
.panel-u > .panel-heading {
  background: #002446;
}
/* Owl Carousel
------------------------------------*/
.owl-btn:hover {
  background: #002446;
}
/* Counter
------------------------------------*/
.counters span.counter-icon i {
  background: #002446;
}
.counters span.counter-icon i:after {
  border-top: 7px solid #002446;
}
/* SKy-Forms
------------------------------------*/
/* Buttons */
.sky-form .button {
  background: #002446;
}
/* Rating */
.sky-form .rating input:checked ~ label {
  color: #002446;
}
/* Message */
.sky-form .message {
  color: #002446;
}
.sky-form .message i {
  border-color: #002446;
}
/* Profile
------------------------------------*/
.profile .profile-post:hover span.profile-post-numb {
  color: #002446;
}
.profile .date-formats {
  background: #002446;
}
.profile .name-location span i,
.profile .name-location span a:hover {
  color: #002446;
}
.share-list li i {
  color: #002446;
}
.profile .comment-list-v2 li:hover i,
.profile .comment-list li:hover i {
  color: #002446;
}
.profile .profile-post.color-one {
  border-color: #002446;
}
/* Pricing Page
------------------------------------*/
/* Pricing Head */
.pricing:hover h4 {
  color: #002446;
}
.pricing-head h3 {
  background: #002446;
  text-shadow: 0 1px 0 #324c80;
}
.pricing-head h4 {
  color: #999;
  background: #fcfcfc;
  border-bottom: solid 1px #cad9f6;
}
/* Pricing Content */
.pricing-content li {
  border-bottom: solid 1px #cad9f6;
}
.pricing-content li i {
  color: #002446;
}
/* Pricing Extra */
.sticker-left {
  background: #002446;
}
/* Pricing Footer */
.pricing-footer a:hover,
.pricing-footer button:hover {
  background: #324c80;
}
/* Pricing Active */
.price-active h4 {
  color: #002446;
}
.no-space-pricing .price-active .pricing-head h4,
.no-space-pricing .pricing:hover .pricing-head h4 {
  color: #002446;
}
/* Mega Pricing Tables 
------------------------------------*/
.pricing-mega-v1 .pricing-head h3,
.pricing-mega-v2 .pricing-head h3,
.pricing-mega-v3 .pricing-head h3 {
  text-shadow: 0 1px 0 #324c80;
}
/* Pricing Table Mega v1 Version
------------------------------------*/
.pricing-mega-v1 .pricing:hover h4 i {
  color: #002446;
}
.pricing-mega-v1 .pricing-content li i {
  color: #002446;
}
/* Pricing Table Colored Background Version
------------------------------------*/
.pricing-bg-colored .pricing:hover {
  background: #002446;
}
.pricing-bg-colored .pricing-head i {
  color: #002446;
}
.pricing-bg-colored .pricing-footer .btn-u {
  border: 1px solid #fff;
}
.pricing-bg-colored .pricing-head p {
  border-bottom: 1px solid #cad9f6;
}
/* Pricing Table Mega v2 
------------------------------------*/
.pricing-mega-v2 .block:hover .bg-color {
  background: #002446;
}
.pricing-mega-v2 .block:hover h3,
.pricing-mega-v2 .block:hover h4,
.pricing-mega-v2 .block:hover li,
.pricing-mega-v2 .block:hover li i,
.pricing-mega-v2 .block:hover h4 i {
  background: #002446;
}
/* Pricing Table Mega v3 
------------------------------------*/
.pricing-mega-v1 .btn-group .dropdown-menu,
.pricing-mega-v3 .btn-group .dropdown-menu {
  background: #002446 !important;
}
.pricing-mega-v1 .btn-group .dropdown-menu li a:hover,
.pricing-mega-v3 .btn-group .dropdown-menu li a:hover {
  background: #324c80;
}
/* Grid Block v2 
------------------------------------*/
.grid-block-v2 li:hover .grid-block-v2-info {
  border-color: #002446;
}
/* Testimonials v3 Title 
------------------------------------*/
.testimonials-v3 .testimonials-v3-title p {
  color: #002446;
}
.testimonials-v3 .owl-buttons .owl-prev:hover,
.testimonials-v3 .owl-buttons .owl-next:hover {
  background: #002446;
}
/* Content Boxes v4 
------------------------------------*/
.content-boxes-v4 i {
  color: #002446;
}
/* Thumbnails v1 
------------------------------------*/
.thumbnails-v1 .read-more {
  color: #002446;
}
/* Thumbnails v6 
------------------------------------*/
.testimonials-v6 .testimonials-info:hover {
  border-color: #002446;
}
/* Team v1 
------------------------------------*/
.team-v1 li:hover > p:before {
  background: #002446;
}
/* Team v4
------------------------------------*/
.team-v4 .team-social-v4 a:hover {
  color: #002446;
}
/* Team v5 & v6 & v7
------------------------------------*/
.team-v5 small,
.team-v6 small,
.team-v7 .team-v7-position {
  color: #002446;
}
/* Headliner Center
------------------------------------*/
.headline-center h2:after {
  background: #002446;
}
/* Headliner Left
------------------------------------*/
.headline-left .headline-brd:after {
  background: #002446;
}
/* Portfolio Box
------------------------------------*/
.portfolio-box .portfolio-box-in i {
  background: #002446;
}
/* Flat Background Block v1
------------------------------------*/
.flat-bg-block-v1 .checked-list i {
  color: #002446;
}
/* Owl Carousel v5
------------------------------------*/
.owl-carousel-v5 .owl-controls .owl-page.active span,
.owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #002446;
}
/* Content Boxes v5
------------------------------------*/
.content-boxes-v5:hover i {
  background: #002446;
}
/* Block Grid v1
------------------------------------*/
.block-grid-v1:hover {
  border-color: #002446;
}
/* Block Grid v2
------------------------------------*/
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #002446;
}
/* Content Boxes v6
------------------------------------*/
.content-boxes-v6:hover i:after {
  border-color: #002446;
}
.content-boxes-v6:hover i {
  background: #002446;
}
/* Portfolio Box-v2
------------------------------------*/
.portfolio-box-v2 .portfolio-box-v2-in i {
  background: rgba(71, 101, 160, 0.8);
}
.portfolio-box-v2 .portfolio-box-v2-in i:hover {
  background: #002446;
}
/* Service Block v1
------------------------------------*/
.service-block-v1 i {
  background: #002446;
}
/* Service Block v4
------------------------------------*/
.service-block-v4 .service-desc i {
  color: #002446;
}
/* Service Block v7
------------------------------------*/
.service-block-v7 i {
  background: #002446;
}
/* Service Block v8
------------------------------------*/
.service-block-v8 .service-block-desc h3::after {
  background: #002446;
}
/* Testimonials bs
------------------------------------*/
.testimonials-bs .carousel-control-v2 i:hover {
  border-color: #002446;
  color: #002446;
}
/* Fusion Portfolio
------------------------------------*/
.fusion-portfolio #filters-container .cbp-filter-item-active {
  background: #002446;
  border-color: #002446;
}
.fusion-portfolio #filters-container .cbp-filter-item:hover {
  color: #002446;
}
.blog_masonry_3col h3 a:hover {
  color: #002446;
}
/* Cube Portfolio
------------------------------------*/
.cube-portfolio .cbp-l-filters-text .cbp-filter-item.cbp-filter-item-active,
.cube-portfolio .cbp-l-filters-text .cbp-filter-item:hover {
  color: #002446;
}
.cube-portfolio .link-captions li i:hover {
  color: #fff;
  background: #002446;
}
.cube-portfolio .cbp-caption-activeWrap.default-transparent-hover {
  background: rgba(71, 101, 160, 0.9) !important;
}
/* Recent Works
------------------------------------*/
.owl-work-v1 .item a:hover span {
  border-bottom-color: #002446;
}
/* Footer Default
------------------------------------*/
.footer-default .footer .dl-horizontal a:hover {
  color: #002446 !important;
}
.footer-default .footer a {
  color: #002446;
}
.footer-default .footer a:hover {
  color: #324c80;
}
.footer-default .copyright a {
  color: #002446;
}
.footer-default .copyright a:hover {
  color: #324c80;
}
/* Footer v4
------------------------------------*/
.footer-v4 .copyright a {
  color: #002446;
}
/* Title v1
------------------------------------*/
.title-v1 h1:after,
.title-v1 h2:after {
  background-color: #002446;
}
/* Copyright Section
------------------------------------*/
.copyright-section i.back-to-top:hover {
  color: #002446;
}
/* Top Control
------------------------------------*/
#topcontrol:hover {
  background-color: #002446;
}
/* News Info
------------------------------------*/
.news-v1 .news-v1-info li a:hover {
  color: #002446;
}
.news-v1 h3 a:hover {
  color: #002446;
}
.news-v2 .news-v2-desc h3 a:hover {
  color: #002446 !important;
}
.news-v3 .post-shares li span {
  background: #002446;
}
.news-v3 .posted-info li a:hover {
  color: #002446 !important;
}
.news-v3 h2 a:hover {
  color: #002446 !important;
}
/* Blog Trending
------------------------------------*/
.blog-trending small a:hover {
  color: #002446;
}
/* Blog Masonry
------------------------------------*/
.blog_masonry_3col ul.grid-boxes-news li a:hover {
  color: #002446;
}
/* List v1
------------------------------------*/
.lists-v1 i {
  background: #002446;
}
/* List v2
------------------------------------*/
.lists-v2 i {
  color: #002446;
}
/* Process v1
------------------------------------*/
.process-v1 .process-in > li i {
  background: #002446;
}
/* Featured Blog
------------------------------------*/
.featured-blog h2::after {
  background: #002446;
}
.featured-blog .featured-img i:hover {
  color: #fff;
  background: #002446;
}
.rgba-default {
  background-color: #4765a0;
}
/* Blog Latest Posts
------------------------------------*/
.blog-latest-posts h3 a:hover {
  color: #002446 !important;
}
/* Blog Trending
------------------------------------*/
.blog-trending h3 a:hover {
  color: #002446 !important;
}
/*404 Error Page v1 
------------------------------------*/
.error-v1 {
  padding-bottom: 30px;
  text-align: center;
}
.error-v1 p {
  color: #555;
  font-size: 16px;
}
.error-v1 span {
  color: #555;
  display: block;
  font-size: 35px;
  font-weight: 200;
}
.error-v1 span.error-v1-title {
  color: #777;
  font-size: 180px;
  line-height: 200px;
  padding-bottom: 20px;
}
/*For Mobile Devices*/
@media (max-width: 500px) {
  .error-v1 p {
    font-size: 12px;
  }
  .error-v1 span {
    font-size: 25px;
  }
  .error-v1 span.error-v1-title {
    font-size: 140px;
  }
}
/**/
/* font */
/**/
/**/
/* defaults */
/**/
.sky-form {
  margin: 0;
  outline: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  font: 13px/1.55 'Open Sans', Helvetica, Arial, sans-serif;
  color: #666;
}
.sky-form header {
  display: block;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  font-size: 25px;
  font-weight: 300;
  color: #232323;
}
.sky-form fieldset {
  display: block;
  padding: 25px 30px 5px;
  border: none;
  background: rgba(255, 255, 255, 0.9);
}
.sky-form fieldset + fieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sky-form section {
  margin-bottom: 20px;
}
.sky-form footer {
  display: block;
  padding: 15px 30px 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
}
.sky-form footer:after {
  content: '';
  display: table;
  clear: both;
}
.sky-form a {
  color: #2da5da;
}
.sky-form .label {
  display: block;
  margin-bottom: 6px;
  line-height: 19px;
  font-weight: 400;
}
.sky-form .label.col {
  margin: 0;
  padding-top: 10px;
}
.sky-form .note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999;
}
.sky-form .input,
.sky-form .select,
.sky-form .textarea,
.sky-form .radio,
.sky-form .checkbox,
.sky-form .toggle,
.sky-form .button {
  position: relative;
  display: block;
}
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 39px;
  padding: 6px 10px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  background: #fff;
  font: 15px/23px 'Open Sans', Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.sky-form .progress {
  float: right;
  margin-top: 10px;
  line-height: 39px;
  color: #232323;
}
.sky-form button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
  position: absolute;
  top: 2px;
  right: 2px;
  border-left: 1px solid #e5e5e5;
}
/**/
/* file inputs */
/**/
.sky-form .input-file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 31px;
  margin: 0;
  padding: 0 20px;
  font-size: 13px;
  line-height: 31px;
}
.sky-form .input-file .button:hover {
  box-shadow: none;
}
.sky-form .input-file .button input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 30px;
  cursor: pointer;
  opacity: 0;
}
/**/
/* selects */
/**/
.sky-form .select i {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 28px;
  height: 35px;
  background: #fff;
  pointer-events: none;
}
.sky-form .select i:after,
.sky-form .select i:before {
  content: '';
  position: absolute;
  right: 10px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.sky-form .select i:after {
  bottom: 12px;
  border-top: 4px solid #404040;
}
.sky-form .select i:before {
  top: 12px;
  border-bottom: 4px solid #404040;
}
.sky-form .select select {
  padding-right: 28px;
}
.sky-form .select-multiple select {
  height: auto;
}
/**/
/* textareas */
/**/
.sky-form .textarea textarea {
  height: auto;
  resize: none;
}
.sky-form .textarea-resizable textarea {
  resize: vertical;
}
.sky-form .textarea-expandable textarea {
  height: 39px;
}
.sky-form .textarea-expandable textarea:focus {
  height: auto;
}
/**/
/* radios and checkboxes */
/**/
.sky-form .radio,
.sky-form .checkbox {
  margin-bottom: 4px;
  padding-left: 27px;
  font-size: 15px;
  line-height: 27px;
  color: #404040;
  cursor: pointer;
}
.sky-form .radio:last-child,
.sky-form .checkbox:last-child {
  margin-bottom: 0;
}
.sky-form .radio input,
.sky-form .checkbox input {
  position: absolute;
  left: -9999px;
}
.sky-form .radio i,
.sky-form .checkbox i {
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  width: 13px;
  height: 13px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  background: #fff;
}
.sky-form .radio i {
  border-radius: 50%;
}
.sky-form .radio input + i:after,
.sky-form .checkbox input + i:after {
  position: absolute;
  opacity: 0;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s;
}
.sky-form .radio input + i:after {
  content: '';
  top: 4px;
  left: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.sky-form .checkbox input + i:after {
  content: '\f00c';
  top: -1px;
  left: -1px;
  width: 15px;
  height: 15px;
  font: normal 12px/16px FontAwesome;
  text-align: center;
}
.sky-form .radio input:checked + i:after,
.sky-form .checkbox input:checked + i:after {
  opacity: 1;
}
.sky-form .inline-group {
  margin: 0 -30px -4px 0;
}
.sky-form .inline-group:after {
  content: '';
  display: table;
  clear: both;
}
.sky-form .inline-group .radio,
.sky-form .inline-group .checkbox {
  float: left;
  margin-right: 30px;
}
.sky-form .inline-group .radio:last-child,
.sky-form .inline-group .checkbox:last-child {
  margin-bottom: 4px;
}
/**/
/* toggles */
/**/
.sky-form .toggle {
  margin-bottom: 4px;
  padding-right: 61px;
  font-size: 15px;
  line-height: 27px;
  color: #404040;
  cursor: pointer;
}
.sky-form .toggle:last-child {
  margin-bottom: 0;
}
.sky-form .toggle input {
  position: absolute;
  left: -9999px;
}
.sky-form .toggle i {
  content: '';
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 49px;
  height: 17px;
  border-width: 2px;
  border-style: solid;
  border-radius: 12px;
  background: #fff;
}
.sky-form .toggle i:after {
  content: 'OFF';
  position: absolute;
  top: 2px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 9px;
  line-height: 13px;
  font-weight: 700;
  text-align: left;
  color: #5f5f5f;
}
.sky-form .toggle i:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 1;
  -ms-transition: right 0.2s;
  -moz-transition: right 0.2s;
  -webkit-transition: right 0.2s;
}
.sky-form .toggle input:checked + i:after {
  content: 'ON';
  text-align: right;
}
.sky-form .toggle input:checked + i:before {
  right: 36px;
}
/**/
/* ratings */
/**/
.sky-form .rating {
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 27px;
  color: #404040;
}
.sky-form .rating:last-child {
  margin-bottom: 0;
}
.sky-form .rating input {
  position: absolute;
  left: -9999px;
}
.sky-form .rating label {
  display: block;
  float: right;
  height: 17px;
  margin-top: 5px;
  padding: 0 2px;
  font-size: 17px;
  line-height: 17px;
  cursor: pointer;
}
/**/
/* buttons */
/**/
.sky-form .button {
  float: right;
  height: 39px;
  overflow: hidden;
  margin: 10px 0 0 20px;
  padding: 0 25px;
  outline: none;
  border: 0;
  font: 300 15px/39px 'Open Sans', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.sky-form .button-uploading {
  position: relative;
  color: transparent;
  cursor: default;
}
.sky-form .button-uploading:after {
  content: 'Uploading...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  -o-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -webkit-animation: blink 1s linear infinite;
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
  position: absolute;
  top: 5px;
  width: 29px;
  height: 29px;
  font-size: 15px;
  line-height: 29px;
  text-align: center;
}
.sky-form .icon-append {
  right: 5px;
  padding-left: 3px;
  border-left-width: 1px;
  border-left-style: solid;
}
.sky-form .icon-prepend {
  left: 5px;
  padding-right: 3px;
  border-right-width: 1px;
  border-right-style: solid;
}
.sky-form .input .icon-prepend + input,
.sky-form .textarea .icon-prepend + textarea {
  padding-left: 46px;
}
.sky-form .input .icon-append + input,
.sky-form .textarea .icon-append + textarea {
  padding-right: 46px;
}
.sky-form .input .icon-prepend + .icon-append + input,
.sky-form .textarea .icon-prepend + .icon-append + textarea {
  padding-left: 46px;
}
/**/
/* grid */
/**/
.sky-form .row {
  margin: 0 -15px;
}
.sky-form .row:after {
  content: '';
  display: table;
  clear: both;
}
.sky-form .col {
  float: left;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.sky-form .col-1 {
  width: 8.33%;
}
.sky-form .col-2 {
  width: 16.66%;
}
.sky-form .col-3 {
  width: 25%;
}
.sky-form .col-4 {
  width: 33.33%;
}
.sky-form .col-5 {
  width: 41.66%;
}
.sky-form .col-6 {
  width: 50%;
}
.sky-form .col-7 {
  width: 58.33%;
}
.sky-form .col-8 {
  width: 66.67%;
}
.sky-form .col-9 {
  width: 75%;
}
.sky-form .col-10 {
  width: 83.33%;
}
.sky-form .col-11 {
  width: 91.66%;
}
@media screen and (max-width: 600px) {
  .sky-form .col {
    float: none;
    width: 100%;
  }
}
/**/
/* tooltips */
/**/
.sky-form .tooltip {
  position: absolute;
  z-index: 1;
  left: -9999px;
  padding: 2px 8px 3px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 0;
  -ms-transition: margin 0.3s, opacity 0.3s;
  -moz-transition: margin 0.3s, opacity 0.3s;
  -webkit-transition: margin 0.3s, opacity 0.3s;
}
.sky-form .tooltip:after {
  content: '';
  position: absolute;
}
.sky-form .input input:focus + .tooltip,
.sky-form .textarea textarea:focus + .tooltip {
  opacity: 1;
}
.sky-form .tooltip-top-right {
  bottom: 100%;
  margin-bottom: 15px;
}
.sky-form .tooltip-top-right:after {
  top: 100%;
  right: 16px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.sky-form .input input:focus + .tooltip-top-right,
.sky-form .textarea textarea:focus + .tooltip-top-right {
  right: 0;
  left: auto;
  margin-bottom: 5px;
}
.sky-form .tooltip-top-left {
  bottom: 100%;
  margin-bottom: 15px;
}
.sky-form .tooltip-top-left:after {
  top: 100%;
  left: 16px;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.sky-form .input input:focus + .tooltip-top-left,
.sky-form .textarea textarea:focus + .tooltip-top-left {
  right: auto;
  left: 0;
  margin-bottom: 5px;
}
.sky-form .tooltip-right {
  top: 9px;
  white-space: nowrap;
  margin-left: 15px;
}
.sky-form .tooltip-right:after {
  top: 6px;
  right: 100%;
  border-top: 4px solid transparent;
  border-right: 4px solid rgba(0, 0, 0, 0.9);
  border-bottom: 4px solid transparent;
}
.sky-form .input input:focus + .tooltip-right,
.sky-form .textarea textarea:focus + .tooltip-right {
  left: 100%;
  margin-left: 5px;
}
.sky-form .tooltip-left {
  top: 9px;
  white-space: nowrap;
  margin-right: 15px;
}
.sky-form .tooltip-left:after {
  top: 6px;
  left: 100%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid rgba(0, 0, 0, 0.9);
}
.sky-form .input input:focus + .tooltip-left,
.sky-form .textarea textarea:focus + .tooltip-left {
  right: 100%;
  left: auto;
  margin-right: 5px;
}
.sky-form .tooltip-bottom-right {
  top: 100%;
  margin-top: 15px;
}
.sky-form .tooltip-bottom-right:after {
  bottom: 100%;
  right: 16px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent;
}
.sky-form .input input:focus + .tooltip-bottom-right,
.sky-form .textarea textarea:focus + .tooltip-bottom-right {
  right: 0;
  left: auto;
  margin-top: 5px;
}
.sky-form .tooltip-bottom-left {
  top: 100%;
  margin-top: 15px;
}
.sky-form .tooltip-bottom-left:after {
  bottom: 100%;
  left: 16px;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-left: 4px solid transparent;
}
.sky-form .input input:focus + .tooltip-bottom-left,
.sky-form .textarea textarea:focus + .tooltip-bottom-left {
  right: auto;
  left: 0;
  margin-top: 5px;
}
/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
  border-color: #e5e5e5;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}
.sky-form .toggle i:before {
  background-color: #2da5da;
}
.sky-form .rating label {
  color: #ccc;
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s;
}
.sky-form .button {
  background-color: #2da5da;
  opacity: 0.8;
  -ms-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
}
.sky-form .button.button-secondary {
  background-color: #b3b3b3;
}
.sky-form .icon-append,
.sky-form .icon-prepend {
  color: #ccc;
}
/**/
/* hover state */
/**/
.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea,
.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
  border-color: #8dc9e5;
}
.sky-form .rating input + label:hover,
.sky-form .rating input + label:hover ~ label {
  color: #2da5da;
}
.sky-form .button:hover {
  opacity: 1;
}
/**/
/* focus state */
/**/
.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus,
.sky-form .radio input:focus + i,
.sky-form .checkbox input:focus + i,
.sky-form .toggle input:focus + i {
  border-color: #2da5da;
}
/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
  background-color: #2da5da;
}
.sky-form .checkbox input + i:after {
  color: #2da5da;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border-color: #2da5da;
}
.sky-form .rating input:checked ~ label {
  color: #2da5da;
}
/**/
/* error state */
/**/
.sky-form .state-error input,
.sky-form .state-error select,
.sky-form .state-error select + i,
.sky-form .state-error textarea,
.sky-form .radio.state-error i,
.sky-form .checkbox.state-error i,
.sky-form .toggle.state-error i,
.sky-form .toggle.state-error input:checked + i {
  background: #fff0f0;
}
.sky-form .state-error + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #ee9393;
}
.sky-form .rating.state-error + em {
  margin-top: -4px;
  margin-bottom: 4px;
}
/**/
/* success state */
/**/
.sky-form .state-success input,
.sky-form .state-success select,
.sky-form .state-success select + i,
.sky-form .state-success textarea,
.sky-form .radio.state-success i,
.sky-form .checkbox.state-success i,
.sky-form .toggle.state-success i,
.sky-form .toggle.state-success input:checked + i {
  background: #f0fff0;
}
.sky-form .state-success + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #ee9393;
}
.sky-form .note-success {
  color: #6fb679;
}
/**/
/* disabled state */
/**/
.sky-form .input.state-disabled input,
.sky-form .select.state-disabled,
.sky-form .textarea.state-disabled,
.sky-form .radio.state-disabled,
.sky-form .checkbox.state-disabled,
.sky-form .toggle.state-disabled,
.sky-form .button.state-disabled {
  cursor: default;
  opacity: 0.5;
}
.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
  border-color: #e5e5e5;
}
/**/
/* submited state */
/**/
.sky-form .message {
  display: none;
  color: #6fb679;
}
.sky-form .message i {
  display: block;
  margin: 0 auto 20px;
  width: 81px;
  height: 81px;
  border: 1px solid #6fb679;
  border-radius: 50%;
  font-size: 30px;
  line-height: 81px;
}
.sky-form.submited fieldset,
.sky-form.submited footer {
  display: none;
}
.sky-form.submited .message {
  display: block;
  padding: 25px 30px;
  background: rgba(255, 255, 255, 0.9);
  font: 300 18px/27px 'Open Sans', Helvetica, Arial, sans-serif;
  text-align: center;
}
/**/
/* datepicker */
/**/
.ui-datepicker {
  display: none;
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font: 13px/1.55 'Open Sans', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #666;
}
.ui-datepicker a {
  color: #404040;
}
.ui-datepicker-header {
  position: relative;
  margin: -10px -12px 10px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 27px;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 0;
  display: block;
  width: 47px;
  height: 47px;
  font-size: 15px;
  line-height: 47px;
  text-decoration: none;
  cursor: pointer;
}
.ui-datepicker-prev {
  left: 0;
}
.ui-datepicker-next {
  right: 0;
}
.ui-datepicker-calendar {
  border-collapse: collapse;
  font-size: 13px;
  line-height: 27px;
}
.ui-datepicker-calendar th {
  color: #999;
}
.ui-datepicker-calendar a,
.ui-datepicker-calendar span {
  display: block;
  width: 31px;
  margin: auto;
  text-decoration: none;
  color: #404040;
}
.ui-datepicker-calendar a:hover {
  background: rgba(0, 0, 0, 0.05);
}
.ui-datepicker-calendar span {
  color: #bfbfbf;
}
.ui-datepicker-today a {
  font-weight: 700;
}
.ui-datepicker-calendar .ui-state-active {
  background: rgba(0, 0, 0, 0.05);
  cursor: default;
}
.ui-datepicker-inline {
  border: 2px solid #e5e5e5;
  background: #fff;
  box-shadow: none;
}
.ui-datepicker-inline .ui-datepicker-header {
  line-height: 47px;
}
.ui-datepicker-inline .ui-datepicker-calendar {
  width: 100%;
}
/**/
/* slider */
/**/
.sky-form .ui-slider {
  position: relative;
  height: 3px;
  border: 2px solid #e5e5e5;
  background: #fff;
  margin: 12px 6px 26px;
}
.sky-form .ui-slider-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  margin: -8px 0 0 -8px;
  border: 2px solid #e5e5e5;
  outline: none;
  background: #fff;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}
/**/
/* modal */
/**/
.sky-form-modal {
  position: fixed;
  z-index: 1;
  display: none;
  width: 400px;
}
.sky-form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
/**/
/* bootstrap compatibility */
/**/
/*
.sky-form *,
.sky-form *:after,
.sky-form *:before {
	margin: 0;
	padding: 0;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
}
*/
.sky-form .label {
  border-radius: 0;
  font-size: 100%;
  text-align: left;
  white-space: normal;
  color: inherit;
}
.sky-form .radio,
.sky-form .checkbox {
  font-weight: 400;
}
.sky-form .radio + .radio,
.sky-form .checkbox + .checkbox {
  margin-top: 0;
}
/*Sky-Forms
------------------------------------*/
/*Contact Style*/
.sky-form.contact-style {
  border: none;
}
/*Comment Style*/
.sky-form.comment-style,
.sky-form.comment-style fieldset {
  padding: 0;
  border: none;
  background: inherit;
}
.sky-form.comment-style input,
.sky-form.comment-style textarea {
  border: none;
}
.sky-form.comment-style input:focus,
.sky-form.comment-style textarea:focus {
  box-shadow: none;
}
/*Sky Space*/
.sky-form .sky-space-20 {
  margin-bottom: 20px;
}
.sky-form .sky-space-30 {
  margin-bottom: 30px;
}
/**/
/* defaults */
/**/
.sky-form {
  box-shadow: none;
  border: 1px solid #eee;
}
.sky-form header {
  color: inherit;
  display: block;
  font-size: 20px;
  background: #fff;
  font-weight: 400;
  padding: 8px 30px;
}
.sky-form fieldset.no-padding {
  padding: 0;
}
.sky-form fieldset {
  background: #fff;
}
.sky-form footer {
  background: #fff;
  padding: 15px 30px;
}
.sky-form a {
  color: inherit;
}
.sky-form a.btn-u {
  color: #fff;
}
.sky-form .input input {
  height: 34px;
  padding: 6px 12px;
}
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
  border-width: 1px;
  font-size: 14px;
  color: #404040;
}
.sky-form .select select {
  height: 33px;
  padding: 6px 10px;
}
.sky-form .select-multiple select {
  height: auto;
}
/**/
/* file inputs */
/**/
.sky-form .input-file .button {
  height: 32px;
  top: 1px;
  right: 1px;
}
/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
  position: absolute;
  top: 1px;
  right: 1px;
  border-left: 1px solid #e5e5e5;
}
/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
  border-color: #bbb;
}
.sky-form .toggle i:before {
  background-color: #999;
}
.sky-form .button {
  background: #72c02c;
}
/**/
/* toggles */
/**/
.sky-form .toggle {
  font-weight: normal;
}
.sky-form .toggle i {
  width: 54px;
  height: 21px;
  border-width: 1px;
}
.sky-form .toggle i:after {
  top: 3px;
}
.sky-form .toggle i:before {
  top: 5px;
  right: 6px;
}
/*(remove Bootstrap 'radio, checkbox' margin-top)
--------------------------------------------------*/
.radio,
.checkbox {
  margin-top: 0;
}
/**/
/* radios and checkboxes */
/**/
.sky-form .radio i,
.sky-form .checkbox i {
  width: 17px;
  height: 17px;
  border-width: 1px;
}
.sky-form .checkbox input + i:after {
  top: 2px;
  left: 0;
  font: normal 10px FontAwesome;
}
/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
  top: 5px;
  left: 5px;
  background-color: #999;
}
.sky-form .checkbox input + i:after {
  color: #999;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
  border-color: #999;
}
.sky-form .rating input:checked ~ label {
  color: #72c02c;
}
/**/
/* selects */
/**/
.sky-form .select i {
  top: 14px;
  width: 9px;
  right: 13px;
  height: 6px;
}
.sky-form .select i:after,
.sky-form .select i:before {
  top: 4px;
}
.sky-form .select i:before {
  top: -2px;
  border-bottom: 4px solid #404040;
}
.label-rounded .ui-slider-handle {
  border-radius: 50% !important;
}
/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
  top: 1px;
  height: 32px;
  font-size: 14px;
  line-height: 33px;
  background: inherit;
}
.sky-form .icon-append {
  right: 1px;
  padding: 0 3px;
  min-width: 34px;
}
.sky-form .icon-prepend {
  left: 6px;
  padding-right: 5px;
}
/**/
/* focus state */
/**/
.sky-form .input input:focus,
.sky-form .select select:focus,
.sky-form .textarea textarea:focus {
  border-color: #bbb;
  box-shadow: 0 0 2px #c9c9c9;
}
.sky-form .radio input:focus + i,
.sky-form .checkbox input:focus + i,
.sky-form .toggle input:focus + i {
  border-color: #999;
  box-shadow: none;
}
/**/
/* hover state */
/**/
.sky-form .input:hover input,
.sky-form .select:hover select,
.sky-form .textarea:hover textarea {
  border-color: #999;
}
.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover {
  border-color: #999;
}
.sky-form .rating input + label:hover,
.sky-form .rating input + label:hover ~ label {
  color: #72c02c;
}
.sky-form .icon-append,
.sky-form .icon-prepend {
  color: #b3b3b3;
}
/**/
/* disabled state */
/**/
.sky-form .input.state-disabled:hover input,
.sky-form .select.state-disabled:hover select,
.sky-form .textarea.state-disabled:hover textarea,
.sky-form .radio.state-disabled:hover i,
.sky-form .checkbox.state-disabled:hover i,
.sky-form .toggle.state-disabled:hover i {
  border-color: #bbb;
}
/**/
/* datepicker */
/**/
.ui-datepicker-header {
  padding: 10px 0;
  font-size: 16px;
}
.ui-datepicker-inline {
  border-width: 1px;
}
.ui-datepicker-inline .ui-datepicker-header {
  line-height: 27px;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  font-size: 18px;
  line-height: 47px;
}
.ui-datepicker-calendar a {
  border: 1px solid transparent;
}
.ui-datepicker-calendar .ui-state-active {
  background: inherit;
  border: 1px solid #bbb;
}
.ui-datepicker-calendar a:hover {
  background: inherit;
  border: 1px solid #bbb;
}
/**/
/* bootstrap compatibility */
/**/
.sky-form button *,
.sky-form button *:after,
.sky-form button *:before {
  margin: 0 auto;
  padding: 0 auto;
}
.btn-u.button-uploading:after {
  top: 6px;
}
.btn-u.btn-u-lg.button-uploading:after {
  top: 10px;
}
.btn-u.btn-u-sm.button-uploading:after {
  top: 3px;
}
.btn-u.btn-u-xs.button-uploading:after {
  top: 2px;
}
.label {
  padding: 0.2em 0 0.3em;
}
/*Fix for datepicker's responsive issue on resolution 1024x768*/
/*.ui-datepicker-calendar a,*/
.ui-datepicker-calendar span {
  width: 26px;
  /*color: #bfbfbf;*/
}
.glyphicons {
  float: left;
  font-weight: bold;
  white-space: nowrap;
  background: #fff;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, 'Arial CE', Arial, sans-serif;
  font-size: 14px;
  color: #000;
  padding: 20px 20px 0 20px;
  width: 100%;
  line-height: 11px;
  overflow: visible;
}
.glyphicons strong {
  display: block;
  width: 100%;
  line-height: 12px;
  overflow: hidden;
}
.glyphicons span {
  display: inline-block;
  width: 100%;
  *display: inline;
  *zoom: 1;
  margin: 3px 0 0 0;
  font-size: 10px;
  font-weight: normal;
  overflow: hidden;
}
.btn > .glyphicons {
  padding: 20px 0px 0px 20px;
}
/*!
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons-regular.eot');
  src: url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons-regular.svg#glyphiconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.glyphicons {
  display: inline-block;
  position: relative;
  padding-left: 48px;
  color: #1d1d1b;
  text-decoration: none;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.glyphicons:before {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  margin: 0 5px 0 0;
  font: 24px/1em 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  color: #1d1d1b;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.glyphicons.white:before {
  color: #fff;
}
.glyphicons.glass:before {
  content: "\E001";
}
.glyphicons.leaf:before {
  content: "\E002";
}
.glyphicons.dog:before {
  content: "\E003";
}
.glyphicons.user:before {
  content: "\E004";
}
.glyphicons.girl:before {
  content: "\E005";
}
.glyphicons.car:before {
  content: "\E006";
}
.glyphicons.user_add:before {
  content: "\E007";
}
.glyphicons.user_remove:before {
  content: "\E008";
}
.glyphicons.film:before {
  content: "\E009";
}
.glyphicons.magic:before {
  content: "\E010";
}
.glyphicons.envelope:before {
  content: "\2709";
}
.glyphicons.camera:before {
  content: "\E011";
}
.glyphicons.heart:before {
  content: "\E013";
}
.glyphicons.beach_umbrella:before {
  content: "\E014";
}
.glyphicons.train:before {
  content: "\E015";
}
.glyphicons.print:before {
  content: "\E016";
}
.glyphicons.bin:before {
  content: "\E017";
}
.glyphicons.music:before {
  content: "\E018";
}
.glyphicons.note:before {
  content: "\E019";
}
.glyphicons.heart_empty:before {
  content: "\E020";
}
.glyphicons.home:before {
  content: "\E021";
}
.glyphicons.snowflake:before {
  content: "\2744";
}
.glyphicons.fire:before {
  content: "\E023";
}
.glyphicons.magnet:before {
  content: "\E024";
}
.glyphicons.parents:before {
  content: "\E025";
}
.glyphicons.binoculars:before {
  content: "\E026";
}
.glyphicons.road:before {
  content: "\E027";
}
.glyphicons.search:before {
  content: "\E028";
}
.glyphicons.cars:before {
  content: "\E029";
}
.glyphicons.notes_2:before {
  content: "\E030";
}
.glyphicons.pencil:before {
  content: "\270F";
}
.glyphicons.bus:before {
  content: "\E032";
}
.glyphicons.wifi_alt:before {
  content: "\E033";
}
.glyphicons.luggage:before {
  content: "\E034";
}
.glyphicons.old_man:before {
  content: "\E035";
}
.glyphicons.woman:before {
  content: "\E036";
}
.glyphicons.file:before {
  content: "\E037";
}
.glyphicons.coins:before {
  content: "\E038";
}
.glyphicons.airplane:before {
  content: "\2708";
}
.glyphicons.notes:before {
  content: "\E040";
}
.glyphicons.stats:before {
  content: "\E041";
}
.glyphicons.charts:before {
  content: "\E042";
}
.glyphicons.pie_chart:before {
  content: "\E043";
}
.glyphicons.group:before {
  content: "\E044";
}
.glyphicons.keys:before {
  content: "\E045";
}
.glyphicons.calendar:before {
  content: "\E046";
}
.glyphicons.router:before {
  content: "\E047";
}
.glyphicons.camera_small:before {
  content: "\E048";
}
.glyphicons.dislikes:before {
  content: "\E049";
}
.glyphicons.star:before {
  content: "\E050";
}
.glyphicons.link:before {
  content: "\E051";
}
.glyphicons.eye_open:before {
  content: "\E052";
}
.glyphicons.eye_close:before {
  content: "\E053";
}
.glyphicons.alarm:before {
  content: "\E054";
}
.glyphicons.clock:before {
  content: "\E055";
}
.glyphicons.stopwatch:before {
  content: "\E056";
}
.glyphicons.projector:before {
  content: "\E057";
}
.glyphicons.history:before {
  content: "\E058";
}
.glyphicons.truck:before {
  content: "\E059";
}
.glyphicons.cargo:before {
  content: "\E060";
}
.glyphicons.compass:before {
  content: "\E061";
}
.glyphicons.keynote:before {
  content: "\E062";
}
.glyphicons.paperclip:before {
  content: "\E063";
}
.glyphicons.power:before {
  content: "\E064";
}
.glyphicons.lightbulb:before {
  content: "\E065";
}
.glyphicons.tag:before {
  content: "\E066";
}
.glyphicons.tags:before {
  content: "\E067";
}
.glyphicons.cleaning:before {
  content: "\E068";
}
.glyphicons.ruller:before {
  content: "\E069";
}
.glyphicons.gift:before {
  content: "\E070";
}
.glyphicons.umbrella:before {
  content: "\2602";
}
.glyphicons.book:before {
  content: "\E072";
}
.glyphicons.bookmark:before {
  content: "\E073";
}
.glyphicons.wifi:before {
  content: "\E074";
}
.glyphicons.cup:before {
  content: "\E075";
}
.glyphicons.stroller:before {
  content: "\E076";
}
.glyphicons.headphones:before {
  content: "\E077";
}
.glyphicons.headset:before {
  content: "\E078";
}
.glyphicons.warning_sign:before {
  content: "\E079";
}
.glyphicons.signal:before {
  content: "\E080";
}
.glyphicons.retweet:before {
  content: "\E081";
}
.glyphicons.refresh:before {
  content: "\E082";
}
.glyphicons.roundabout:before {
  content: "\E083";
}
.glyphicons.random:before {
  content: "\E084";
}
.glyphicons.heat:before {
  content: "\E085";
}
.glyphicons.repeat:before {
  content: "\E086";
}
.glyphicons.display:before {
  content: "\E087";
}
.glyphicons.log_book:before {
  content: "\E088";
}
.glyphicons.address_book:before {
  content: "\E089";
}
.glyphicons.building:before {
  content: "\E090";
}
.glyphicons.eyedropper:before {
  content: "\E091";
}
.glyphicons.adjust:before {
  content: "\E092";
}
.glyphicons.tint:before {
  content: "\E093";
}
.glyphicons.crop:before {
  content: "\E094";
}
.glyphicons.vector_path_square:before {
  content: "\E095";
}
.glyphicons.vector_path_circle:before {
  content: "\E096";
}
.glyphicons.vector_path_polygon:before {
  content: "\E097";
}
.glyphicons.vector_path_line:before {
  content: "\E098";
}
.glyphicons.vector_path_curve:before {
  content: "\E099";
}
.glyphicons.vector_path_all:before {
  content: "\E100";
}
.glyphicons.font:before {
  content: "\E101";
}
.glyphicons.italic:before {
  content: "\E102";
}
.glyphicons.bold:before {
  content: "\E103";
}
.glyphicons.text_underline:before {
  content: "\E104";
}
.glyphicons.text_strike:before {
  content: "\E105";
}
.glyphicons.text_height:before {
  content: "\E106";
}
.glyphicons.text_width:before {
  content: "\E107";
}
.glyphicons.text_resize:before {
  content: "\E108";
}
.glyphicons.left_indent:before {
  content: "\E109";
}
.glyphicons.right_indent:before {
  content: "\E110";
}
.glyphicons.align_left:before {
  content: "\E111";
}
.glyphicons.align_center:before {
  content: "\E112";
}
.glyphicons.align_right:before {
  content: "\E113";
}
.glyphicons.justify:before {
  content: "\E114";
}
.glyphicons.list:before {
  content: "\E115";
}
.glyphicons.text_smaller:before {
  content: "\E116";
}
.glyphicons.text_bigger:before {
  content: "\E117";
}
.glyphicons.embed:before {
  content: "\E118";
}
.glyphicons.embed_close:before {
  content: "\E119";
}
.glyphicons.table:before {
  content: "\E120";
}
.glyphicons.message_full:before {
  content: "\E121";
}
.glyphicons.message_empty:before {
  content: "\E122";
}
.glyphicons.message_in:before {
  content: "\E123";
}
.glyphicons.message_out:before {
  content: "\E124";
}
.glyphicons.message_plus:before {
  content: "\E125";
}
.glyphicons.message_minus:before {
  content: "\E126";
}
.glyphicons.message_ban:before {
  content: "\E127";
}
.glyphicons.message_flag:before {
  content: "\E128";
}
.glyphicons.message_lock:before {
  content: "\E129";
}
.glyphicons.message_new:before {
  content: "\E130";
}
.glyphicons.inbox:before {
  content: "\E131";
}
.glyphicons.inbox_plus:before {
  content: "\E132";
}
.glyphicons.inbox_minus:before {
  content: "\E133";
}
.glyphicons.inbox_lock:before {
  content: "\E134";
}
.glyphicons.inbox_in:before {
  content: "\E135";
}
.glyphicons.inbox_out:before {
  content: "\E136";
}
.glyphicons.cogwheel:before {
  content: "\E137";
}
.glyphicons.cogwheels:before {
  content: "\E138";
}
.glyphicons.picture:before {
  content: "\E139";
}
.glyphicons.adjust_alt:before {
  content: "\E140";
}
.glyphicons.database_lock:before {
  content: "\E141";
}
.glyphicons.database_plus:before {
  content: "\E142";
}
.glyphicons.database_minus:before {
  content: "\E143";
}
.glyphicons.database_ban:before {
  content: "\E144";
}
.glyphicons.folder_open:before {
  content: "\E145";
}
.glyphicons.folder_plus:before {
  content: "\E146";
}
.glyphicons.folder_minus:before {
  content: "\E147";
}
.glyphicons.folder_lock:before {
  content: "\E148";
}
.glyphicons.folder_flag:before {
  content: "\E149";
}
.glyphicons.folder_new:before {
  content: "\E150";
}
.glyphicons.edit:before {
  content: "\E151";
}
.glyphicons.new_window:before {
  content: "\E152";
}
.glyphicons.check:before {
  content: "\E153";
}
.glyphicons.unchecked:before {
  content: "\E154";
}
.glyphicons.more_windows:before {
  content: "\E155";
}
.glyphicons.show_big_thumbnails:before {
  content: "\E156";
}
.glyphicons.show_thumbnails:before {
  content: "\E157";
}
.glyphicons.show_thumbnails_with_lines:before {
  content: "\E158";
}
.glyphicons.show_lines:before {
  content: "\E159";
}
.glyphicons.playlist:before {
  content: "\E160";
}
.glyphicons.imac:before {
  content: "\E161";
}
.glyphicons.macbook:before {
  content: "\E162";
}
.glyphicons.ipad:before {
  content: "\E163";
}
.glyphicons.iphone:before {
  content: "\E164";
}
.glyphicons.iphone_transfer:before {
  content: "\E165";
}
.glyphicons.iphone_exchange:before {
  content: "\E166";
}
.glyphicons.ipod:before {
  content: "\E167";
}
.glyphicons.ipod_shuffle:before {
  content: "\E168";
}
.glyphicons.ear_plugs:before {
  content: "\E169";
}
.glyphicons.record:before {
  content: "\E170";
}
.glyphicons.step_backward:before {
  content: "\E171";
}
.glyphicons.fast_backward:before {
  content: "\E172";
}
.glyphicons.rewind:before {
  content: "\E173";
}
.glyphicons.play:before {
  content: "\E174";
}
.glyphicons.pause:before {
  content: "\E175";
}
.glyphicons.stop:before {
  content: "\E176";
}
.glyphicons.forward:before {
  content: "\E177";
}
.glyphicons.fast_forward:before {
  content: "\E178";
}
.glyphicons.step_forward:before {
  content: "\E179";
}
.glyphicons.eject:before {
  content: "\E180";
}
.glyphicons.facetime_video:before {
  content: "\E181";
}
.glyphicons.download_alt:before {
  content: "\E182";
}
.glyphicons.mute:before {
  content: "\E183";
}
.glyphicons.volume_down:before {
  content: "\E184";
}
.glyphicons.volume_up:before {
  content: "\E185";
}
.glyphicons.screenshot:before {
  content: "\E186";
}
.glyphicons.move:before {
  content: "\E187";
}
.glyphicons.more:before {
  content: "\E188";
}
.glyphicons.brightness_reduce:before {
  content: "\E189";
}
.glyphicons.brightness_increase:before {
  content: "\E190";
}
.glyphicons.circle_plus:before {
  content: "\E191";
}
.glyphicons.circle_minus:before {
  content: "\E192";
}
.glyphicons.circle_remove:before {
  content: "\E193";
}
.glyphicons.circle_ok:before {
  content: "\E194";
}
.glyphicons.circle_question_mark:before {
  content: "\E195";
}
.glyphicons.circle_info:before {
  content: "\E196";
}
.glyphicons.circle_exclamation_mark:before {
  content: "\E197";
}
.glyphicons.remove:before {
  content: "\E198";
}
.glyphicons.ok:before {
  content: "\E199";
}
.glyphicons.ban:before {
  content: "\E200";
}
.glyphicons.download:before {
  content: "\E201";
}
.glyphicons.upload:before {
  content: "\E202";
}
.glyphicons.shopping_cart:before {
  content: "\E203";
}
.glyphicons.lock:before {
  content: "\E204";
}
.glyphicons.unlock:before {
  content: "\E205";
}
.glyphicons.electricity:before {
  content: "\E206";
}
.glyphicons.ok_2:before {
  content: "\E207";
}
.glyphicons.remove_2:before {
  content: "\E208";
}
.glyphicons.cart_out:before {
  content: "\E209";
}
.glyphicons.cart_in:before {
  content: "\E210";
}
.glyphicons.left_arrow:before {
  content: "\E211";
}
.glyphicons.right_arrow:before {
  content: "\E212";
}
.glyphicons.down_arrow:before {
  content: "\E213";
}
.glyphicons.up_arrow:before {
  content: "\E214";
}
.glyphicons.resize_small:before {
  content: "\E215";
}
.glyphicons.resize_full:before {
  content: "\E216";
}
.glyphicons.circle_arrow_left:before {
  content: "\E217";
}
.glyphicons.circle_arrow_right:before {
  content: "\E218";
}
.glyphicons.circle_arrow_top:before {
  content: "\E219";
}
.glyphicons.circle_arrow_down:before {
  content: "\E220";
}
.glyphicons.play_button:before {
  content: "\E221";
}
.glyphicons.unshare:before {
  content: "\E222";
}
.glyphicons.share:before {
  content: "\E223";
}
.glyphicons.chevron-right:before {
  content: "\E224";
}
.glyphicons.chevron-left:before {
  content: "\E225";
}
.glyphicons.bluetooth:before {
  content: "\E226";
}
.glyphicons.euro:before {
  content: "\20AC";
}
.glyphicons.usd:before {
  content: "\E228";
}
.glyphicons.gbp:before {
  content: "\E229";
}
.glyphicons.retweet_2:before {
  content: "\E230";
}
.glyphicons.moon:before {
  content: "\E231";
}
.glyphicons.sun:before {
  content: "\2609";
}
.glyphicons.cloud:before {
  content: "\2601";
}
.glyphicons.direction:before {
  content: "\E234";
}
.glyphicons.brush:before {
  content: "\E235";
}
.glyphicons.pen:before {
  content: "\E236";
}
.glyphicons.zoom_in:before {
  content: "\E237";
}
.glyphicons.zoom_out:before {
  content: "\E238";
}
.glyphicons.pin:before {
  content: "\E239";
}
.glyphicons.albums:before {
  content: "\E240";
}
.glyphicons.rotation_lock:before {
  content: "\E241";
}
.glyphicons.flash:before {
  content: "\E242";
}
.glyphicons.google_maps:before {
  content: "\E243";
}
.glyphicons.anchor:before {
  content: "\2693";
}
.glyphicons.conversation:before {
  content: "\E245";
}
.glyphicons.chat:before {
  content: "\E246";
}
.glyphicons.male:before {
  content: "\E247";
}
.glyphicons.female:before {
  content: "\E248";
}
.glyphicons.asterisk:before {
  content: "\002A";
}
.glyphicons.divide:before {
  content: "\00F7";
}
.glyphicons.snorkel_diving:before {
  content: "\E251";
}
.glyphicons.scuba_diving:before {
  content: "\E252";
}
.glyphicons.oxygen_bottle:before {
  content: "\E253";
}
.glyphicons.fins:before {
  content: "\E254";
}
.glyphicons.fishes:before {
  content: "\E255";
}
.glyphicons.boat:before {
  content: "\E256";
}
.glyphicons.delete:before {
  content: "\E257";
}
.glyphicons.sheriffs_star:before {
  content: "\E258";
}
.glyphicons.qrcode:before {
  content: "\E259";
}
.glyphicons.barcode:before {
  content: "\E260";
}
.glyphicons.pool:before {
  content: "\E261";
}
.glyphicons.buoy:before {
  content: "\E262";
}
.glyphicons.spade:before {
  content: "\E263";
}
.glyphicons.bank:before {
  content: "\E264";
}
.glyphicons.vcard:before {
  content: "\E265";
}
.glyphicons.electrical_plug:before {
  content: "\E266";
}
.glyphicons.flag:before {
  content: "\E267";
}
.glyphicons.credit_card:before {
  content: "\E268";
}
.glyphicons.keyboard-wireless:before {
  content: "\E269";
}
.glyphicons.keyboard-wired:before {
  content: "\E270";
}
.glyphicons.shield:before {
  content: "\E271";
}
.glyphicons.ring:before {
  content: "\02DA";
}
.glyphicons.cake:before {
  content: "\E273";
}
.glyphicons.drink:before {
  content: "\E274";
}
.glyphicons.beer:before {
  content: "\E275";
}
.glyphicons.fast_food:before {
  content: "\E276";
}
.glyphicons.cutlery:before {
  content: "\E277";
}
.glyphicons.pizza:before {
  content: "\E278";
}
.glyphicons.birthday_cake:before {
  content: "\E279";
}
.glyphicons.tablet:before {
  content: "\E280";
}
.glyphicons.settings:before {
  content: "\E281";
}
.glyphicons.bullets:before {
  content: "\E282";
}
.glyphicons.cardio:before {
  content: "\E283";
}
.glyphicons.t-shirt:before {
  content: "\E284";
}
.glyphicons.pants:before {
  content: "\E285";
}
.glyphicons.sweater:before {
  content: "\E286";
}
.glyphicons.fabric:before {
  content: "\E287";
}
.glyphicons.leather:before {
  content: "\E288";
}
.glyphicons.scissors:before {
  content: "\E289";
}
.glyphicons.bomb:before {
  content: "\E290";
}
.glyphicons.skull:before {
  content: "\E291";
}
.glyphicons.celebration:before {
  content: "\E292";
}
.glyphicons.tea_kettle:before {
  content: "\E293";
}
.glyphicons.french_press:before {
  content: "\E294";
}
.glyphicons.coffee_cup:before {
  content: "\E295";
}
.glyphicons.pot:before {
  content: "\E296";
}
.glyphicons.grater:before {
  content: "\E297";
}
.glyphicons.kettle:before {
  content: "\E298";
}
.glyphicons.hospital:before {
  content: "\E299";
}
.glyphicons.hospital_h:before {
  content: "\E300";
}
.glyphicons.microphone:before {
  content: "\E301";
}
.glyphicons.webcam:before {
  content: "\E302";
}
.glyphicons.temple_christianity_church:before {
  content: "\E303";
}
.glyphicons.temple_islam:before {
  content: "\E304";
}
.glyphicons.temple_hindu:before {
  content: "\E305";
}
.glyphicons.temple_buddhist:before {
  content: "\E306";
}
.glyphicons.bicycle:before {
  content: "\E307";
}
.glyphicons.life_preserver:before {
  content: "\E308";
}
.glyphicons.share_alt:before {
  content: "\E309";
}
.glyphicons.comments:before {
  content: "\E310";
}
.glyphicons.flower:before {
  content: "\2698";
}
.glyphicons.baseball:before {
  content: "\26BE";
}
.glyphicons.rugby:before {
  content: "\E313";
}
.glyphicons.ax:before {
  content: "\E314";
}
.glyphicons.table_tennis:before {
  content: "\E315";
}
.glyphicons.bowling:before {
  content: "\E316";
}
.glyphicons.tree_conifer:before {
  content: "\E317";
}
.glyphicons.tree_deciduous:before {
  content: "\E318";
}
.glyphicons.more_items:before {
  content: "\E319";
}
.glyphicons.sort:before {
  content: "\E320";
}
.glyphicons.filter:before {
  content: "\E321";
}
.glyphicons.gamepad:before {
  content: "\E322";
}
.glyphicons.playing_dices:before {
  content: "\E323";
}
.glyphicons.calculator:before {
  content: "\E324";
}
.glyphicons.tie:before {
  content: "\E325";
}
.glyphicons.wallet:before {
  content: "\E326";
}
.glyphicons.piano:before {
  content: "\E327";
}
.glyphicons.sampler:before {
  content: "\E328";
}
.glyphicons.podium:before {
  content: "\E329";
}
.glyphicons.soccer_ball:before {
  content: "\E330";
}
.glyphicons.blog:before {
  content: "\E331";
}
.glyphicons.dashboard:before {
  content: "\E332";
}
.glyphicons.certificate:before {
  content: "\E333";
}
.glyphicons.bell:before {
  content: "\E334";
}
.glyphicons.candle:before {
  content: "\E335";
}
.glyphicons.pushpin:before {
  content: "\E336";
}
.glyphicons.iphone_shake:before {
  content: "\E337";
}
.glyphicons.pin_flag:before {
  content: "\E338";
}
.glyphicons.turtle:before {
  content: "\E339";
}
.glyphicons.rabbit:before {
  content: "\E340";
}
.glyphicons.globe:before {
  content: "\E341";
}
.glyphicons.briefcase:before {
  content: "\E342";
}
.glyphicons.hdd:before {
  content: "\E343";
}
.glyphicons.thumbs_up:before {
  content: "\E344";
}
.glyphicons.thumbs_down:before {
  content: "\E345";
}
.glyphicons.hand_right:before {
  content: "\E346";
}
.glyphicons.hand_left:before {
  content: "\E347";
}
.glyphicons.hand_up:before {
  content: "\E348";
}
.glyphicons.hand_down:before {
  content: "\E349";
}
.glyphicons.fullscreen:before {
  content: "\E350";
}
.glyphicons.shopping_bag:before {
  content: "\E351";
}
.glyphicons.book_open:before {
  content: "\E352";
}
.glyphicons.nameplate:before {
  content: "\E353";
}
.glyphicons.nameplate_alt:before {
  content: "\E354";
}
.glyphicons.vases:before {
  content: "\E355";
}
.glyphicons.bullhorn:before {
  content: "\E356";
}
.glyphicons.dumbbell:before {
  content: "\E357";
}
.glyphicons.suitcase:before {
  content: "\E358";
}
.glyphicons.file_import:before {
  content: "\E359";
}
.glyphicons.file_export:before {
  content: "\E360";
}
.glyphicons.bug:before {
  content: "\E361";
}
.glyphicons.crown:before {
  content: "\E362";
}
.glyphicons.smoking:before {
  content: "\E363";
}
.glyphicons.cloud-download:before {
  content: "\E364";
}
.glyphicons.cloud-upload:before {
  content: "\E365";
}
.glyphicons.restart:before {
  content: "\E366";
}
.glyphicons.security_camera:before {
  content: "\E367";
}
.glyphicons.expand:before {
  content: "\E368";
}
.glyphicons.collapse:before {
  content: "\E369";
}
.glyphicons.collapse_top:before {
  content: "\E370";
}
.glyphicons.globe_af:before {
  content: "\E371";
}
.glyphicons.global:before {
  content: "\E372";
}
.glyphicons.spray:before {
  content: "\E373";
}
.glyphicons.nails:before {
  content: "\E374";
}
.glyphicons.claw_hammer:before {
  content: "\E375";
}
.glyphicons.classic_hammer:before {
  content: "\E376";
}
.glyphicons.hand_saw:before {
  content: "\E377";
}
.glyphicons.riflescope:before {
  content: "\E378";
}
.glyphicons.electrical_socket_eu:before {
  content: "\E379";
}
.glyphicons.electrical_socket_us:before {
  content: "\E380";
}
.glyphicons.message_forward:before {
  content: "\E381";
}
.glyphicons.coat_hanger:before {
  content: "\E382";
}
.glyphicons.dress:before {
  content: "\E383";
}
.glyphicons.bathrobe:before {
  content: "\E384";
}
.glyphicons.shirt:before {
  content: "\E385";
}
.glyphicons.underwear:before {
  content: "\E386";
}
.glyphicons.log_in:before {
  content: "\E387";
}
.glyphicons.log_out:before {
  content: "\E388";
}
.glyphicons.exit:before {
  content: "\E389";
}
.glyphicons.new_window_alt:before {
  content: "\E390";
}
.glyphicons.video_sd:before {
  content: "\E391";
}
.glyphicons.video_hd:before {
  content: "\E392";
}
.glyphicons.subtitles:before {
  content: "\E393";
}
.glyphicons.sound_stereo:before {
  content: "\E394";
}
.glyphicons.sound_dolby:before {
  content: "\E395";
}
.glyphicons.sound_5_1:before {
  content: "\E396";
}
.glyphicons.sound_6_1:before {
  content: "\E397";
}
.glyphicons.sound_7_1:before {
  content: "\E398";
}
.glyphicons.copyright_mark:before {
  content: "\E399";
}
.glyphicons.registration_mark:before {
  content: "\E400";
}
.glyphicons.radar:before {
  content: "\E401";
}
.glyphicons.skateboard:before {
  content: "\E402";
}
.glyphicons.golf_course:before {
  content: "\E403";
}
.glyphicons.sorting:before {
  content: "\E404";
}
.glyphicons.sort-by-alphabet:before {
  content: "\E405";
}
.glyphicons.sort-by-alphabet-alt:before {
  content: "\E406";
}
.glyphicons.sort-by-order:before {
  content: "\E407";
}
.glyphicons.sort-by-order-alt:before {
  content: "\E408";
}
.glyphicons.sort-by-attributes:before {
  content: "\E409";
}
.glyphicons.sort-by-attributes-alt:before {
  content: "\E410";
}
.glyphicons.compressed:before {
  content: "\E411";
}
.glyphicons.package:before {
  content: "\E412";
}
.glyphicons.cloud_plus:before {
  content: "\E413";
}
.glyphicons.cloud_minus:before {
  content: "\E414";
}
.glyphicons.disk_save:before {
  content: "\E415";
}
.glyphicons.disk_open:before {
  content: "\E416";
}
.glyphicons.disk_saved:before {
  content: "\E417";
}
.glyphicons.disk_remove:before {
  content: "\E418";
}
.glyphicons.disk_import:before {
  content: "\E419";
}
.glyphicons.disk_export:before {
  content: "\E420";
}
.glyphicons.tower:before {
  content: "\E421";
}
.glyphicons.send:before {
  content: "\E422";
}
.glyphicons.git_branch:before {
  content: "\E423";
}
.glyphicons.git_create:before {
  content: "\E424";
}
.glyphicons.git_private:before {
  content: "\E425";
}
.glyphicons.git_delete:before {
  content: "\E426";
}
.glyphicons.git_merge:before {
  content: "\E427";
}
.glyphicons.git_pull_request:before {
  content: "\E428";
}
.glyphicons.git_compare:before {
  content: "\E429";
}
.glyphicons.git_commit:before {
  content: "\E430";
}
.glyphicons.construction_cone:before {
  content: "\E431";
}
.glyphicons.shoe_steps:before {
  content: "\E432";
}
.glyphicons.plus:before {
  content: "\002B";
}
.glyphicons.minus:before {
  content: "\2212";
}
.glyphicons.redo:before {
  content: "\E435";
}
.glyphicons.undo:before {
  content: "\E436";
}
.glyphicons.golf:before {
  content: "\E437";
}
.glyphicons.hockey:before {
  content: "\E438";
}
.glyphicons.pipe:before {
  content: "\E439";
}
.glyphicons.wrench:before {
  content: "\E440";
}
.glyphicons.folder_closed:before {
  content: "\E441";
}
.glyphicons.phone_alt:before {
  content: "\E442";
}
.glyphicons.earphone:before {
  content: "\E443";
}
.glyphicons.floppy_disk:before {
  content: "\E444";
}
.glyphicons.floppy_saved:before {
  content: "\E445";
}
.glyphicons.floppy_remove:before {
  content: "\E446";
}
.glyphicons.floppy_save:before {
  content: "\E447";
}
.glyphicons.floppy_open:before {
  content: "\E448";
}
.glyphicons.translate:before {
  content: "\E449";
}
.glyphicons.fax:before {
  content: "\E450";
}
.glyphicons.factory:before {
  content: "\E451";
}
.glyphicons.shop_window:before {
  content: "\E452";
}
.glyphicons.shop:before {
  content: "\E453";
}
.glyphicons.kiosk:before {
  content: "\E454";
}
.glyphicons.kiosk_wheels:before {
  content: "\E455";
}
.glyphicons.kiosk_light:before {
  content: "\E456";
}
.glyphicons.kiosk_food:before {
  content: "\E457";
}
.glyphicons.transfer:before {
  content: "\E458";
}
.glyphicons.money:before {
  content: "\E459";
}
.glyphicons.header:before {
  content: "\E460";
}
.glyphicons.blacksmith:before {
  content: "\E461";
}
.glyphicons.saw_blade:before {
  content: "\E462";
}
.glyphicons.basketball:before {
  content: "\E463";
}
.glyphicons.server:before {
  content: "\E464";
}
.glyphicons.server_plus:before {
  content: "\E465";
}
.glyphicons.server_minus:before {
  content: "\E466";
}
.glyphicons.server_ban:before {
  content: "\E467";
}
.glyphicons.server_flag:before {
  content: "\E468";
}
.glyphicons.server_lock:before {
  content: "\E469";
}
.glyphicons.server_new:before {
  content: "\E470";
}
.glyphicons-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 8px 0 0;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url(../images/glyphicons.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: top;
  *display: inline;
  *zoom: 1;
  *margin-right: .3em;
}
.glyphicons-icon _:-o-prefocus,
.glyphicons-icon {
  background-image: url(../images/glyphicons.png);
}
.no-inlinesvg .glyphicons-icon {
  background-image: url(../images/glyphicons.png);
}
.glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.svg);
}
.glyphicons-icon.white _:-o-prefocus,
.glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.png);
}
.no-inlinesvg .glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.png);
}
.glyphicons-icon.glass {
  background-position: 4px 11px;
}
.glyphicons-icon.leaf {
  background-position: -44px 11px;
}
.glyphicons-icon.dog {
  background-position: -92px 11px;
}
.glyphicons-icon.user {
  background-position: -140px 11px;
}
.glyphicons-icon.girl {
  background-position: -188px 11px;
}
.glyphicons-icon.car {
  background-position: -236px 11px;
}
.glyphicons-icon.user_add {
  background-position: -284px 11px;
}
.glyphicons-icon.user_remove {
  background-position: -332px 11px;
}
.glyphicons-icon.film {
  background-position: -380px 11px;
}
.glyphicons-icon.magic {
  background-position: -428px 11px;
}
.glyphicons-icon.envelope {
  background-position: 4px -37px;
}
.glyphicons-icon.camera {
  background-position: -44px -37px;
}
.glyphicons-icon.heart {
  background-position: -92px -37px;
}
.glyphicons-icon.beach_umbrella {
  background-position: -140px -37px;
}
.glyphicons-icon.train {
  background-position: -188px -37px;
}
.glyphicons-icon.print {
  background-position: -236px -37px;
}
.glyphicons-icon.bin {
  background-position: -284px -37px;
}
.glyphicons-icon.music {
  background-position: -332px -37px;
}
.glyphicons-icon.note {
  background-position: -380px -37px;
}
.glyphicons-icon.heart_empty {
  background-position: -428px -37px;
}
.glyphicons-icon.home {
  background-position: 4px -85px;
}
.glyphicons-icon.snowflake {
  background-position: -44px -85px;
}
.glyphicons-icon.fire {
  background-position: -92px -85px;
}
.glyphicons-icon.magnet {
  background-position: -140px -85px;
}
.glyphicons-icon.parents {
  background-position: -188px -85px;
}
.glyphicons-icon.binoculars {
  background-position: -236px -85px;
}
.glyphicons-icon.road {
  background-position: -284px -85px;
}
.glyphicons-icon.search {
  background-position: -332px -85px;
}
.glyphicons-icon.cars {
  background-position: -380px -85px;
}
.glyphicons-icon.notes_2 {
  background-position: -428px -85px;
}
.glyphicons-icon.pencil {
  background-position: 4px -133px;
}
.glyphicons-icon.bus {
  background-position: -44px -133px;
}
.glyphicons-icon.wifi_alt {
  background-position: -92px -133px;
}
.glyphicons-icon.luggage {
  background-position: -140px -133px;
}
.glyphicons-icon.old_man {
  background-position: -188px -133px;
}
.glyphicons-icon.woman {
  background-position: -236px -133px;
}
.glyphicons-icon.file {
  background-position: -284px -133px;
}
.glyphicons-icon.coins {
  background-position: -332px -133px;
}
.glyphicons-icon.airplane {
  background-position: -380px -133px;
}
.glyphicons-icon.notes {
  background-position: -428px -133px;
}
.glyphicons-icon.stats {
  background-position: 4px -181px;
}
.glyphicons-icon.charts {
  background-position: -44px -181px;
}
.glyphicons-icon.pie_chart {
  background-position: -92px -181px;
}
.glyphicons-icon.group {
  background-position: -140px -181px;
}
.glyphicons-icon.keys {
  background-position: -188px -181px;
}
.glyphicons-icon.calendar {
  background-position: -236px -181px;
}
.glyphicons-icon.router {
  background-position: -284px -181px;
}
.glyphicons-icon.camera_small {
  background-position: -332px -181px;
}
.glyphicons-icon.dislikes {
  background-position: -380px -181px;
}
.glyphicons-icon.star {
  background-position: -428px -181px;
}
.glyphicons-icon.link {
  background-position: 4px -229px;
}
.glyphicons-icon.eye_open {
  background-position: -44px -229px;
}
.glyphicons-icon.eye_close {
  background-position: -92px -229px;
}
.glyphicons-icon.alarm {
  background-position: -140px -229px;
}
.glyphicons-icon.clock {
  background-position: -188px -229px;
}
.glyphicons-icon.stopwatch {
  background-position: -236px -229px;
}
.glyphicons-icon.projector {
  background-position: -284px -229px;
}
.glyphicons-icon.history {
  background-position: -332px -229px;
}
.glyphicons-icon.truck {
  background-position: -380px -229px;
}
.glyphicons-icon.cargo {
  background-position: -428px -229px;
}
.glyphicons-icon.compass {
  background-position: 4px -277px;
}
.glyphicons-icon.keynote {
  background-position: -44px -277px;
}
.glyphicons-icon.paperclip {
  background-position: -92px -277px;
}
.glyphicons-icon.power {
  background-position: -140px -277px;
}
.glyphicons-icon.lightbulb {
  background-position: -188px -277px;
}
.glyphicons-icon.tag {
  background-position: -236px -277px;
}
.glyphicons-icon.tags {
  background-position: -284px -277px;
}
.glyphicons-icon.cleaning {
  background-position: -332px -277px;
}
.glyphicons-icon.ruller {
  background-position: -380px -277px;
}
.glyphicons-icon.gift {
  background-position: -428px -277px;
}
.glyphicons-icon.umbrella {
  background-position: 4px -325px;
}
.glyphicons-icon.book {
  background-position: -44px -325px;
}
.glyphicons-icon.bookmark {
  background-position: -92px -325px;
}
.glyphicons-icon.wifi {
  background-position: -140px -325px;
}
.glyphicons-icon.cup {
  background-position: -188px -325px;
}
.glyphicons-icon.stroller {
  background-position: -236px -325px;
}
.glyphicons-icon.headphones {
  background-position: -284px -325px;
}
.glyphicons-icon.headset {
  background-position: -332px -325px;
}
.glyphicons-icon.warning_sign {
  background-position: -380px -325px;
}
.glyphicons-icon.signal {
  background-position: -428px -325px;
}
.glyphicons-icon.retweet {
  background-position: 4px -373px;
}
.glyphicons-icon.refresh {
  background-position: -44px -373px;
}
.glyphicons-icon.roundabout {
  background-position: -92px -373px;
}
.glyphicons-icon.random {
  background-position: -140px -373px;
}
.glyphicons-icon.heat {
  background-position: -188px -373px;
}
.glyphicons-icon.repeat {
  background-position: -236px -373px;
}
.glyphicons-icon.display {
  background-position: -284px -373px;
}
.glyphicons-icon.log_book {
  background-position: -332px -373px;
}
.glyphicons-icon.address_book {
  background-position: -380px -373px;
}
.glyphicons-icon.building {
  background-position: -428px -373px;
}
.glyphicons-icon.eyedropper {
  background-position: 4px -421px;
}
.glyphicons-icon.adjust {
  background-position: -44px -421px;
}
.glyphicons-icon.tint {
  background-position: -92px -421px;
}
.glyphicons-icon.crop {
  background-position: -140px -421px;
}
.glyphicons-icon.vector_path_square {
  background-position: -188px -421px;
}
.glyphicons-icon.vector_path_circle {
  background-position: -236px -421px;
}
.glyphicons-icon.vector_path_polygon {
  background-position: -284px -421px;
}
.glyphicons-icon.vector_path_line {
  background-position: -332px -421px;
}
.glyphicons-icon.vector_path_curve {
  background-position: -380px -421px;
}
.glyphicons-icon.vector_path_all {
  background-position: -428px -421px;
}
.glyphicons-icon.font {
  background-position: 4px -469px;
}
.glyphicons-icon.italic {
  background-position: -44px -469px;
}
.glyphicons-icon.bold {
  background-position: -92px -469px;
}
.glyphicons-icon.text_underline {
  background-position: -140px -469px;
}
.glyphicons-icon.text_strike {
  background-position: -188px -469px;
}
.glyphicons-icon.text_height {
  background-position: -236px -469px;
}
.glyphicons-icon.text_width {
  background-position: -284px -469px;
}
.glyphicons-icon.text_resize {
  background-position: -332px -469px;
}
.glyphicons-icon.left_indent {
  background-position: -380px -469px;
}
.glyphicons-icon.right_indent {
  background-position: -428px -469px;
}
.glyphicons-icon.align_left {
  background-position: 4px -517px;
}
.glyphicons-icon.align_center {
  background-position: -44px -517px;
}
.glyphicons-icon.align_right {
  background-position: -92px -517px;
}
.glyphicons-icon.justify {
  background-position: -140px -517px;
}
.glyphicons-icon.list {
  background-position: -188px -517px;
}
.glyphicons-icon.text_smaller {
  background-position: -236px -517px;
}
.glyphicons-icon.text_bigger {
  background-position: -284px -517px;
}
.glyphicons-icon.embed {
  background-position: -332px -517px;
}
.glyphicons-icon.embed_close {
  background-position: -380px -517px;
}
.glyphicons-icon.table {
  background-position: -428px -517px;
}
.glyphicons-icon.message_full {
  background-position: 4px -565px;
}
.glyphicons-icon.message_empty {
  background-position: -44px -565px;
}
.glyphicons-icon.message_in {
  background-position: -92px -565px;
}
.glyphicons-icon.message_out {
  background-position: -140px -565px;
}
.glyphicons-icon.message_plus {
  background-position: -188px -565px;
}
.glyphicons-icon.message_minus {
  background-position: -236px -565px;
}
.glyphicons-icon.message_ban {
  background-position: -284px -565px;
}
.glyphicons-icon.message_flag {
  background-position: -332px -565px;
}
.glyphicons-icon.message_lock {
  background-position: -380px -565px;
}
.glyphicons-icon.message_new {
  background-position: -428px -565px;
}
.glyphicons-icon.inbox {
  background-position: 4px -613px;
}
.glyphicons-icon.inbox_plus {
  background-position: -44px -613px;
}
.glyphicons-icon.inbox_minus {
  background-position: -92px -613px;
}
.glyphicons-icon.inbox_lock {
  background-position: -140px -613px;
}
.glyphicons-icon.inbox_in {
  background-position: -188px -613px;
}
.glyphicons-icon.inbox_out {
  background-position: -236px -613px;
}
.glyphicons-icon.cogwheel {
  background-position: -284px -613px;
}
.glyphicons-icon.cogwheels {
  background-position: -332px -613px;
}
.glyphicons-icon.picture {
  background-position: -380px -613px;
}
.glyphicons-icon.adjust_alt {
  background-position: -428px -613px;
}
.glyphicons-icon.database_lock {
  background-position: 4px -661px;
}
.glyphicons-icon.database_plus {
  background-position: -44px -661px;
}
.glyphicons-icon.database_minus {
  background-position: -92px -661px;
}
.glyphicons-icon.database_ban {
  background-position: -140px -661px;
}
.glyphicons-icon.folder_open {
  background-position: -188px -661px;
}
.glyphicons-icon.folder_plus {
  background-position: -236px -661px;
}
.glyphicons-icon.folder_minus {
  background-position: -284px -661px;
}
.glyphicons-icon.folder_lock {
  background-position: -332px -661px;
}
.glyphicons-icon.folder_flag {
  background-position: -380px -661px;
}
.glyphicons-icon.folder_new {
  background-position: -428px -661px;
}
.glyphicons-icon.edit {
  background-position: 4px -709px;
}
.glyphicons-icon.new_window {
  background-position: -44px -709px;
}
.glyphicons-icon.check {
  background-position: -92px -709px;
}
.glyphicons-icon.unchecked {
  background-position: -140px -709px;
}
.glyphicons-icon.more_windows {
  background-position: -188px -709px;
}
.glyphicons-icon.show_big_thumbnails {
  background-position: -236px -709px;
}
.glyphicons-icon.show_thumbnails {
  background-position: -284px -709px;
}
.glyphicons-icon.show_thumbnails_with_lines {
  background-position: -332px -709px;
}
.glyphicons-icon.show_lines {
  background-position: -380px -709px;
}
.glyphicons-icon.playlist {
  background-position: -428px -709px;
}
.glyphicons-icon.imac {
  background-position: 4px -757px;
}
.glyphicons-icon.macbook {
  background-position: -44px -757px;
}
.glyphicons-icon.ipad {
  background-position: -92px -757px;
}
.glyphicons-icon.iphone {
  background-position: -140px -757px;
}
.glyphicons-icon.iphone_transfer {
  background-position: -188px -757px;
}
.glyphicons-icon.iphone_exchange {
  background-position: -236px -757px;
}
.glyphicons-icon.ipod {
  background-position: -284px -757px;
}
.glyphicons-icon.ipod_shuffle {
  background-position: -332px -757px;
}
.glyphicons-icon.ear_plugs {
  background-position: -380px -757px;
}
.glyphicons-icon.record {
  background-position: -428px -757px;
}
.glyphicons-icon.step_backward {
  background-position: 4px -805px;
}
.glyphicons-icon.fast_backward {
  background-position: -44px -805px;
}
.glyphicons-icon.rewind {
  background-position: -92px -805px;
}
.glyphicons-icon.play {
  background-position: -140px -805px;
}
.glyphicons-icon.pause {
  background-position: -188px -805px;
}
.glyphicons-icon.stop {
  background-position: -236px -805px;
}
.glyphicons-icon.forward {
  background-position: -284px -805px;
}
.glyphicons-icon.fast_forward {
  background-position: -332px -805px;
}
.glyphicons-icon.step_forward {
  background-position: -380px -805px;
}
.glyphicons-icon.eject {
  background-position: -428px -805px;
}
.glyphicons-icon.facetime_video {
  background-position: 4px -853px;
}
.glyphicons-icon.download_alt {
  background-position: -44px -853px;
}
.glyphicons-icon.mute {
  background-position: -92px -853px;
}
.glyphicons-icon.volume_down {
  background-position: -140px -853px;
}
.glyphicons-icon.volume_up {
  background-position: -188px -853px;
}
.glyphicons-icon.screenshot {
  background-position: -236px -853px;
}
.glyphicons-icon.move {
  background-position: -284px -853px;
}
.glyphicons-icon.more {
  background-position: -332px -853px;
}
.glyphicons-icon.brightness_reduce {
  background-position: -380px -853px;
}
.glyphicons-icon.brightness_increase {
  background-position: -428px -853px;
}
.glyphicons-icon.circle_plus {
  background-position: 4px -901px;
}
.glyphicons-icon.circle_minus {
  background-position: -44px -901px;
}
.glyphicons-icon.circle_remove {
  background-position: -92px -901px;
}
.glyphicons-icon.circle_ok {
  background-position: -140px -901px;
}
.glyphicons-icon.circle_question_mark {
  background-position: -188px -901px;
}
.glyphicons-icon.circle_info {
  background-position: -236px -901px;
}
.glyphicons-icon.circle_exclamation_mark {
  background-position: -284px -901px;
}
.glyphicons-icon.remove {
  background-position: -332px -901px;
}
.glyphicons-icon.ok {
  background-position: -380px -901px;
}
.glyphicons-icon.ban {
  background-position: -428px -901px;
}
.glyphicons-icon.download {
  background-position: 4px -949px;
}
.glyphicons-icon.upload {
  background-position: -44px -949px;
}
.glyphicons-icon.shopping_cart {
  background-position: -92px -949px;
}
.glyphicons-icon.lock {
  background-position: -140px -949px;
}
.glyphicons-icon.unlock {
  background-position: -188px -949px;
}
.glyphicons-icon.electricity {
  background-position: -236px -949px;
}
.glyphicons-icon.ok_2 {
  background-position: -284px -949px;
}
.glyphicons-icon.remove_2 {
  background-position: -332px -949px;
}
.glyphicons-icon.cart_out {
  background-position: -380px -949px;
}
.glyphicons-icon.cart_in {
  background-position: -428px -949px;
}
.glyphicons-icon.left_arrow {
  background-position: 4px -997px;
}
.glyphicons-icon.right_arrow {
  background-position: -44px -997px;
}
.glyphicons-icon.down_arrow {
  background-position: -92px -997px;
}
.glyphicons-icon.up_arrow {
  background-position: -140px -997px;
}
.glyphicons-icon.resize_small {
  background-position: -188px -997px;
}
.glyphicons-icon.resize_full {
  background-position: -236px -997px;
}
.glyphicons-icon.circle_arrow_left {
  background-position: -284px -997px;
}
.glyphicons-icon.circle_arrow_right {
  background-position: -332px -997px;
}
.glyphicons-icon.circle_arrow_top {
  background-position: -380px -997px;
}
.glyphicons-icon.circle_arrow_down {
  background-position: -428px -997px;
}
.glyphicons-icon.play_button {
  background-position: 4px -1045px;
}
.glyphicons-icon.unshare {
  background-position: -44px -1045px;
}
.glyphicons-icon.share {
  background-position: -92px -1045px;
}
.glyphicons-icon.chevron-right {
  background-position: -140px -1045px;
}
.glyphicons-icon.chevron-left {
  background-position: -188px -1045px;
}
.glyphicons-icon.bluetooth {
  background-position: -236px -1045px;
}
.glyphicons-icon.euro {
  background-position: -284px -1045px;
}
.glyphicons-icon.usd {
  background-position: -332px -1045px;
}
.glyphicons-icon.gbp {
  background-position: -380px -1045px;
}
.glyphicons-icon.retweet_2 {
  background-position: -428px -1045px;
}
.glyphicons-icon.moon {
  background-position: 4px -1093px;
}
.glyphicons-icon.sun {
  background-position: -44px -1093px;
}
.glyphicons-icon.cloud {
  background-position: -92px -1093px;
}
.glyphicons-icon.direction {
  background-position: -140px -1093px;
}
.glyphicons-icon.brush {
  background-position: -188px -1093px;
}
.glyphicons-icon.pen {
  background-position: -236px -1093px;
}
.glyphicons-icon.zoom_in {
  background-position: -284px -1093px;
}
.glyphicons-icon.zoom_out {
  background-position: -332px -1093px;
}
.glyphicons-icon.pin {
  background-position: -380px -1093px;
}
.glyphicons-icon.albums {
  background-position: -428px -1093px;
}
.glyphicons-icon.rotation_lock {
  background-position: 4px -1141px;
}
.glyphicons-icon.flash {
  background-position: -44px -1141px;
}
.glyphicons-icon.google_maps {
  background-position: -92px -1141px;
}
.glyphicons-icon.anchor {
  background-position: -140px -1141px;
}
.glyphicons-icon.conversation {
  background-position: -188px -1141px;
}
.glyphicons-icon.chat {
  background-position: -236px -1141px;
}
.glyphicons-icon.male {
  background-position: -284px -1141px;
}
.glyphicons-icon.female {
  background-position: -332px -1141px;
}
.glyphicons-icon.asterisk {
  background-position: -380px -1141px;
}
.glyphicons-icon.divide {
  background-position: -428px -1141px;
}
.glyphicons-icon.snorkel_diving {
  background-position: 4px -1189px;
}
.glyphicons-icon.scuba_diving {
  background-position: -44px -1189px;
}
.glyphicons-icon.oxygen_bottle {
  background-position: -92px -1189px;
}
.glyphicons-icon.fins {
  background-position: -140px -1189px;
}
.glyphicons-icon.fishes {
  background-position: -188px -1189px;
}
.glyphicons-icon.boat {
  background-position: -236px -1189px;
}
.glyphicons-icon.delete {
  background-position: -284px -1189px;
}
.glyphicons-icon.sheriffs_star {
  background-position: -332px -1189px;
}
.glyphicons-icon.qrcode {
  background-position: -380px -1189px;
}
.glyphicons-icon.barcode {
  background-position: -428px -1189px;
}
.glyphicons-icon.pool {
  background-position: 4px -1237px;
}
.glyphicons-icon.buoy {
  background-position: -44px -1237px;
}
.glyphicons-icon.spade {
  background-position: -92px -1237px;
}
.glyphicons-icon.bank {
  background-position: -140px -1237px;
}
.glyphicons-icon.vcard {
  background-position: -188px -1237px;
}
.glyphicons-icon.electrical_plug {
  background-position: -236px -1237px;
}
.glyphicons-icon.flag {
  background-position: -284px -1237px;
}
.glyphicons-icon.credit_card {
  background-position: -332px -1237px;
}
.glyphicons-icon.keyboard-wireless {
  background-position: -380px -1237px;
}
.glyphicons-icon.keyboard-wired {
  background-position: -428px -1237px;
}
.glyphicons-icon.shield {
  background-position: 4px -1285px;
}
.glyphicons-icon.ring {
  background-position: -44px -1285px;
}
.glyphicons-icon.cake {
  background-position: -92px -1285px;
}
.glyphicons-icon.drink {
  background-position: -140px -1285px;
}
.glyphicons-icon.beer {
  background-position: -188px -1285px;
}
.glyphicons-icon.fast_food {
  background-position: -236px -1285px;
}
.glyphicons-icon.cutlery {
  background-position: -284px -1285px;
}
.glyphicons-icon.pizza {
  background-position: -332px -1285px;
}
.glyphicons-icon.birthday_cake {
  background-position: -380px -1285px;
}
.glyphicons-icon.tablet {
  background-position: -428px -1285px;
}
.glyphicons-icon.settings {
  background-position: 4px -1333px;
}
.glyphicons-icon.bullets {
  background-position: -44px -1333px;
}
.glyphicons-icon.cardio {
  background-position: -92px -1333px;
}
.glyphicons-icon.t-shirt {
  background-position: -140px -1333px;
}
.glyphicons-icon.pants {
  background-position: -188px -1333px;
}
.glyphicons-icon.sweater {
  background-position: -236px -1333px;
}
.glyphicons-icon.fabric {
  background-position: -284px -1333px;
}
.glyphicons-icon.leather {
  background-position: -332px -1333px;
}
.glyphicons-icon.scissors {
  background-position: -380px -1333px;
}
.glyphicons-icon.bomb {
  background-position: -428px -1333px;
}
.glyphicons-icon.skull {
  background-position: 4px -1381px;
}
.glyphicons-icon.celebration {
  background-position: -44px -1381px;
}
.glyphicons-icon.tea_kettle {
  background-position: -92px -1381px;
}
.glyphicons-icon.french_press {
  background-position: -140px -1381px;
}
.glyphicons-icon.coffee_cup {
  background-position: -188px -1381px;
}
.glyphicons-icon.pot {
  background-position: -236px -1381px;
}
.glyphicons-icon.grater {
  background-position: -284px -1381px;
}
.glyphicons-icon.kettle {
  background-position: -332px -1381px;
}
.glyphicons-icon.hospital {
  background-position: -380px -1381px;
}
.glyphicons-icon.hospital_h {
  background-position: -428px -1381px;
}
.glyphicons-icon.microphone {
  background-position: 4px -1429px;
}
.glyphicons-icon.webcam {
  background-position: -44px -1429px;
}
.glyphicons-icon.temple_christianity_church {
  background-position: -92px -1429px;
}
.glyphicons-icon.temple_islam {
  background-position: -140px -1429px;
}
.glyphicons-icon.temple_hindu {
  background-position: -188px -1429px;
}
.glyphicons-icon.temple_buddhist {
  background-position: -236px -1429px;
}
.glyphicons-icon.bicycle {
  background-position: -284px -1429px;
}
.glyphicons-icon.life_preserver {
  background-position: -332px -1429px;
}
.glyphicons-icon.share_alt {
  background-position: -380px -1429px;
}
.glyphicons-icon.comments {
  background-position: -428px -1429px;
}
.glyphicons-icon.flower {
  background-position: 4px -1477px;
}
.glyphicons-icon.baseball {
  background-position: -44px -1477px;
}
.glyphicons-icon.rugby {
  background-position: -92px -1477px;
}
.glyphicons-icon.ax {
  background-position: -140px -1477px;
}
.glyphicons-icon.table_tennis {
  background-position: -188px -1477px;
}
.glyphicons-icon.bowling {
  background-position: -236px -1477px;
}
.glyphicons-icon.tree_conifer {
  background-position: -284px -1477px;
}
.glyphicons-icon.tree_deciduous {
  background-position: -332px -1477px;
}
.glyphicons-icon.more_items {
  background-position: -380px -1477px;
}
.glyphicons-icon.sort {
  background-position: -428px -1477px;
}
.glyphicons-icon.filter {
  background-position: 4px -1525px;
}
.glyphicons-icon.gamepad {
  background-position: -44px -1525px;
}
.glyphicons-icon.playing_dices {
  background-position: -92px -1525px;
}
.glyphicons-icon.calculator {
  background-position: -140px -1525px;
}
.glyphicons-icon.tie {
  background-position: -188px -1525px;
}
.glyphicons-icon.wallet {
  background-position: -236px -1525px;
}
.glyphicons-icon.piano {
  background-position: -284px -1525px;
}
.glyphicons-icon.sampler {
  background-position: -332px -1525px;
}
.glyphicons-icon.podium {
  background-position: -380px -1525px;
}
.glyphicons-icon.soccer_ball {
  background-position: -428px -1525px;
}
.glyphicons-icon.blog {
  background-position: 4px -1573px;
}
.glyphicons-icon.dashboard {
  background-position: -44px -1573px;
}
.glyphicons-icon.certificate {
  background-position: -92px -1573px;
}
.glyphicons-icon.bell {
  background-position: -140px -1573px;
}
.glyphicons-icon.candle {
  background-position: -188px -1573px;
}
.glyphicons-icon.pushpin {
  background-position: -236px -1573px;
}
.glyphicons-icon.iphone_shake {
  background-position: -284px -1573px;
}
.glyphicons-icon.pin_flag {
  background-position: -332px -1573px;
}
.glyphicons-icon.turtle {
  background-position: -380px -1573px;
}
.glyphicons-icon.rabbit {
  background-position: -428px -1573px;
}
.glyphicons-icon.globe {
  background-position: 4px -1621px;
}
.glyphicons-icon.briefcase {
  background-position: -44px -1621px;
}
.glyphicons-icon.hdd {
  background-position: -92px -1621px;
}
.glyphicons-icon.thumbs_up {
  background-position: -140px -1621px;
}
.glyphicons-icon.thumbs_down {
  background-position: -188px -1621px;
}
.glyphicons-icon.hand_right {
  background-position: -236px -1621px;
}
.glyphicons-icon.hand_left {
  background-position: -284px -1621px;
}
.glyphicons-icon.hand_up {
  background-position: -332px -1621px;
}
.glyphicons-icon.hand_down {
  background-position: -380px -1621px;
}
.glyphicons-icon.fullscreen {
  background-position: -428px -1621px;
}
.glyphicons-icon.shopping_bag {
  background-position: 4px -1669px;
}
.glyphicons-icon.book_open {
  background-position: -44px -1669px;
}
.glyphicons-icon.nameplate {
  background-position: -92px -1669px;
}
.glyphicons-icon.nameplate_alt {
  background-position: -140px -1669px;
}
.glyphicons-icon.vases {
  background-position: -188px -1669px;
}
.glyphicons-icon.bullhorn {
  background-position: -236px -1669px;
}
.glyphicons-icon.dumbbell {
  background-position: -284px -1669px;
}
.glyphicons-icon.suitcase {
  background-position: -332px -1669px;
}
.glyphicons-icon.file_import {
  background-position: -380px -1669px;
}
.glyphicons-icon.file_export {
  background-position: -428px -1669px;
}
.glyphicons-icon.bug {
  background-position: 4px -1717px;
}
.glyphicons-icon.crown {
  background-position: -44px -1717px;
}
.glyphicons-icon.smoking {
  background-position: -92px -1717px;
}
.glyphicons-icon.cloud-download {
  background-position: -140px -1717px;
}
.glyphicons-icon.cloud-upload {
  background-position: -188px -1717px;
}
.glyphicons-icon.restart {
  background-position: -236px -1717px;
}
.glyphicons-icon.security_camera {
  background-position: -284px -1717px;
}
.glyphicons-icon.expand {
  background-position: -332px -1717px;
}
.glyphicons-icon.collapse {
  background-position: -380px -1717px;
}
.glyphicons-icon.collapse_top {
  background-position: -428px -1717px;
}
.glyphicons-icon.globe_af {
  background-position: 4px -1765px;
}
.glyphicons-icon.global {
  background-position: -44px -1765px;
}
.glyphicons-icon.spray {
  background-position: -92px -1765px;
}
.glyphicons-icon.nails {
  background-position: -140px -1765px;
}
.glyphicons-icon.claw_hammer {
  background-position: -188px -1765px;
}
.glyphicons-icon.classic_hammer {
  background-position: -236px -1765px;
}
.glyphicons-icon.hand_saw {
  background-position: -284px -1765px;
}
.glyphicons-icon.riflescope {
  background-position: -332px -1765px;
}
.glyphicons-icon.electrical_socket_eu {
  background-position: -380px -1765px;
}
.glyphicons-icon.electrical_socket_us {
  background-position: -428px -1765px;
}
.glyphicons-icon.message_forward {
  background-position: 4px -1813px;
}
.glyphicons-icon.coat_hanger {
  background-position: -44px -1813px;
}
.glyphicons-icon.dress {
  background-position: -92px -1813px;
}
.glyphicons-icon.bathrobe {
  background-position: -140px -1813px;
}
.glyphicons-icon.shirt {
  background-position: -188px -1813px;
}
.glyphicons-icon.underwear {
  background-position: -236px -1813px;
}
.glyphicons-icon.log_in {
  background-position: -284px -1813px;
}
.glyphicons-icon.log_out {
  background-position: -332px -1813px;
}
.glyphicons-icon.exit {
  background-position: -380px -1813px;
}
.glyphicons-icon.new_window_alt {
  background-position: -428px -1813px;
}
.glyphicons-icon.video_sd {
  background-position: 4px -1861px;
}
.glyphicons-icon.video_hd {
  background-position: -44px -1861px;
}
.glyphicons-icon.subtitles {
  background-position: -92px -1861px;
}
.glyphicons-icon.sound_stereo {
  background-position: -140px -1861px;
}
.glyphicons-icon.sound_dolby {
  background-position: -188px -1861px;
}
.glyphicons-icon.sound_5_1 {
  background-position: -236px -1861px;
}
.glyphicons-icon.sound_6_1 {
  background-position: -284px -1861px;
}
.glyphicons-icon.sound_7_1 {
  background-position: -332px -1861px;
}
.glyphicons-icon.copyright_mark {
  background-position: -380px -1861px;
}
.glyphicons-icon.registration_mark {
  background-position: -428px -1861px;
}
.glyphicons-icon.radar {
  background-position: 4px -1909px;
}
.glyphicons-icon.skateboard {
  background-position: -44px -1909px;
}
.glyphicons-icon.golf_course {
  background-position: -92px -1909px;
}
.glyphicons-icon.sorting {
  background-position: -140px -1909px;
}
.glyphicons-icon.sort-by-alphabet {
  background-position: -188px -1909px;
}
.glyphicons-icon.sort-by-alphabet-alt {
  background-position: -236px -1909px;
}
.glyphicons-icon.sort-by-order {
  background-position: -284px -1909px;
}
.glyphicons-icon.sort-by-order-alt {
  background-position: -332px -1909px;
}
.glyphicons-icon.sort-by-attributes {
  background-position: -380px -1909px;
}
.glyphicons-icon.sort-by-attributes-alt {
  background-position: -428px -1909px;
}
.glyphicons-icon.compressed {
  background-position: 4px -1957px;
}
.glyphicons-icon.package {
  background-position: -44px -1957px;
}
.glyphicons-icon.cloud_plus {
  background-position: -92px -1957px;
}
.glyphicons-icon.cloud_minus {
  background-position: -140px -1957px;
}
.glyphicons-icon.disk_save {
  background-position: -188px -1957px;
}
.glyphicons-icon.disk_open {
  background-position: -236px -1957px;
}
.glyphicons-icon.disk_saved {
  background-position: -284px -1957px;
}
.glyphicons-icon.disk_remove {
  background-position: -332px -1957px;
}
.glyphicons-icon.disk_import {
  background-position: -380px -1957px;
}
.glyphicons-icon.disk_export {
  background-position: -428px -1957px;
}
.glyphicons-icon.tower {
  background-position: 4px -2005px;
}
.glyphicons-icon.send {
  background-position: -44px -2005px;
}
.glyphicons-icon.git_branch {
  background-position: -92px -2005px;
}
.glyphicons-icon.git_create {
  background-position: -140px -2005px;
}
.glyphicons-icon.git_private {
  background-position: -188px -2005px;
}
.glyphicons-icon.git_delete {
  background-position: -236px -2005px;
}
.glyphicons-icon.git_merge {
  background-position: -284px -2005px;
}
.glyphicons-icon.git_pull_request {
  background-position: -332px -2005px;
}
.glyphicons-icon.git_compare {
  background-position: -380px -2005px;
}
.glyphicons-icon.git_commit {
  background-position: -428px -2005px;
}
.glyphicons-icon.construction_cone {
  background-position: 4px -2053px;
}
.glyphicons-icon.shoe_steps {
  background-position: -44px -2053px;
}
.glyphicons-icon.plus {
  background-position: -92px -2053px;
}
.glyphicons-icon.minus {
  background-position: -140px -2053px;
}
.glyphicons-icon.redo {
  background-position: -188px -2053px;
}
.glyphicons-icon.undo {
  background-position: -236px -2053px;
}
.glyphicons-icon.golf {
  background-position: -284px -2053px;
}
.glyphicons-icon.hockey {
  background-position: -332px -2053px;
}
.glyphicons-icon.pipe {
  background-position: -380px -2053px;
}
.glyphicons-icon.wrench {
  background-position: -428px -2053px;
}
.glyphicons-icon.folder_closed {
  background-position: 4px -2101px;
}
.glyphicons-icon.phone_alt {
  background-position: -44px -2101px;
}
.glyphicons-icon.earphone {
  background-position: -92px -2101px;
}
.glyphicons-icon.floppy_disk {
  background-position: -140px -2101px;
}
.glyphicons-icon.floppy_saved {
  background-position: -188px -2101px;
}
.glyphicons-icon.floppy_remove {
  background-position: -236px -2101px;
}
.glyphicons-icon.floppy_save {
  background-position: -284px -2101px;
}
.glyphicons-icon.floppy_open {
  background-position: -332px -2101px;
}
.glyphicons-icon.translate {
  background-position: -380px -2101px;
}
.glyphicons-icon.fax {
  background-position: -428px -2101px;
}
.glyphicons-icon.factory {
  background-position: 4px -2149px;
}
.glyphicons-icon.shop_window {
  background-position: -44px -2149px;
}
.glyphicons-icon.shop {
  background-position: -92px -2149px;
}
.glyphicons-icon.kiosk {
  background-position: -140px -2149px;
}
.glyphicons-icon.kiosk_wheels {
  background-position: -188px -2149px;
}
.glyphicons-icon.kiosk_light {
  background-position: -236px -2149px;
}
.glyphicons-icon.kiosk_food {
  background-position: -284px -2149px;
}
.glyphicons-icon.transfer {
  background-position: -332px -2149px;
}
.glyphicons-icon.money {
  background-position: -380px -2149px;
}
.glyphicons-icon.header {
  background-position: -428px -2149px;
}
.glyphicons-icon.blacksmith {
  background-position: 4px -2197px;
}
.glyphicons-icon.saw_blade {
  background-position: -44px -2197px;
}
.glyphicons-icon.basketball {
  background-position: -92px -2197px;
}
.glyphicons-icon.server {
  background-position: -140px -2197px;
}
.glyphicons-icon.server_plus {
  background-position: -188px -2197px;
}
.glyphicons-icon.server_minus {
  background-position: -236px -2197px;
}
.glyphicons-icon.server_ban {
  background-position: -284px -2197px;
}
.glyphicons-icon.server_flag {
  background-position: -332px -2197px;
}
.glyphicons-icon.server_lock {
  background-position: -380px -2197px;
}
.glyphicons-icon.server_new {
  background-position: -428px -2197px;
}
