@import "../_variables.less";

.sky-form {

  .PetagFont();

  .button {
    opacity: 1;
  }
  font-family: @regular-font-family !important;
  // radio checkboxes should look like select
  .radio input:checked + i,
  .checkbox input:checked + i,
  .toggle input:checked + i {
    border:2px solid #404040;
  }
  .radio i,
  .checkbox i {
    border-color:#404040;
    border-width: 2px;
    background:none;
  }
  .radio:hover i {
    border-color:#404040;
  }

  .radio input + i:after,
  .radio input ~ i:after {
    top:4px;
    left:4px;
    background:#404040;
  }
  .radio input:focus + i {
    border-color:#404040;
  }

  .fileButton {
    background-color: @darkest-blue;
    color: white;
  }

  .has-error.state-error {
    input:focus {
      border-color: @error-red-light;
      //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px @error-red-light;
    }
  }
}


.sky-form .radio input:checked + i:after,
.sky-form .checkbox input:checked + i:after,
.sky-form .radio input:checked ~ i:after,
.sky-form .checkbox input:checked ~ i:after {
  color:#333;
}

.sky-form .radio:hover i,
.sky-form .checkbox:hover i,
.sky-form .toggle:hover i,
.sky-form .ui-slider-handle:hover,
.sky-form .radio:focus i,
.sky-form .checkbox:focus i,
.sky-form .toggle:focus i,
.sky-form .ui-slider-handle:focus {
  border-color:#333;
}
.sky-form .checkbox input:focus ~ i,
.sky-form input:focus {
  box-shadow: 0 0 5px @power-blue;
}