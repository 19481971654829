.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.loginBox .withIcon .input-group-addon {
  position: absolute;
  width: 36px;
  height: 34px;
  padding-top: 8px;
}
.loginBox .withIcon .form-group {
  margin-left: 36px;
}
.loginBox .withIcon .form-group input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.loginBox .withIcon .form-group .note.note-error {
  padding-left: 2px;
}
.loginBox i {
  width: 14px;
}
.loginBox .cmsInfoBoxHeader h5 {
  font-size: 20px;
  font-weight: 400;
}
.loginBox .forgotten {
  text-align: left;
}
.loginBox #loginSubmit {
  font-weight: 600;
}
.loginBox #registerLink,
.loginBox #forgotPasswordLink {
  font-weight: 300;
  font-size: 13px;
}
#loginBox .btn-lg,
#loginBox .btn-sm {
  line-height: 1.5 !important;
  font-size: 17px;
  font-weight: 400;
}
.loginIndex {
  padding: 100px 15px;
}
@media only screen and (min-width: 768px) {
  .loginIndex > .row > div:first-child {
    padding-right: 35px;
  }
  .loginIndex > .row > div:last-child {
    padding-left: 35px;
  }
}
.loginIndex #regInfoHead {
  font-size: 20px;
  font-weight: 400;
  margin-top: 0;
  color: #002446;
}
.loginIndex #regInfoText {
  font-size: 18px;
}
.loginIndex .btn {
  line-height: 1.5 !important;
  font-size: 17px !important;
  font-weight: 400 !important;
}
.teaserLoginBox {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
