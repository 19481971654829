//@import "_unify";


//***
// Lift adds "hidden" input fields so the original selector may not work
//***
.sky-form .checkbox input ~ i:after {
  &:extend(.sky-form .checkbox input + i:after);
}

.sky-form .radio input ~ i:after {
  &:extend(.sky-form .radio input + i:after);
}

.sky-form .radio input:checked ~ i:after {
  &:extend(.sky-form .radio input:checked + i:after);
}

.sky-form .checkbox input:checked ~ i:after {
  &:extend(.sky-form .checkbox input:checked + i:after);
}

//***
// Custom sky-form changes
//***
.sky-form {
  border: none;
}

.sky-form fieldset {
  background: none;
}