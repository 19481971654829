.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.header .topbar {
  background-color: #002446;
}
.header .topbar .btnRow > div:first-child {
  padding-right: 5px !important;
  text-align: center;
  line-height: 26px;
}
.header .topbar .btnRow > div:last-child {
  padding-left: 5px !important;
}
.header .topbar .btnRow #topNavRegisterButton {
  color: #fff;
  font-size: 14px;
  text-transform: none;
  display: inline;
  text-decoration: none !important;
}
.header .topbar .btnRow #topNavRegisterButton:hover {
  background: none;
  text-decoration: underline !important;
}
.header .topbar .hoverSelector:hover a {
  text-decoration: underline;
}
.header .topbar ul.loginbar {
  text-align: center;
  padding: 8px 0 0;
  font-size: 13px;
  line-height: 35px;
  height: 52px;
}
.header .topbar ul.loginbar > li {
  padding: 0 8px;
  display: inline-block;
}
.header .topbar ul.loginbar > li > i {
  padding-right: 3px;
  color: #fff;
}
.header .topbar ul.loginbar > li > a,
.header .topbar ul.loginbar > li > a:active,
.header .topbar ul.loginbar > li > a:hover {
  font-size: 14px;
  color: #fff;
}
.header .topbar ul.loginbar .note.note-error {
  text-align: left;
  font-size: 12px;
  padding-left: 2px;
  color: white;
}
.header .topbar ul.search,
.header .topbar ul.login,
.header .topbar ul ul {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 34px;
  left: -15px;
  display: none;
  padding: 18px 11px 10px;
  list-style: none;
  min-width: 300px;
  position: absolute;
  background: #002446;
}
.header .topbar .form-group {
  margin-bottom: 9px;
}
.header .topbar .form-control {
  height: 32px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 15px;
}
.header .topbar ul li:nth-child(n+2) ul {
  left: auto;
  right: 0px;
}
.header .topbar li:hover ul.search,
.header .topbar li:hover ul.login {
  display: block;
}
.header .topbar ul.search li a,
.header .topbar ul.login li a {
  color: #555;
  display: block;
  font-size: 16px;
  padding: 2px 12px;
  margin-bottom: 1px;
}
.header .topbar ul.search li.active a i,
.header .topbar ul.login li.active a i {
  color: #999;
  float: right;
  margin-top: 2px;
}
.header .topbar ul.search li a:hover,
.header .topbar ul.search li.active a,
.header .topbar ul.login li a:hover,
.header .topbar ul.login li.active a {
  background: #fafafa;
}
.header .topbar ul.search li a:hover,
.header .topbar ul.login li a:hover {
  text-decoration: none;
}
.header .topbar .switch {
  display: block;
  vertical-align: top;
  border: 0;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  padding: 12px 8px 6px;
  /* Checkbox/Radio container */
  /* Switches */
  /* Switch track */
}
.header .topbar .switch label {
  vertical-align: middle;
  margin: 5px;
}
.header .topbar .switch .form-control-label {
  margin-left: 0.5em;
}
.header .topbar .switch input {
  display: none;
}
.header .topbar .switch .checkbox input[type="checkbox"] + label,
.header .topbar .switch .radio input[type="radio"] + label {
  border-radius: 100%;
  width: 1em;
  height: 1em;
  display: inline-block;
  background: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  box-shadow: 0 0 0 transparent;
}
.header .topbar .switch input + label {
  position: relative;
}
.header .topbar .switch input + label::before {
  content: "";
  height: 1em;
  width: 2.5em;
  border-radius: 1.2em;
  background-color: #b0afaf;
  display: inline-block;
  position: relative;
  top: 0.28em;
  vertical-align: top;
}
.header .topbar .switch input + label::after {
  margin-top: -1px;
  content: "";
  position: absolute;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  box-sizing: border-box;
  left: -0.2em;
  top: 0.05em;
  background-color: #002446;
  -webkit-transition: -webkit-transform 0.08s linear;
  transition: transform 0.08s linear;
  vertical-align: top;
  border: 2px solid #fff;
}
.header .topbar .switch input:checked + label::after {
  background-color: #002446 !important;
  border: 2px solid #fff;
  -webkit-transform: translateX(1.5em);
  transform: translateX(1.5em);
  width: 1.5em;
  height: 1.5em;
}
.header .topbar .switch span {
  padding: 10px;
  color: #e7edf1;
}
.header .topbar .switch span.activeSelection {
  color: #324c80;
}
.header #loggedIn.workAsSuperUser i,
.header #loggedIn.workAsSuperUser a {
  color: #fac700;
}
.header #loggedIn ul.login {
  padding: 18px 0 0;
}
.header #loggedIn ul.login li.loginNormal {
  text-align: left;
  border-bottom: 1px solid #FFF;
}
.header #loggedIn ul.login li.loginNormal a {
  padding-left: 20px;
  padding-right: 20px;
  color: #FFF;
}
.header #loggedIn ul.login li.loginNormal a:hover {
  background-color: #195184;
}
.header #loggedIn ul.login li.loginworksas {
  background-color: #fac700;
}
.header #loggedIn ul.login li.loginworksas .text {
  text-align: center;
  color: #FFF;
  font-size: 14px;
}
.header #loggedIn ul.login li.loginworksas .logoutlink {
  color: #FFF;
}
.header #loggedIn ul.login li.loginworksas .logoutlink:hover {
  background-color: inherit;
  color: #545454 !important;
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .header .topbar ul.loginbar {
    padding: 8px 0 0;
    font-size: 20px;
    margin: auto;
  }
  .header .topbar ul.loginbar > li {
    padding: 0 15px;
  }
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .header .topbar .switch {
    display: inline-block;
    margin-left: 0px;
    text-align: right;
    margin-right: 0;
  }
  .header .topbar ul.loginbar {
    text-align: right;
  }
}
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
