@import "../_variables";
/*
Listing like: /useraccount/publications or /useraccount/searchprofiles
*/

.listing {

  padding-bottom: 0 !important;

  .checkbox {
    margin-top: -3px;
  }

  .sky-form .radio, .sky-form .checkbox {
    padding-left:22px;
  }

  .row {
    border-bottom:1px solid @light-grey;
    margin:0 -5px 5px;
    padding: 10px 0 10px;
    &:last-child {
      border-bottom:none;
    }
  }

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .listing {
    .sky-form .radio, .sky-form .checkbox {
      padding-left:25px;
    }
    .row {
      padding: 2px 0 3px;
      .titleCol {
        padding-left:5px;
      }
      .dataRemove {
        padding-right: 5px;
      }
    }
  }
}

.imageListings {
  .item {
    border-bottom: 1px solid @light-grey;
    padding-bottom: 10px;
  }
}

.listing-info {
  background: lighten(@light-blue, 0%);
  color: @text-gray;
  padding: 10px 15px 5px;
  .fa {
    margin-right: 5px;
  }
}