@import "../_variables";

.lightBlue() {
  background-color:@light-blue1;

  color:#fff !important;
}

.btn-light-blue {
  .lightBlue;
}

.uploadButton {
  .lightBlue !important;
}
.btn {
  padding-top: 4px;
  padding-bottom: 4px;
}


.btn-u {
  border-radius:3px;
  padding-top:4px;
  padding-bottom:4px;
  font-size: 18px;
}

.btn, input[type="submit"]  {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn.blue-gray {
  background: @blue-gray;
  color: #fff;
  border-color:@blue-gray;

  &:hover {
  background:darken(@blue-gray,5%);
  }
  &:focus {
  background:darken(@blue-gray,2%);
  }

}

.btn-yellow {
  
  &:focus {
  outline:none !important;
  }
  
  &:hover {
    background: lighten(@ism-yellow, 2%) !important;
    color: #545454 !important;
  }
}

.btn-wrap-text {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.btn.closeOpen {
  //make him 40ppx height
  padding-top:6px;
  padding-bottom: 7px;
}

.btn.btn-error {
  background: @error-red-dark;
  color: #fff;
  border-color: @error-red-dark;
}
