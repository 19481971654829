@ism-yellow: #fac700;
@petag-green: #95d148;
@blue: #4765a0;
@power-blue:#195184;
@darker-blue: #324c80;
@darkest-blue: #002446;
@light-blue: #e7edf1;

// sticky footer
@footer-color: #555;
@footer-height: 340px;
@footer-heightMobil: 900px;
@footer-text: #888888;

@checkbox-grey: #404040;
@light-grey: #dddddd;
// Text Colors
@text-gray:#868686;
@darker-text-gray: #565656;
@text-black: #333333;

@light-blue1:#b3c0c9;
@another-light-blue:#EAEFF3;

@home-blue: #C9D6DF;

// Fonts:
@regular-font-family:'Klavika-Regular', sans-serif;
@light-font-family:'Klavika-Regular', sans-serif;
@bold-font-family:'Klavika-Regular', sans-serif;
@slim:300;
@regular:400;
@medium:400;
@semibold:600;
@bold:600;

//border Radius
@border-radius-petite: 5px;
@border-radius-default: 3px;
@border-radius-grande: 5px;

//employer-signup multiselects
@employer-multiselect-grey: #b2c0c9;
@blue-gray:#b2c0c9;

@form-layout-1-base-text:#999999;

@error-red-light: #d81e26; // use for icons & co.
@error-red-dark: #BA1D23; // use for Buttons or larger areas of read

//useraccount div tables
@table-even: #f9f9f9;

// max px size of square box
@square-box-size: 160px;
@box-height: 160px;
@box-width: 200px;
@square-box-padding: 10px;

.BorderBottomThick() {

  border-bottom: #fff 3px solid !important;
}


.BorderBottomMedium() {
  border-bottom: #fff 2px solid !important;
}

.BorderBottomThin() {
  border-bottom: #fff 1px solid !important;
}

.PetagFont() {
  font-family: @regular-font-family;
  color: @text-gray;
  line-height: 1.5;
  display: table;
  width: 100%;
  font-size: 16px;
}
// fonts functions:

.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}

.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}


.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}

.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}



.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}

.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}


//for demo


.font-200 {
  .font200();
}

.font-300 {
  .font300();
}

.font-400 {
  .font400();
}

.font-500 {
  .font500();
}

.font-600 {
  .font600();
}

.font-700 {
  .font700();
}

