@import "../_variables";

.loginBox {
  // nur das kleine Widget

  input {
  }

  .withIcon {
    .input-group-addon {
      position: absolute;
      width: 36px;
      height: 34px;
      padding-top: 8px;
    }

    .form-group {
      margin-left: 36px;
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .note.note-error {
        padding-left: 2px;
      }
    }
  }

  i {
    width: 14px; // css fix since icons are not same width
  }

  .cmsInfoBoxHeader {
    h5 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .forgotten {
    text-align: left;
  }

  #loginSubmit {
    //width: 100%;
    font-weight: 600;
  }
  #registerLink,
  #forgotPasswordLink {
    font-weight: 300;
    font-size: 13px;
  }

}

#loginBox {
  // ganze login Zeile auf der Startseite
  .btn-lg, .btn-sm {
    line-height: 1.5 !important;
    font-size: 17px;
    font-weight: 400;
  }
  #buttonToRegister {
  }
}

.loginIndex {
  // Login Index Seite
  padding: 100px 15px;

  > .row > div {
    // more space between cols on large screens
    @media only screen and (min-width: 768px) {
      &:first-child {
        padding-right: 35px;
      }

      &:last-child {
        padding-left: 35px;
      }
    }
  }

  #regInfoHead {

    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    color: @darkest-blue;
  }

  #regInfoText {
    font-size: 18px;
    //margin-bottom: 30px;
    //margin-top: 20px;
  }

  .btn {
    line-height: 1.5 !important;
    font-size: 17px !important;
    font-weight: 400 !important;
  }
}

.teaserLoginBox {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}