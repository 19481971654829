.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
/* Navbar */
.navbar {
  margin-bottom: 0;
  border: 0;
  background-color: #fff;
}
.header {
  border-bottom: 0;
}
.header .navbar-nav {
  padding-top: 0.5rem;
}
.header .navbar-nav > li > a {
  padding: 0.6em 1em 0.45em;
}
.header .navbar-nav > li > a:hover,
.header .header .navbar-nav > .active > a {
  border-bottom-color: #fac700;
}
.header .navbar-nav > .dropdown > a:after {
  content: "";
}
.header li.dropdown:last-child > .dropdown-menu {
  left: auto;
  right: 0;
}
.navbar .navbar-header > .nav-ism-logo {
  margin-left: 12px;
  padding: 0 0 0 0;
}
.navbar .navbar-header > .nav-ism-logo img {
  max-width: 260px;
}
.header .dropdown-menu {
  border-top: 0;
  margin-top: -1px !important;
  padding-top: 1px;
}
.header .navbar-toggle {
  margin: 6px 15px 8px 0;
}
/*topbar*/
.header .topbar {
  padding: 0;
}
.header .topbar .switch {
  padding: 8px;
  background: #fff;
}
.header .topbar .switch span {
  color: #888888 !important;
}
.header .topbar .switch span.activeSelection {
  color: #002446 !important;
}
.navbar.navbar-default.mega-menu {
  border: 0;
}
.header .navbar-nav > li > a:hover,
.header .navbar-nav > .active > a {
  border-bottom: 4px solid #fac700;
  box-sizing: border-box;
}
.header .navbar-nav > li > a {
  border-bottom: 4px solid white;
  bottom: 0 !important;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-bottom-color: #fac700;
}
.nav .dropdown:hover > a {
  border-bottom: 4px solid #fac700;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background: #fff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background: #fff;
}
.header .navbar-nav > .active > a:hover {
  color: #777777;
}
.header .navbar-nav > .active > a:focus {
  text-decoration: underline;
}
.header .navbar-nav .dropdown-toggle .caret {
  margin-top: 10px;
}
@media only screen and (max-width: 991px) {
  .navbar {
    padding-bottom: 10px;
    border-bottom: 1px solid #b2c0c9;
  }
}
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .navbar .navbar-header > .nav-ism-logo {
    padding: 0;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    max-width: 260px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (min-width: 375px) {
  .navbar .navbar-header > .nav-ism-logo {
    padding: 0;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    max-width: 300px;
  }
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .navbar .navbar-header > .nav-ism-logo {
    margin-left: 0;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    max-width: 350px;
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .header .navbar-nav > li > a {
    padding: 9px 15px 9px 15px;
  }
  .navbar .navbar-header > .nav-ism-logo img {
    padding-top: 7px;
  }
  .header .navbar-nav > li > a {
    padding-top: 45px !important;
    padding-bottom: 35px !important;
  }
  .header .navbar-brand {
    padding: 5px 0 !important;
    margin-top: 10px;
  }
  .header .navbar-nav > li > a:hover,
  .header .navbar-nav > .active > a {
    background: #fff;
  }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .header .navbar-nav > li > a {
    font-size: 19px;
  }
}
