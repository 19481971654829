@import "../_variables.less";

#functionBlock {
  margin:-90px 0 20px 0;

  padding-top: 14px;
  padding-bottom: 14px;
  #saveSearch .row-same-height >  div:last-child  {
    padding-right: 4px !important;
  }

  #savedSearchProfiles {
    label {
      color: #FFF;
      font-weight: normal;
    }

    select.form-control {
      width: 30%;
      margin-left: 10px;
      display: inline-block;
      background-color: @light-blue;
      color: @power-blue;
      border: none;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

.detailFilter {

  margin-top: 10px;
  .tab-v1 .nav-tabs {
    .BorderBottomMedium();
  }
  .tab-v1 .nav-tabs a {
    font-weight: @slim;
    background:none !important;
    padding:10px 15px 3px;
    font-size: 15px;
    text-align: left;
    &.active{
      background: white !important;
    }
    &.active:hover,
    &.active:focus {
      text-decoration: underline;
    }
  }

  .tab-v1 .nav-tabs  li.active {
    a {
      background:#fff !important;
    }

    a:hover {
      color:@footer-color;
    }
  }

  .nav-tabs > li > a {
    margin-right: 0;
  }

  .tab-v1 .nav-tabs > .active > a, .tab-v1 .nav-tabs > .active > a:hover, .tab-v1 .nav-tabs > .active > a:focus,
  .tab-v1 .nav-tabs > li > a:hover {
    color: @darker-blue;
    background: white;
  }

  .sky-form .checkbox i {
    background-color: inherit;
    border: 2px solid @checkbox-grey;
  }

  .sky-form .checkbox input + i:after, .sky-form .checkbox input ~ i:after {
    top: 1px;
    color: @checkbox-grey;
  }


  .tab-content {
    padding:10px 13px;
  }
}


/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .detailFilter {
    .nav-tabs > li:not(:last-child) {
      border-right: 1px solid white;
    }
    .tab-v1 .nav-tabs a {
      padding:10px 3px 3px;
      text-align: center;
      font-size: 15px;
    }
    .tab-content {
      padding: 10px 4px;
    }
  }

  .tab-v1 .nav-tabs li.col-md-2 {
    width: 100/7%;
  }
  }

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

.reset-filter {
  margin-top: 20px 0px;
}

.reset-search {
  text-align: right;
  cursor: pointer;

  img {
    width:25px;
    height: 25px;
    float: left;
  }

  p {
    color: @darkest-blue;
    font-size: 15px;
    margin: 3px 0px 0px 20px;
  }
}


