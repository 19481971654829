@import "../_variables";
// CSS for Same height rows with vertical alignment


/* columns of same height styles */

.row-full-height {
  height: 100%;
}
.col-full-height {
  height: 100%;
  vertical-align: middle;
}
.row-same-height {
  display: table;
  width: 100%;
  /* fix overflow */
  table-layout: fixed;
}
.col-xs-height {
  display: table-cell;
  float: none !important;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}


/* vertical alignment styles */

.col-top {
  vertical-align: top;
}
.col-middle {
  vertical-align: middle;
}
.col-bottom {
  vertical-align: bottom;
}