@import "../_variables";
.indexActive {
  font-weight: 600;
}

#searchCriteriaForm {
  .multiSelectPreview {
    width: 369px;
    height: 34px;
    background-color: @power-blue;
    color: white;
    border: none;
    padding-left: 10px;
  }

  #moreFilter {
    border: 0;
    background-color: @power-blue;
    color: #fff;
    box-shadow: none !important;
    position: relative;
    overflow: hidden;

    img {
      width: 25px;
      margin-right: 0.5em;
    }
  }
}

th.sorting_disabled {
  border-bottom: none;
}

.indexFilter {
  border-top:1px solid @light-grey;
  border-bottom:1px solid @light-grey;
  padding:5px 0;
  margin:0 0 15px;
}

.job-img-inputs-bg {

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  .col-md-4 {
    margin: 7px 0 !important;
  }


}



#district {
  color: white;

  a {
    color: white;
  }

  .row {
    margin: 0;
  }

  .caret {
    color: @ism-yellow;
    margin-right: 3px;
  }

  .districtSelect {
    border-top: 2px solid @power-blue;
  }

  #districtContent {
    .districtSelect {
      label {
        color: white;
      }

      label.checkbox i {
        border-color: white;
        &::after {
          color: white;
        }
      }
    }
  }
}


.paginate_button {
  color:@ism-yellow;
  padding:0 5px;

}




.job-img-inputs-bg .col-md-4.submitBtn {
  display: none;
}
.job-img-inputs-bg .col-md-4.submitBtnMobil {
  display: block;
}



@media only screen and (min-width : 480px) {

}

@media only screen and (min-width : 768px) {

}

@media only screen and (min-width : 992px) {
  .job-img-inputs-bg .col-md-4.submitBtnMobil {
    display: none;
  }

  .job-img-inputs-bg .col-md-4.submitBtn {
    display: block;
    padding-left: 15px !important;
  }

  .byEmployer > div.right-border {
    border-right: 1px solid #fff;

  }
}

@media only screen and (min-width : 1200px) {

}