@import "../_variables";
.form-layout-1 {
  select  {
    font-weight: 400;
  }

  .form-control {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    &:focus {
      border-color:darken(#ccc, 20%) !important;
      -webkit-box-shadow:  0 0 3px rgba(0,0,0,.3) !important;
      -moz-box-shadow:  0 0 3px rgba(0,0,0,.3)!important;
      box-shadow:  0 0 3px rgba(0,0,0,.3)!important;
    }
  }


  .radio input:checked + i:after,
  .checkbox input:checked + i:after,
  .radio input:checked ~ i:after,
  .checkbox input:checked ~ i:after {
    opacity: 1 !important;
  }

  .title {
    //given to first headline
    color: @darker-blue;
    margin-bottom: 30px;
  }

  .form-control {
    border-radius: 0;
    height: 40px;
    font-size: 20px;
    color:darken(@form-layout-1-base-text, 30%);
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color:    @form-layout-1-base-text;
    }

    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    @form-layout-1-base-text;
      opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:    @form-layout-1-base-text;
      opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:    @form-layout-1-base-text;
    }
  }
  .warningLabel,.note-error {
    color:@error-red-dark;
    padding: 0 0 0 2px;
    font-weight: normal;
  }
  .state-error {
    input.form-control, select, &:focus {
      border-color: #ccc;
        background: inherit !important;
    }
  }
  .label {
    color:@darker-blue;
    font-weight: 400;
    font-size: 20px;
    padding: 4px 7px 4px 0;
  }
  .input-group-addon {
    background: @light-blue1;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow:  none;
    box-shadow:  none;
    color:#fff;
  }
    .form-group {
    margin-bottom:20px;
  }

  .form-control[disabled] {
    background: #fff;
  }

  .state-disabled,.state-disabled:hover {
    cursor: not-allowed;
    color:#555555 !important;
    opacity: 1!important;
    i {
      border-color:#333 !important;
    }
  }



  .containsMultiSelect {

    > .row {
      margin-bottom: 5px;
    }
    select, .btn-default {
      background:@light-blue1;
      width: 100%;
      display: block;
      color: #fff;
      text-align: center;
    }
    .state-error select {
      background:@light-blue1;
    }
  }

  label {
    .font400
  }

  .iconSet {
    .btn-icon {
      background: none;
      font-size: 14px;
      vertical-align: super;
      border: none;
      color: @text-gray;

      &:hover {
        color: @ism-yellow;
      }
    }
  }

  .knowledge-error .knowledgeNameLabel   {
    //font-weight: 600;
    font-style: italic;
    color: @error-red-dark;
  }


}
.datepicker table tr td span.active.active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active {
  background: @light-blue1 !important;
  color: #fff;
}
