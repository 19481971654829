@import "../_variables";

.blue-multiselect, {
  button.multiselect {
    border: 0;
    background-color: @power-blue;
    color: #fff;
    box-shadow: none !important;
    position: relative;
    overflow: hidden;

    .caret {
      color: @ism-yellow;
    }
  }

  .dropdown-menu {
    right: 0;
    a, label {
      color: #fff;
    }
    background: @power-blue;
    border: 0;
  }

  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
    background: @darkest-blue;

  }

  .dropdown-menu > li > a {
    label {
      padding-left: 30px;

      i {
        border-color: #FFF;
      }
    }
  }
}

.light-blue-multiselect, {
  button.multiselect {
    border-radius: 3px;
    border: 0;
    background-color: @light-blue1;
    color: #fff;
    box-shadow: none !important;
    position: relative;
    .caret {
      color: #fff;
    }
  }
  .dropdown-menu {
    right: 0;
    a, label {
      color: #fff;
    }
    background: @light-blue1;
    border: 0;
  }
  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
    background-color: darken(@light-blue1, 10%);
  }
  .dropdown-menu > .active > a {
    background-color: darken(@light-blue1, 10%);
    &:hover {
      background-color: darken(@light-blue1, 15%);
    }
  }
  .dropdown-menu > li > a {
    label {
      padding-left: 30px;

      i {
        border-color: #FFF;
      }
    }
  }
}

.white-multiselect {
  position: relative;
  button.form-control {
    border-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: @power-blue;
    .caret {
      color: @ism-yellow;
      padding-top: 5px;
    }
  }

  &.open {
    button.form-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .dropdown-menu {
    right: 0;
    a, label {
      color: @power-blue;
    }
    background: #fff;
    border: 0;
  }
  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
    background-color: lighten(@light-blue1, 20%);
  }
  .dropdown-menu > .active > a {
    background-color: lighten(@light-blue1, 20%);
    &:hover {
      background-color: lighten(@light-blue1, 15%);
    }
  }
  .dropdown-menu > li > a {
    label {
      padding-left: 30px;

      i {
        border-color: #FFF;
      }
    }
  }
}

.darkest-blue-multiselect, {
  button.multiselect {
    border: 0;
    background-color: @darkest-blue;
    color: #fff;
    box-shadow: none !important;
    position: relative;

    .caret {
      color: @ism-yellow;
    }
  }

  .dropdown-menu {
    right: 0;
    a, label {
      color: #fff;
    }
    background: @darkest-blue;
    border: 0;
  }

  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
    background: @power-blue;

  }

  .dropdown-menu > li > a {
    label {
      padding-left: 30px;

      i {
        border-color: #FFF;
      }
    }
  }
}

//fix since label are the clickable area and not always 100% width
.multiselect-container.dropdown-menu label {
  width: 100% !important;
}

ul.multiselect-container {

  li.active {

    i.multi-checkbox:after {
      opacity: 1;
    }
  }

  li {
    i.multi-checkbox:after {
      opacity: 0;
    }
  }

  a.multi-select-link {

    &:hover, &:focus {
      i.multi-checkbox {
        border-color: #000;
      }
    }

    i.multi-checkbox {
      position: absolute;
      width: 17px;
      height: 17px;
      border: 2px solid #FFF;
      margin-top: 7px;

      &:after {
        content: '\f00c';
        position: absolute;
        top: -1px;
        left: 1px;
        font: normal 12px/16px FontAwesome;
      }
    }
  }
}

.btn-blue {
  border: 0;
  background-color: @power-blue !important;
  color: #fff;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  padding: 4px 12px 3px;
}

.btn-blue:hover {
  background-color: @power-blue !important;
  color: #fff !important;
}