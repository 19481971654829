@import "../_variables";
.ism-info {
  background: lighten(@light-blue, 0%);
  color: @text-gray;
  padding: 17px 15px 13px;
  margin: 15px 0 39px;
  .fa {
    margin-right: 5px;
  }
}

.inline {
  display: inline;
}