@import "../_variables";
//style for tables
.tableTitle {
  font-size: 19px;
}

.dataTables_paginate{
  .paginate_input {
    width: 28px;
    text-align: center;
  }
  .paginate_button.disabled {
    visibility: hidden;
  }
}


/**
 Responsiv Data Tables:

*/
  table.responsive {
    // Custom Expand Buttons:
    td:before {
      color:@text-gray !important;
      border: 0 !important;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
      background:none !important;
      font-weight: bold;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      left: auto !important;
      right:0 !important;
      width: 28px!important;
      height: 28px !important;
      font-size: 32px!important;
      top: 33px!important;
    }
  }

table.company {
  td {
    vertical-align: top;
  }
  td.company {
    padding-left: 30px;
    padding-bottom: 10px;

  }
}