.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
/*
Listing like: /useraccount/publications or /useraccount/searchprofiles
*/
.listing {
  padding-bottom: 0 !important;
}
.listing .checkbox {
  margin-top: -3px;
}
.listing .sky-form .radio,
.listing .sky-form .checkbox {
  padding-left: 22px;
}
.listing .row {
  border-bottom: 1px solid #dddddd;
  margin: 0 -5px 5px;
  padding: 10px 0 10px;
}
.listing .row:last-child {
  border-bottom: none;
}
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .listing .sky-form .radio,
  .listing .sky-form .checkbox {
    padding-left: 25px;
  }
  .listing .row {
    padding: 2px 0 3px;
  }
  .listing .row .titleCol {
    padding-left: 5px;
  }
  .listing .row .dataRemove {
    padding-right: 5px;
  }
}
.imageListings .item {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}
.listing-info {
  background: #e7edf1;
  color: #868686;
  padding: 10px 15px 5px;
}
.listing-info .fa {
  margin-right: 5px;
}
