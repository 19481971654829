@import "../_variables.less";

.form-select {
  width: 100%;
  font-size:18px;
}

.multiselect-container {
  width: 100%;
}

.form-control {
  font-size:18px;
}




.sky-form .radio input:checked ~ i:after,
.sky-form .checkbox input:checked ~ i:after {
  opacity: 1;
}


// border + round corner problem fix
// if this causes any other css display errors, do not remove but prefix with "#pageDocuments"
.sky-form .input-file .button {
  right:-4px;
  top: 0;
  height: 35px;
}
