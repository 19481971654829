.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
/* common */
html,
body {
  height: 100%;
}
body {
  font-family: 'Klavika-Regular', sans-serif;
  color: #868686;
  line-height: 1.5;
  display: table;
  width: 100%;
  font-size: 16px;
  /* we need padding-top for the fixed navbar*/
  padding-top: 185px;
}
@media (min-width: 768px) {
  body {
    padding-top: 132px;
  }
}
@media (min-width: 1024px) {
  body {
    padding-top: 156px;
  }
}
.bold {
  font-family: 'Klavika-Regular', sans-serif;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.light {
  font-family: 'Klavika-Regular', sans-serif;
}
.text-gray {
  color: #868686;
}
.page-row {
  display: table-row;
  height: 1px;
}
.page-row-expanded {
  height: 100%;
}
.btn-yellow {
  color: #ffffff !important;
  background-color: #fac700 !important;
  border-color: #fac700 !important;
}
.btn-yellow.btn {
  padding-top: 4px;
  padding-bottom: 4px;
}
.btn-yellow.center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.btn-upload {
  margin-right: 5px;
}
a {
  color: #324c80;
}
.btn-yellow.text-blue:hover {
  color: #fff !important;
}
.open.btn-group .multiselect {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.multiselect-container {
  border-radius: 0 0 3px 3px;
}
.multiselect-container > li > a > label > input[type="checkbox"] {
  left: 30px;
}
button.multiselect .caret {
  border: none !important;
  vertical-align: top;
  font-size: 13px;
  padding-top: 2px;
  position: absolute;
  right: 25px;
}
button.multiselect .caret:before {
  font-family: FontAwesome;
  content: "\f0dc";
  margin-left: 5px;
}
.job-img-inputs-bg {
  background-color: #002446;
  padding: 8px 14px;
  border-radius: 5px !important;
}
.job-img-inputs-bg .btn-yellow {
  padding: 4px 12px 3px;
}
.job-img-inputs-bg .row > div {
  margin: 7px 0 !important;
}
.job-img-inputs-bg .row {
  margin: 15px 0;
}
.job-img-inputs-bg .open button.multiselect {
  background: #195184;
}
.job-img-inputs-bg .blue-multiselect .dropdown-menu > li > a {
  padding-left: 0;
}
.blue-select {
  background-color: #195184;
  color: #fff;
}
.btn-round,
.btn-round button {
  border-radius: 3px !important;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.margin-20 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.margin-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.margin-40 {
  margin-bottom: 40px;
  margin-top: 40px;
}
.margin-50 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.margin-60 {
  margin-bottom: 60px;
  margin-top: 60px;
}
.margin-70 {
  margin-bottom: 70px;
  margin-top: 70px;
}
.margin-80 {
  margin-bottom: 80px;
  margin-top: 80px;
}
.margin-90 {
  margin-bottom: 90px;
  margin-top: 90px;
}
.margin-100 {
  margin-bottom: 100px;
  margin-top: 100px;
}
.padding-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.padding-left-0 {
  padding-left: 0px;
}
.padding-right-0 {
  padding-right: 0px;
}
.background-white {
  background-color: white;
}
.background-light-blue {
  background-color: #e7edf1;
}
.dark-blue-block {
  background-color: #002446;
  color: white;
  padding: 10px;
}
.indexActive > a {
  color: #fac700;
}
.radio-inline-label {
  margin-right: 20px;
  margin-top: 4px;
}
.offerTableJobTitle {
  font-size: 110%;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.popover {
  color: #002446;
}
.iconSet .btn-icon {
  padding-top: 0;
  background: none;
  font-size: 22px;
  border: none;
  color: #868686;
}
.iconSet .btn-icon:hover {
  color: #fac700;
}
button,
.btn-group,
.btn,
.btn-round {
  border-radius: 3px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
.icon {
  transform: scale(1.5);
  overflow: hidden;
}
.row-eq-height,
.vertical-align {
  display: block;
}
@media only screen and (min-width: 768px) {
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .vertical-align {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.message-box ul li {
  list-style: none;
}
.btn-blue {
  background: #002446;
  border-color: #324c80;
  color: #fff;
  margin: 4px 7px 4px 0;
}
.btn-blue:hover,
.btn-blue:focus {
  color: #fac700;
}
.btn {
  font-size: 18px;
}
.btn-sm {
  font-size: 15px;
  line-height: 1.25;
}
.btn-xs {
  font-size: 14px;
  line-height: 1.25;
}
.btn-delete {
  color: #d81e26;
  text-decoration: none !important;
}
.btn-delete:hover,
.btn-delete:focus {
  color: #BA1D23;
}
.btn-add {
  background: #fff;
  color: #fac700 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 32px;
  height: 30px;
  margin: 0 8px;
  padding: 6px 15px;
  position: relative;
  line-height: 15px;
}
.btn-add:before {
  content: "+";
  position: absolute;
  left: 0;
  right: 0;
}
.btn-add-blue {
  background: #fff;
  color: #fac700 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 32px;
  height: 30px;
  margin: 0 8px;
  padding: 6px 15px;
  position: relative;
  line-height: 15px;
  background-color: #002446;
  line-height: 13px;
}
.btn-add-blue:before {
  content: "+";
  position: absolute;
  left: 0;
  right: 0;
}
.btn-add-blue:hover,
.btn-add-blue:focus {
  color: white;
}
.btn-lg {
  font-weight: 400;
}
.rectangle {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
/**
  Tab as static navigation:
*/
.tab-v1 a {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.tab-v1 ul.nav-tabs-static li {
  text-align: center;
}
.tab-v1 ul.nav-tabs-static li a {
  background: #F1F5F6;
  border-radius: 0;
  border-right: 1px solid #fff;
}
.tab-v1 ul.nav-tabs-static li a:focus {
  text-decoration: none;
}
.tab-v1 ul.nav-tabs-static li:last-child a {
  border-right: none;
}
.tab-v1 ul.nav-tabs-static li.active a:hover {
  text-decoration: none;
}
.progress-bar.yellow {
  background: #fac700;
}
/**
 maxWdiths
*/
.maxWidth-100 {
  max-width: 100px;
}
.maxWidth-200 {
  max-width: 200px;
}
.maxWidth-300 {
  max-width: 300px;
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .btn-round-left-gt-sm {
    border-radius: 3px 0 0 3px !important;
  }
  .btn-round-right-gt-sm {
    border-radius: 0 3px 3px 0 !important;
  }
  .btn-round-not-gt-sm {
    border-radius: 0 !important;
  }
  .gapLeft-gt-sm {
    padding-left: 15px !important;
  }
  .text-right-gt-sm {
    text-align: right;
  }
  .pull-right-gt-sm {
    float: right;
  }
}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .btn-round-left-gt-md {
    border-radius: 3px 0 0 3px !important;
  }
  .btn-round-right-gt-md {
    border-radius: 0 3px 3px 0 !important;
  }
  .btn-round-not-gt-md {
    border-radius: 0 !important;
  }
  .btn-round-not {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
  .button-bar > div:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.8);
  }
  .tab-v1 ul.nav-tabs-static.fullWidth-4 li {
    width: 25%;
  }
  .tab-v1 ul.nav-tabs-static.fullWidth-5 li {
    width: 20%;
  }
  .tab-v1 ul.nav-tabs-static.fullWidth-6 li {
    width: 16.66666667%;
  }
  .tab-v1 ul.nav-tabs-static.fullWidth-7 li {
    width: 12.5%;
  }
  .tab-v1 ul.nav-tabs-static.fullWidth-8 li {
    width: 12.5%;
  }
}
/* Large Devices, Wide Screens */
/* Remove top margin for inline-radios example: Salutation*/
.inline-group .radio-inline.radio {
  margin-top: 0px;
}
/* Blue page labels */
.pageLabel {
  color: #324c80;
}
.sky-form .input-file .button {
  font-family: 'Klavika-Regular', sans-serif !important;
  font-size: 18px !important;
}
hr.white {
  border-top-color: white;
}
hr.x2 {
  border-width: 3px;
}
hr.grey {
  border-top-color: #b2c0c9;
}
.owl-carousel {
  z-index: 1;
}
.progress-opacity {
  opacity: 0.6;
}
.progress-xs {
  height: 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
b,
strong {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.modal.delete .btn-u#dialog-confirm {
  background-color: #BA1D23;
  border-color: #BA1D23;
}
#ajax-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
}
#ajax-spinner .ajax-popup {
  margin: 50px auto;
  width: 64px;
  height: 64px;
  background-color: #dddddd;
  border-radius: 3px;
  padding: 5px;
}
#ajax-spinner .ajax-popup img {
  width: 100%;
  height: 100%;
}
.red-text {
  color: #d81e26;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}
.padding-bottom-80 {
  padding-bottom: 80px;
}
.documentsPreviewWrapper {
  border: 1px solid #002446;
}
.betweener {
  position: relative;
  top: -25px;
}
.grid-cell-home {
  border: solid;
  border-width: 30px 30px 0 0;
  border-color: #C9D6DF;
  padding: 20px;
}
.grid-cell-home h3 {
  margin-top: 0 !important;
}
.video-js .vjs-big-play-button {
  font-size: 7em !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.videoPlayerError {
  padding-left: 30px;
}
ul.share {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 34px;
  left: -15px;
  display: none;
  padding: 18px 11px 10px;
  list-style: none;
  min-width: 300px;
  position: absolute;
  background: #002446;
}
.grid-info-block {
  background-color: white;
  border: 15px solid #e7edf1;
}
.grid-info-block .info-block-text {
  color: #868686;
}
.grid-info-block .info-right-block {
  padding-top: 15px;
}
.label-text-box label {
  font-weight: 400;
  display: block;
  color: #565656;
  border-bottom: 3px solid #565656;
}
.label-text-box > div {
  margin-bottom: 15px;
}
.label-text-box div {
  font-size: 14px;
  font-weight: 300;
  color: #565656;
}
.label-text-box p {
  color: #565656;
}
p,
li,
li a,
label {
  color: #868686;
}
.img-responsive {
  width: 100%;
}
@media (min-width: 768px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 992px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
.list-style-type-square {
  list-style-type: square;
}
