.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
@media print {
  html,
  body {
    height: auto;
  }
  html,
  body,
  .container,
  .tab-v1,
  .tab-content,
  .tab-panel {
    float: none;
  }
  .print-no-float {
    float: none !important;
  }
  .logo-print {
    border-bottom: 1px solid #dddddd;
    padding: 15px 0px 30px 0px;
  }
  [class*="col-sm-"] {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .visible-xs {
    display: none !important;
  }
  .hidden-xs {
    display: block !important;
  }
  table.hidden-xs {
    display: table;
  }
  tr.hidden-xs {
    display: table-row !important;
  }
  th.hidden-xs,
  td.hidden-xs {
    display: table-cell !important;
  }
  .hidden-xs.hidden-print {
    display: none !important;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
  .panel .collapse {
    height: auto !important;
    visibility: visible !important;
    display: block !important;
  }
  .tab-content > .tab-pane {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  .progress {
    background-color: #F5F5F5 !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#F5F5F5', endColorstr='#F5F5F5')" !important;
  }
  .progress-bar-blue {
    display: block !important;
    background-color: #337ab7 !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#5BC0DE', endColorstr='#5BC0DE')" !important;
  }
  .progress,
  .progress > .progress-bar {
    display: block !important;
    -webkit-print-color-adjust: exact !important;
    box-shadow: inset 0 0 !important;
    -webkit-box-shadow: inset 0 0 !important;
  }
  a[href]:after {
    content: none !important;
  }
  /* Always insert a page break after the element */
  .always-page-break-after,
  .always-pba {
    page-break-after: always;
  }
  /* Avoid page break after the element (if possible) */
  .avoid-page-break-after,
  .avoid-pba {
    page-break-after: avoid;
  }
  /* Insert page breaks after the element so that the next page is formatted as a left page */
  .left-page-break-after,
  .left-pba {
    page-break-after: left;
  }
  /* Insert page breaks after the element so that the next page is formatted as a right page */
  .right-page-break-after,
  .right-pba {
    page-break-after: right;
  }
  /* Always insert a page break before the element */
  .always-page-break-before,
  .always-pbb {
    page-break-before: always;
  }
  /* Avoid page break before the element (if possible) */
  .avoid-page-break-before,
  .avoid-pbb {
    page-break-before: avoid;
  }
  /* Insert page breaks before the element so that the next page is formatted as a left page */
  .left-page-break-before,
  .left-pbb {
    page-break-before: left;
  }
  /* Insert page breaks before the element so that the next page is formatted as a right page */
  .right-page-break-before,
  .right-pbb {
    page-break-before: right;
  }
  /* Avoid page break inside the element (if possible) */
  .avoid-page-break-inside,
  .avoid-pbi {
    page-break-inside: avoid;
  }
  .page-break {
    display: block;
    page-break-before: always;
  }
  .dataTables_length,
  .dataTables_paginate {
    display: none;
    visibility: hidden;
  }
  .table .saveOffer {
    display: none;
    visibility: hidden;
  }
  .resultIcon.fa.fa-times:before {
    color: #d81e26 !important;
  }
  .resultIcon.fa.fa-check:before {
    color: green !important;
  }
}
