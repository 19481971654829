.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.wageCal .word-break {
  word-break: break-word;
}
.wageCal.employer .wageCal .container {
  padding-top: 50px;
}
.wageCal.employer .showData td {
  padding-right: 30px;
}
.wageCal.employer .showData th,
.wageCal.employer .showData td {
  border-right: 5px solid white;
  padding-right: 10px;
}
.wageCal.employer .showData th:first-child,
.wageCal.employer .showData td:first-child,
.wageCal.employer .showData th :last-child,
.wageCal.employer .showData td :last-child {
  border-right: none;
}
.wageCal.employer .showData th:nth-child(n+3) {
  text-align: right;
}
.wageCal h2 {
  font-weight: 300;
  color: #002446;
  font-size: 26px;
}
.wageCal h4 {
  font-weight: 400;
  margin-top: 0;
  color: #333;
}
.wageCal .btn-yellow {
  padding-left: 50px;
  padding-right: 50px;
}
.wageCal .btn-yellow.disabled {
  background: #FAFAFA;
  border-color: #fafafa;
  color: #747474;
  font-style: italic;
}
.wageCal .inputData {
  padding-bottom: 25px;
}
.wageCal .inputData .form {
  margin-bottom: 25px;
  padding: 5px 25px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  background: #a3b0b9;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjYTNiMGI5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjYTNiMGI5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzg3OGY5NiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9yYWRpYWxHcmFkaWVudD4KICA8cmVjdCB4PSItNTAiIHk9Ii01MCIgd2lkdGg9IjEwMSIgaGVpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -moz-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(39%, #a3b0b9), color-stop(39%, #a3b0b9), color-stop(100%, #878f96));
  background: -webkit-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
  background: -o-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
  background: -ms-radial-gradient(center, ellipse cover, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
  background: radial-gradient(ellipse at center, #a3b0b9 39%, #a3b0b9 39%, #878f96 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a3b0b9', endColorstr='#878f96', GradientType=1);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.wageCal .inputData .form .row {
  margin-bottom: 15px;
  margin-top: 15px;
}
.wageCal .inputData .form .input .form-control {
  height: 25px;
  border-width: 2px 2px 2px 2px;
  background: #85939C;
  color: #002446;
  text-align: right;
  padding: 0px 12px 0px;
  width: 100%;
  font-weight: 400;
}
.wageCal .inputData .form .input .form-control.ng-valid {
  border-color: #9CB3C0;
  border-bottom-color: #B0BDC6;
}
.wageCal .inputData .form .input .form-control.ng-valid:focus {
  outline: 0 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.wageCal .inputData .form .input input::-webkit-outer-spin-button,
.wageCal .inputData .form .input input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
.wageCal .inputData .form .skala {
  color: #555;
}
.wageCal .inputData .hints {
  color: #555;
  margin-top: 30px;
}
.wageCal .inputData .hints ol {
  list-style-type: lower-alpha;
  padding-left: 22px;
}
.wageCal .inputData .has-error .form-control,
.wageCal .inputData .has-error .form-control:focus {
  border-color: #fac700;
}
.wageCal .triangle-down {
  width: 0;
  height: 0;
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-top: 45px solid #e7edf1;
  margin: 0 auto 20px;
}
.wageCal .showData {
  width: 100%;
}
.wageCal .showData .result {
  text-align: center;
  font-weight: 600;
  color: #002446;
  font-size: 26px;
}
.wageCal .showData table {
  background: #e7edf1;
  width: 100%;
  font-size: 16px;
  margin: 50px 0 0;
}
.wageCal .showData .scrollWrap {
  width: 100%;
  margin: 0 -15px 0 -15px;
  overflow: scroll;
}
.wageCal .showData .scrollWrap::-webkit-scrollbar {
  /* This is the magic bit */
  display: none;
}
.wageCal .showData th {
  background: #002446;
  color: #e7edf1;
  text-align: center;
}
.wageCal .showData td,
.wageCal .showData th {
  padding: 3px 0 3px 20px;
  text-align: right;
  font-weight: 400;
}
.wageCal .showData td:first-child,
.wageCal .showData th:first-child {
  padding: 3px 10px 3px 10px;
}
.wageCal .showData td:last-child,
.wageCal .showData th:last-child {
  padding: 3px 10px 3px 0;
}
.wageCal .showData .borderRight {
  padding-right: 10px;
}
.wageCal .showData td {
  text-align: right;
}
.wageCal .showData tr.yellow td {
  background: #fac700;
  font-weight: 600;
  color: #002446 !important;
}
.wageCal .showData td:nth-child(2) {
  color: #324c80;
  padding-right: 10px;
}
.wageCal .showData td:nth-child(1),
.wageCal .showData th:nth-child(1) {
  text-align: left;
}
.wageCal .showData th:nth-child(n+3) {
  text-align: right;
}
.wageCal .showData .borderRight {
  border-right: 5px solid white;
}
.wageCal .popover {
  min-width: 240px !important;
  max-width: 300px !important;
}
.bgWhite {
  padding-bottom: 80px;
  background: #fff;
}
.sky-form .tooltip {
  background: none;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}
.tooltip-inner {
  background-color: #fff;
  color: #000;
  padding: 12px 8px;
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .scrollWrap {
    margin: 0 15px 0 15px;
  }
}
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
