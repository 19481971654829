.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.recommend #downloadButton {
  display: none !important;
  visibility: hidden !important;
}
.display-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.padding-right-5 {
  padding-right: 5px;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.jobOffer #yVideo {
  padding-right: 0px;
}
.jobOffer #gMaps.remove-padding {
  padding-left: 0px;
}
.jobOffer .iconSet ul.dropdown-menu li {
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #dddddd;
}
.jobOffer .iconSet ul.dropdown-menu li .btn-icon {
  font-size: 16px;
}
.jobOffer .iconSet ul.dropdown-menu li button.btn-icon {
  padding: 3px 10px;
}
.jobOffer .iconSet ul.dropdown-menu li span {
  color: #002446;
}
.jobOffer .iconSet ul.dropdown-menu li .fa {
  width: 15px;
  margin-right: 10px;
  color: #002446;
}
.jobOffer .iconSet ul.dropdown-menu li:empty {
  border: none;
}
.jobOffer .iconSet ul.dropdown-menu li:last-child {
  border: none;
}
.prevNextNav {
  font-size: 15px;
  margin: 20px 0 0;
}
.prevNextNav > div {
  padding: 0;
}
.prevNextNav a {
  color: #868686;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 13px;
}
.prevNextNav a i {
  margin: 0 2px 0;
  color: #fac700;
}
.prevNextNav a:hover {
  color: #565656;
}
.prevNextNav a:hover i {
  color: #565656;
}
.jobOffer .applyButton,
.publicationView .applyButton,
.jobOffer .contactButton,
.publicationView .contactButton {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .jobOffer .applyButton,
  .publicationView .applyButton,
  .jobOffer .contactButton,
  .publicationView .contactButton {
    margin-top: 75px;
  }
}
.jobOffer hr,
.publicationView hr {
  border-color: #fff;
}
.jobOffer hr.hr-md,
.publicationView hr.hr-md {
  margin: 14px 0 25px;
}
.jobOffer .square-content,
.publicationView .square-content {
  background: #fff;
}
.jobOffer .jobOfferTitle,
.publicationView .jobOfferTitle,
.jobOffer .publicationOfferTitle,
.publicationView .publicationOfferTitle {
  margin: 0;
  font-size: 24px;
  color: #324c80;
}
.jobOffer .companyName,
.publicationView .companyName,
.jobOffer .personName,
.publicationView .personName {
  font-size: 18px;
  color: #324c80;
}
.jobOffer .companyName a,
.publicationView .companyName a {
  font-weight: 600;
  color: #324c80;
  font-size: 18px;
}
.jobOffer .offerData,
.publicationView .offerData {
  color: #324c80;
  font-weight: 400;
}
.jobOffer .jobMeta,
.publicationView .jobMeta,
.jobOffer .personalMeta,
.publicationView .personalMeta,
.jobOffer .personalContact,
.publicationView .personalContact {
  margin-top: 45px;
  margin-bottom: 30px;
}
.jobOffer .dataAndAction,
.publicationView .dataAndAction {
  margin-top: 57px;
}
.tab-v1 .nav-tabs {
  border-bottom-width: 0 !important;
}
.tab-v1 .nav-tabs > li a {
  background: white;
  font-size: 18px;
  padding: 10px 20px 7px;
  margin-right: 0;
}
.tab-v1 .nav-tabs > li.active a {
  color: #002446;
  background: white;
}
.tab-v1 .nav-tabs > li.active a:hover,
.tab-v1 .nav-tabs > li.active a:focus {
  background: white;
  color: #555;
}
.tab-v1 .nav-tabs > li.active a:focus {
  color: #002446;
}
.tab-v1 .nav-tabs > li > a:hover,
.tab-v1 .nav-tabs > li > a:focus {
  background: white;
  color: #002446;
}
.pdf-controlBar {
  color: #002446;
  padding: 0 2px;
  font-size: 13px;
}
.pdf-controlBar a {
  font-weight: 600;
  color: #868686;
  text-decoration: none !important;
}
.pdf-controlBar a i {
  padding: 0 5px;
  color: #fac700;
}
.pdf-controlBar a:hover {
  color: #565656;
}
.pdf-controlBar a:hover i {
  color: #565656;
}
#employerFooter {
  margin: 30px 0;
  font-weight: 300;
}
#employerFooter #contactPanel h5#contactPersonHead,
#employerFooter #contactPanel h5#companyName {
  font-size: 22px;
  font-weight: 600;
  color: #868686;
  margin-top: 3px;
}
#employerFooter #contactPanel h5#contactPersonHead {
  width: 80px;
}
@media only screen and (min-width: 576px) {
  #employerFooter #contactPanel h5#contactPersonHead {
    width: auto;
  }
}
#employerFooter .contactBold {
  font-weight: 600;
}
#employerFooter .contactDetails {
  line-height: 1.7;
}
#employerFooter .contactDetails table tr td {
  border-right: 5px solid transparent;
}
#employerFooter .contactRowType {
  vertical-align: top;
}
#employerFooter .contactMail {
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (min-width: 1200px) {
  #employerFooter .padding-logo {
    padding-left: 0px;
  }
  #employerFooter .padding-contactPerson {
    padding-right: 0px;
    padding-left: 80px;
  }
}
#employerFooter .box-row-top {
  padding-top: 20px;
}
#employerFooter .box-row {
  padding-left: 20px;
  padding-right: 20px;
}
#employerFooter #recruitersWelcome {
  color: #d81e26;
  font-size: larger;
}
#employerFooter .flex-container {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
#employerFooter #companyData .company-box {
  width: 110px;
  height: 90px;
}
#employerFooter #companyData .company-box .company-box-content span img {
  max-width: 100px;
  max-height: 80px;
}
#employerFooter #contactPerson #contactLogo {
  margin-bottom: 10px;
}
#employerFooter #contactPerson #contactLogo .contact-person-logo {
  height: 90px;
}
@media only screen and (min-width: 768px) {
  #employerFooter .flex-container {
    flex-direction: row;
  }
  #employerFooter #companyData #companyLogo {
    margin-right: 20px;
  }
  #employerFooter #companyData #companyLogo .company-box {
    width: 130px;
    height: 110px;
  }
  #employerFooter #companyData #companyLogo .company-box .company-box-content span img {
    max-width: 120px;
    max-height: 100px;
  }
  #employerFooter #contactPerson.sm-align-right {
    justify-content: flex-end;
  }
  #employerFooter #contactPerson #contactLogo {
    order: 1;
    margin-left: 20px;
  }
  #employerFooter #contactPerson #contactLogo .contact-person-logo {
    height: 110px;
  }
}
@media only screen and (min-width: 1024px) {
  #employerFooter #companyData #companyLogo .company-box {
    width: 190px;
    height: 150px;
  }
  #employerFooter #companyData #companyLogo .company-box .company-box-content span img {
    max-width: 180px;
    max-height: 140px;
  }
  #employerFooter #contactPerson #contactLogo .contact-person-logo {
    height: 150px;
  }
}
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
#profileTab,
#jobSearchTab,
#matchingResultTab {
  font-weight: 300;
}
#profileTab hr,
#jobSearchTab hr,
#matchingResultTab hr {
  border-color: #e7edf1;
}
#profileTab #profileGroups h3,
#jobSearchTab #profileGroups h3,
#matchingResultTab #profileGroups h3,
#profileTab #resultProfileGroups h3,
#jobSearchTab #resultProfileGroups h3,
#matchingResultTab #resultProfileGroups h3 {
  margin-top: 0;
}
#profileTab h3,
#jobSearchTab h3,
#matchingResultTab h3 {
  font-size: 24px;
  color: #324c80;
  font-weight: 300;
}
#advertisementTab #headertext {
  margin-top: 0;
}
#responsibilityTab h4,
#profileTab #personalTab h4,
#profileTab #employmentTab h4,
#generalTab h4,
#professionalTab h4,
#employmentTab h4,
#accordionSearched h4 {
  font-weight: 400;
  font-size: 16px;
  border-bottom: 3px solid #565656;
  padding-bottom: 4px;
  margin-bottom: 5px;
  color: #565656;
}
#responsibilityTab p,
#profileTab #personalTab p,
#profileTab #employmentTab p,
#generalTab p,
#professionalTab p,
#employmentTab p,
#accordionSearched p {
  font-size: 16px;
  font-weight: 300;
  color: #565656;
}
#responsibilityTab div,
#profileTab #personalTab div,
#profileTab #employmentTab div,
#generalTab div,
#professionalTab div,
#employmentTab div,
#accordionSearched div {
  color: #565656;
}
@media print {
  #profileTab h3,
  #jobSearchTab h3,
  #matchingResultTab h3 {
    font-size: 21px !important;
  }
}
