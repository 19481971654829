
/* Fonts */
@font-face {
  font-family: "Klavika-Regular";
  src: url("../fonts/Klavika-Regular.eot#") format("eot"),
  url("../fonts/Klavika-Regular.woff") format("woff"),
  url("../fonts/Klavika-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "Klavika-Light";
  src: url("../fonts/Klavika-Light.eot#") format("eot"),
  url("../fonts/Klavika-Light.woff") format("woff"),
  url("../fonts/Klavika-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "Klavika-Bold";
  src: url("../fonts/Klavika-Bold.eot#") format("eot"),
  url("../fonts/Klavika-Bold.woff") format("woff"),
  url("../fonts/Klavika-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal
}