@import "../_variables.less";

.header {
  .topbar {

    .btnRow {
      > div {
        &:first-child {
          padding-right: 5px !important;
          text-align: center;
          line-height: 26px;
        }
        &:last-child {
          padding-left: 5px !important;
        }
      }

      #topNavRegisterButton {
        color: #fff;
        font-size: 14px;
        text-transform: none;
        display: inline;
        text-decoration: none !important;

        &:hover {
          background: none;

          text-decoration: underline !important;
        }
      }
    }

    background-color: @darkest-blue;
    .hoverSelector:hover a {
      //underline text while hovering dropdown
      text-decoration: underline;
    }

    ul.loginbar {
      text-align: center;
      padding: 8px 0 0;
      font-size: 13px;
      line-height: 35px;
      height: 52px;
      > li {
        padding: 0 8px;
        display: inline-block;
        > i {
          padding-right: 3px;
          color: #fff;
        }
        > a,
        > a:active,
        > a:hover {
          font-size: 14px;
          color: #fff;
        }
      }

      .note.note-error {
        text-align: left;
        font-size: 12px;
        padding-left: 2px;
        color: white;
      }
    }
    ul.search,
    ul.login,
    ul ul {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      top: 34px;
      left: -15px;
      display: none;
      padding: 18px 11px 10px;
      list-style: none;
      min-width: 300px;
      position: absolute;
      background: @darkest-blue;;
    }

    .form-group {
      margin-bottom: 9px;
    }

    .form-control {
      height: 32px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      font-size: 15px;
    }

    ul li:nth-child(n+2) ul {
      left: auto;
      right: 0px;
    }

    li:hover ul.search,
    li:hover ul.login {
      display: block;
    }

    ul.search li a,
    ul.login li a {
      color: #555;
      display: block;
      font-size: 16px;
      padding: 2px 12px;
      margin-bottom: 1px;
    }

    ul.search li.active a i,
    ul.login li.active a i {
      color: #999;
      float: right;
      margin-top: 2px;
    }

    ul.search li a:hover,
    ul.search li.active a,
    ul.login li a:hover,
    ul.login li.active a {
      background: #fafafa;
    }

    ul.search li a:hover,
    ul.login li a:hover {
      text-decoration: none;
    }
    //http://nt1m.github.io/material-framework/#toggles

    .switch {
      display: block;
      vertical-align: top;
      border: 0;
      cursor: pointer;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      // display: inline-block;
      margin-left: -15px;
      margin-right: -15px;
      text-align: center;
      padding: 12px 8px 6px;

      label {
        vertical-align: middle;
        margin: 5px;
      }

      .form-control-label {
        margin-left: 0.5em;
      }
      input {
        display: none;
      }
      /* Checkbox/Radio container */
      .checkbox input[type="checkbox"] + label,
      .radio input[type="radio"] + label {
        border-radius: 100%;
        width: 1em;
        height: 1em;
        display: inline-block;
        background: transparent;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        position: relative;
        box-shadow: 0 0 0 transparent;
      }

      /* Switches */
      input + label {
        position: relative;
      }

      /* Switch track */
      input + label::before {
        content: "";
        height: 1em;
        width: 2.5em;
        border-radius: 1.2em;
        background-color: #b0afaf;
        display: inline-block;
        position: relative;
        top: 0.28em;
        vertical-align: top;
      }

      ///* Switch thumb */
      input + label::after {
        margin-top: -1px;
        content: "";
        position: absolute;
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        //box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        left: -0.2em;
        top: 0.05em;
        background-color: @darkest-blue;
        -webkit-transition: -webkit-transform 0.08s linear;
        transition: transform 0.08s linear;
        vertical-align: top;
        border: 2px solid #fff;
      }

      input:checked + label::after {
        background-color: @darkest-blue !important;
        border: 2px solid #fff;
        -webkit-transform: translateX(1.5em);
        transform: translateX(1.5em);
        width: 1.5em;
        height: 1.5em;
      }
      span {
        padding: 10px;
        color: @light-blue;
      }
      span.activeSelection {
        color: @darker-blue;
      }
    }
  }

  #loggedIn {

    &.workAsSuperUser {
      i, a {
        color: @ism-yellow
      }
    }

    ul.login {
      padding: 18px 0 0;

      li.loginNormal {
        text-align: left;
        border-bottom: 1px solid #FFF;

        a {
          padding-left: 20px;
          padding-right: 20px;
          color: #FFF;
        }

        a:hover {
          background-color: @power-blue;
        }
      }

      li.loginworksas {
        background-color: @ism-yellow;

        .text {
          text-align: center;
          color: #FFF;
          font-size: 14px;
        }
        .logoutlink {
          color: #FFF;
          &:hover {
            background-color: inherit;
            color: #545454 !important;
          }
        }
      }
    }
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .header {
    .topbar {
      ul.loginbar {
        padding: 8px 0 0;
        font-size: 20px;
        margin: auto;
        > li {
          padding: 0 15px;
        }
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .header {
    .topbar {
      .switch {
        display: inline-block;
        margin-left: 0px;
        text-align: right;
        margin-right: 0;
      }
      ul.loginbar {
        text-align: right;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}
