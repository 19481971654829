@import "../_variables.less";

.signup-form {
  #userAcceptTac a {
    color: @darker-blue;
    text-decoration: underline;
    font-weight: 400;
    font-size:19px;
  }
  
  #pageContent {
    border-bottom: 1px solid #fff;
    padding-bottom: 25px;
  }
}

.seeker-signup-form {
  #userSalutation{
    margin-top: 11px;
  }
}


#activeSectionHeading  h3 {
  color:lighten(#B2C0C9,5%);
  display: none;
  margin-top:0;
  font-size: 25px;
  margin-bottom: 25px;
  &.active  {
    display: block;
  }
}

.employer-signup-form {

  #activeSectionHeading .active {
    color: @darker-blue;
  }

  #next {
    //width: 15%;
  }

  button.multiselect {

    .multiselect-selected-text {
      font-weight: bold;
    }
  }

  .multiselectSection {
    button.multiselect {
      border: 0;
      box-shadow:none !important;
      background-color: @employer-multiselect-grey;
      color: #fff;
    }
  }

  /* Custom, iPhone Retina */
  @media only screen and (min-width: 320px) {
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: 480px) {
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) {


    #activeSectionHeading  h3 {
      display: block;
    }

      #activeSectionHeading > .row:before {
      top: 15px;
      left: 175px;
      position: relative;
        display: block;
        height: 3px;
      width: 46%;
      background-color: #B2C0C9;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width: 992px) {
    #activeSectionHeading > .row:before {
      width: 58%;
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width: 1200px) {
    #activeSectionHeading > .row:before {
      width: 65%;
    }
  }
}


.invitee-signup-form {

  .underline {
    border-bottom: 1px solid;
  }

}