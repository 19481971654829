@import "../_variables";

.cc-banner {

  .PetagFont();

  .cc-btn {
    background-color: @ism-yellow;
    color: white;
    border-radius: 3px;

    &:hover{
      text-decoration: none;
      background: lighten(@ism-yellow, 2%) !important;
      color: #545454 !important;
    }
  }
}
