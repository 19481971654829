.font200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
.font-200 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 400;
}
.font-300 {
  font-family: 'Klavika-Light', 'Arial', sans-serif;
  font-weight: 600;
}
.font-400 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 400;
}
.font-500 {
  font-family: 'Klavika-Regular', 'Arial', sans-serif;
  font-weight: 600;
}
.font-600 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 400;
}
.font-700 {
  font-family: 'Klavika-Bold', 'Arial', sans-serif;
  font-weight: 600;
}
body .form-layout-2 .form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  height: 40px;
  -webkit-box-shadow: inset 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px 0px 1px rgba(0, 0, 0, 0.1);
}
body .form-layout-2 textarea {
  font-size: 18px;
  padding: 12px 12px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #999999;
  border: 0;
  -webkit-box-shadow: inset 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px 0px 1px rgba(0, 0, 0, 0.1);
}
body .form-layout-2 h4 {
  font-size: 24px;
  margin: 19px 0 18px;
}
body .form-layout-2 .form-group {
  margin-bottom: 18px;
}
body .form-layout-2 .warningLabel,
body .form-layout-2 .note-error {
  color: #d81e26;
  padding: 0 0 0 2px;
  font-size: 16px;
  font-weight: normal;
}
body .form-layout-2 .state-error input.form-control,
body .form-layout-2 .has-error input.form-control,
body .form-layout-2 .state-error select,
body .form-layout-2 .has-error select,
body .form-layout-2 .state-error:focus,
body .form-layout-2 .has-error:focus {
  background: #fff !important;
}
body .form-layout-2 .state-error i,
body .form-layout-2 .has-error i {
  background: inherit !important;
}
body .form-layout-2 .state-error .radio,
body .form-layout-2 .has-error .radio,
body .form-layout-2 .state-error .has-error .checkbox,
body .form-layout-2 .has-error .has-error .checkbox,
body .form-layout-2 .state-error .radio-inline,
body .form-layout-2 .has-error .radio-inline {
  color: inherit;
}
body .form-layout-2 .state-disabled,
body .form-layout-2 .state-disabled:hover {
  cursor: not-allowed;
  color: #555555 !important;
  opacity: 1!important;
}
body .form-layout-2 .state-disabled i,
body .form-layout-2 .state-disabled:hover i {
  border-color: #333 !important;
}
body .form-layout-2 .uploadButton {
  font-size: 34px;
}
body .form-layout-2.sky-form .state-error input {
  background: #fff !important;
}
body .form-layout-2.sky-form .state-error label {
  color: inherit!important;
}
